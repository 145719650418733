import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Tooltip } from 'antd';
import {
    categoryData,
    categorySelector,
} from "../store/reducer/category";
import { assestURL } from "../services/config";
import {
    subcategoryData,
    AddsubCategory,
    UpdatesubCategory,
    DeletesubCategory,
    subCategorySelector,
    clearsLoadingDatas,
    clearsCatgAddLoading,
    clearsCatgUpdateLoading,
    clearsCatgDeleteLoading,
    clearData,
    clearErrormsg
} from "../store/reducer/subcategory";
import { API_STATUS } from "../utils/constants";
import {
    subcategoryFields
} from "./fields/subcategoryform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { ErrorSharp } from "@mui/icons-material";
import { Button, Modal } from "react-bootstrap";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d')
    const TO_RADIANS = Math.PI / 180
    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}
function SubCategory() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const { subcategoryDatas, subcategoryCount, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(subCategorySelector);
    const resetForm = subcategoryFields;
    const [formFields, setFormFields] = useState(subcategoryFields);
    const [showAdd, setshowAdd] = useState(false);
    const [hybridMode, sethybridMode] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [fileerror, setfileerror] = useState(false);
    const [filesizeerror, setfilesizeerror] = useState(false);

    let [thumbsimage, setThumbsImage] = useState([]);
    const [completedCrop, setCompletedCrop] = useState()
    let [imgSRC, setImgSRC] = useState([]);
    let [imgSRCwidth, setImgSRCwidth] = useState("");
    let [imgSRCheight, setImgSRCheight] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const previewCanvasRef = useRef(null);
    const [filename, setfilename] = useState('');
    const imgRef = useRef(null);
    let [scale, setScale] = useState(1);

    const maxProductWidth = 500;
    const maxProductHeight = 500;

    //const formDatas = _.map()

    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        locked: true,
        width: maxProductWidth,
        height: maxProductHeight
    })

    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = {
            unit: 'px', // Can be 'px' or '%'
            x: 0,
            y: 0,
            locked: true,
            width: maxProductWidth,
            height: maxProductHeight
        }

        setCrop(crop)
        setCompletedCrop(crop)
    }

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        subcategoryDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        subcategoryDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const CheckOrRadioChange = (value, index) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
    }

    const DropdownChange = (value, index) => {
        console.log(value, 'value-==')
        formFields[index].value = value;
        setFormFields([...formFields]);
        if (value && value[0] && value[0].id == 4) {
            // sethybridMode(true)
            let get_indexs = _.findIndex(formFields, { name: "hybrid_category" })
            if (get_indexs >= 0) {
                formFields[get_indexs].class = "";
                formFields[get_indexs].validation = "";
            }
        } else {
            let get_indexs = _.findIndex(formFields, { name: "hybrid_category" })
            if (get_indexs >= 0) {
                formFields[get_indexs].class = "hide";
                formFields[get_indexs].validation = "";
            }
            // sethybridMode(false)
        }
    };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result

                const img = new Image();
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;
                    setImgSRCwidth(width);
                    setImgSRCheight(height);

                    if (width < maxProductWidth || height < maxProductHeight) {
                        const errorMessage = {
                            file,
                            errors: [
                                {
                                    code: "file-invalid-dimensions",
                                    message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
                                },
                            ],
                        };

                        rejectedFiles.push(errorMessage);
                        setThumbsImage([]);
                        setfileUpload('')
                    } else {

                        setShowPreview(true);
                        console.log(`Image dimensions: ${width}x${height}`);
                        // setfileUpload(binaryStr);
                        setfilePath(file.path);
                        setfileerror(false);
                    }
                };
                img.src = binaryStr;
            }
            reader.readAsDataURL(file)
        })

    }, [])

    useEffect(() => {
        if (files.length > 0) {
            console.log(files[0].preview, "preview");
            setImgSRC(files[0].preview)
            let thumbs = files.map(file => (
                <div className="dropzone_thum_img" key={file.name}>
                    <div className="dropzone_thumb">
                        <img
                            src={file.preview}
                            className="dropzone_img"
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(file.preview) }}
                        />
                    </div>
                </div>
            ));
            setThumbsImage(thumbs);
        }
    }, [files])


    useEffect(() => {
        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {
            const t = setTimeout(() => {

                // We use canvasPreview as it's much faster than imgPreview.

                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                )
            }, 100);

            return () => {
                clearTimeout(t)
            }
        }

    }, [completedCrop])



    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false,
        maxSize: 1024000,
    })
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{(e.message == 'File is larger than 1024000 bytes') ? 'File size should be less than 1 MB' : e.message}</span>
            ))}
        </div>
    ));

    //Crop Image Modal close
    const Imgmodalclose = () => {
        setShowPreview(false)
    }

    const cropImg = () => {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        //   console.log(previewCanvasRef.current)
        previewCanvasRef.current.toBlob((blob) => {
            console.log(blob, "blob");
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const reader = new FileReader();

            reader.onload = () => {
                console.log(reader.result, "blob");
                setfileUpload(reader.result);
                setShowPreview(false)
            };
            reader.readAsDataURL(blob);
        })
    };

    const submitSubCategory = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            /**
             * Add subcategory image validation
             */
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
                return false;
            }
            if (filesizeerror == true) {
                return false;
            }
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                if (data?.name === "status" || data?.name === 'category_id') {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val[0].id;
                } else {
                    return arr[data?.name] = data.value;
                }
            });
            arr.image = fileUpload;
            arr.imgpath = filePath;

            const postData = arr;
            console.log(postData, "postData");
            //return false;
            //const postData = arr;
            if (updateID == "")
                dispatch(AddsubCategory({ postData }));
            else {
                postData.id = updateID;
                dispatch(UpdatesubCategory({ postData, updateID }));
            }
            //setshowAdd(false);
            setfileUpload('');
            setThumbsImage([]);
        } else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            setShowvalidator(true);
            console.log("test");
        }
    };

    const editSubCategory = (row) => {
        window.scrollTo(0, 0)
        setUpdateID(row.id);
        console.log(row.name)
        setfileerror(false)

        setfileUpload('');
        setThumbsImage([]);
        let data = formFields.map((data, index) => {
            //console.log(_.filter(statusDropdown, { id: row.status }));
            if (data.name === "name")
                data.value = row.name;
            if (data.name === "description")
                data.value = row.description;
            if (data.name === "status") {
                let set_status = _.filter(statusDropdown, { id: row.status });
                console.log(set_status[0]);
                data.value = set_status;
            }
            if (data.name === "category_id") {
                let set_status = _.filter(categoryOptions, { id: row.category_id });
                console.log(set_status[0]);
                data.value = set_status;
            }
            if (data.name === "hybrid_category") {
                data.value = row.hybrid_category;
            }
            if (data.name === "image") {
                data.value = assestURL + row.image;
            }
            return data;
        });
        console.log(data, "formfields");
        setFormFields(data)
        setshowAdd(true);
    };

    const deleteSubCategory = (row) => {
        setshowAdd(false);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Sub Category?',
            html: 'Deleting Sub Category will delete its products',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(DeletesubCategory({ deleteID }));
            }
        })

    };

    const resetFormFields = () => {
        setShowvalidator(false);
        setfileerror(false);
        setUpdateID("");
        setFiles([]);
        setfileUpload('');
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
        })
    };

    const subcategoryDispatch = () => {
        dispatch(subcategoryData({
            query: searchQuery,
            page: page + 1,
            limit: rowsPerPage,
            sortby: sortBy,
            order: order,
        }));
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        subcategoryDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        subcategoryDispatch();
    };

    const zoomIn = () => {
        setScale(scale * 2);
    }

    const zoomOut = () => {
        setScale(scale / 2);
    }


    useEffect(() => {
        dispatch(clearData());
        dispatch(categoryData({}));
        subcategoryDispatch();
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (categoryDatas && categoryOptions.length == 0) {
            let catdata = [];
            categoryDatas.map((data) => {
                return catdata.push({ id: data.id, name: data.name })
            });
            setCategoryOptions(catdata)
            console.log(catdata, "catdata")
        }
    }, [categoryDatas])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearsCatgDeleteLoading());
            subcategoryDispatch();
        }
        if (addloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearsCatgAddLoading());
            subcategoryDispatch();
        }
        if (updateloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearsCatgUpdateLoading());
            resetFormFields();
            subcategoryDispatch();
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }

    }, [deleteloading, addloading, updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Sub Category"
                    Breadcrumb={[{ name: "Sub Category" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Sub Category</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-7">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search Sub Category" />
                                                : ''}
                                        </div>
                                        <div className="col-md-5">
                                            <button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                                setshowAdd(!showAdd);
                                                console.log(resetForm, 'categoryFields')
                                                resetFormFields()
                                            }}>
                                                {(showAdd) ? "Cancel" : "Add Sub Category"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={(showAdd) ? "body" : "body hide"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className={(fields.class == "hide") ? "col-md-6 hide" : "col-md-6"}>
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className=""
                                                                            options={(fields?.options == "status") ? statusDropdown : (fields?.options == "category" && categoryOptions.length > 0) ? categoryOptions : []}
                                                                            values={(fields.value) ? fields?.value : []}
                                                                            disabled={false}
                                                                            placeholder={fields?.placeHolder}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={true}
                                                                            searchBy={"name"}
                                                                            labelField={"name"}
                                                                            valueField={fields?.valueField}
                                                                            dropdownHeight="300px"
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        />
                                                                        :
                                                                        (fields?.input == "radio") ?
                                                                            <>&nbsp;
                                                                                <Tooltip title={fields?.placeHolder}>
                                                                                    <span className="icon-question"></span>
                                                                                </Tooltip><br />
                                                                                <>
                                                                                    {fields?.options && fields.options.map((item) => {
                                                                                        console.log((item?.value == fields?.value), item?.value, fields?.value, 'asasas');
                                                                                        return <label class="fancy-radio">
                                                                                            <input type="radio" className={fields?.class}
                                                                                                value={item?.value}
                                                                                                name={fields?.name}
                                                                                                checked={(item?.value == fields?.value) ? "checked" : ""}
                                                                                                onChange={(e) => { CheckOrRadioChange(item?.value, index) }} /><span><i></i>{item.option}</span>
                                                                                        </label>
                                                                                    })}
                                                                                </>
                                                                            </>
                                                                            : (fields?.input == "file") ?
                                                                                <>&nbsp;
                                                                                    <Tooltip title={fields?.placeHolder}>
                                                                                        <span className="icon-question"></span>
                                                                                    </Tooltip><br />
                                                                                    <section className="row container">
                                                                                        <div className="col-md-8">
                                                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                                                <input {...getInputProps()}
                                                                                                    name={fields?.name} />
                                                                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                            </div></div>
                                                                                        <div className="col-md-4">


                                                                                            <div className="thumbcontainer">{(fileUpload ? <div className="dropzone_thum_img">
                                                                                                <div className="dropzone_thumb">
                                                                                                    <img
                                                                                                        src={fileUpload}
                                                                                                        className="dropzone_img"
                                                                                                    />
                                                                                                </div>
                                                                                            </div> : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                                <div className="dropzone_thumb">
                                                                                                    <img
                                                                                                        src={fields?.value}
                                                                                                        className="dropzone_img"
                                                                                                    />
                                                                                                </div>
                                                                                            </div> : '')}</div>
                                                                                        </div>
                                                                                        {fileRejectionItems}
                                                                                        <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                                    </section>
                                                                                </>
                                                                                : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitSubCategory(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "category_id" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("category_id");
                                                    }}
                                                >
                                                    Category
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Sub Category
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            subcategoryDatas && subcategoryDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        {row?.category?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status === 1
                                                            ? "Active"
                                                            : "In-active"}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="center">

                                                    </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { editSubCategory(row) }} title="Edit"><i className="icon-pencil text-info p-2"></i></a>
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteSubCategory(row) }} title="Delete"><i className="icon-trash text-danger p-2"></i></a>                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={subcategoryCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={showPreview} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {/* <button onClick={zoomIn}>Zoom in</button>
                        <button onClick={zoomOut}>Zoom out</button> */}
                        {imgSRC && (
                            <ReactCrop
                                crop={crop}
                                //onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onChange={c => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={false}
                                maxHeight={maxProductHeight}
                                maxWidth={maxProductWidth}
                                minWidth={maxProductWidth}
                                minHeight={maxProductHeight}
                                locked={true}
                                style={{ overflow: "scroll", height: "400px", width: imgSRCwidth }}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSRC}
                                    style={{ transform: `scale(${scale})`, width: imgSRCwidth, height: imgSRCheight, maxWidth: "unset" }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}

                        {!!completedCrop && (
                            <>
                                <div style={{ display: "none" }}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>)}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={cropImg}>
                        Crop
                    </Button>
                    <Button variant="primary" onClick={Imgmodalclose} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SubCategory;
