import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
    productData,
    AddProduct,
    UpdateProduct,
    DeleteProduct,
    DeleteBulkProduct,
    ChangeBulkProductStatus,
    productsSelector,
    clearData,
    clearProdAddLoading,
    clearProdUpdateLoading,
    clearProdLoadingDatas,
    clearProdDeleteLoading,
    clearProdBulkDLoading,
    clearProdBulkSLoading,
    clearErrormsg
} from "../../store/reducer/products";

import {
    itemsizeData,
    itemsizeSelector,
} from "../../store/reducer/itemsize";
import { API_STATUS } from "../../utils/constants";
import {
    productFields
} from "../fields/productform";
// import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { AudioOutlined } from '@ant-design/icons';
import { Input, Space, Select } from 'antd';
import { categoryData, categorySelector } from "../../store/reducer/category";

function Products() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { productDatas, productCount, loading, addloading, deleteloading, updateloading, errorMessage,
        bulkDloading, bulkSloading } = useSelector(productsSelector);
    const { itemsizeDatas, itemsizeCount } = useSelector(itemsizeSelector);
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const resetForm = productFields;
    const [formFields, setFormFields] = useState(productFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [updateID, setUpdateID] = useState('');
    const [getAllCheck, setgetAllCheck] = useState(false);
    const [getrowCheck, setgetrowCheck] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [categoryArray, setCategoryArray] = useState([])
    const [category_id,setCategory_id] = useState(0)
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    let [reward, setreward] = useState(0);

    //const formDatas = _.map()

    console.log(productDatas, 'productDatas');
    console.log(searchQuery, 'searchQuery');
    console.log(categoryDatas, 'categoryDatas');
    console.log(page,'page');

    const handleChangePage = (event, newPage) => {
        page = newPage;


        setPage(newPage);
        productDispatch();

        setSelectedRow([]);
        setgetAllCheck(false);
        setgetrowCheck([]);
    };

    useEffect(() => {
        dispatch(categoryData({ status: 1 }));
    }, [])

    useEffect(() => {
        if (categoryDatas && categoryDatas.length > 0) {
            let temp = [];
            for (let i in categoryDatas) {
                temp.push({
                    value: categoryDatas[i].id,
                    label: categoryDatas[i].name
                })
            }
            setCategoryArray(temp)
        }
    }, [categoryDatas])

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        productDispatch();

        setSelectedRow([]);
        setgetAllCheck(false);
        setgetrowCheck([]);
    };

    const editProduct = (row) => {
        navigate('/editproducts/' + row.id)
        navigate(0);
    };

    const checkAllIds = (id) => {
        if (id == "all") {
            setgetAllCheck(!getAllCheck);
            let sdata = [];
            if (productDatas) {
                productDatas.map((row) => {
                    getrowCheck[row.id] = !getAllCheck;
                    sdata.push(row.id);
                });
            }
            if (!getAllCheck) {
                setSelectedRow(sdata);
            } else {
                setSelectedRow([]);
            }
        } else {
            getrowCheck[id] = !getrowCheck[id]
            console.log(getrowCheck, "getrowCheck");
            let sdata = [];
            let data = getrowCheck.filter(function (element, key) {
                if (element !== undefined && element !== false) {
                    sdata.push(key);
                    return key;
                };
            });
            setSelectedRow(sdata);
            console.log(sdata, data, data.length)
            if (data.length == productCount)
                setgetAllCheck(true);
            else
                setgetAllCheck(false);
            setgetrowCheck([...getrowCheck])
        }
    };

    const deleteProduct = (row) => {
        //setshowAdd(true);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Product?',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                console.log('triggered');
                dispatch(DeleteProduct({ deleteID }));
            }
        })

    };

    const BulkAction = (type) => {
        let postData = { id: selectedRow };
        if (selectedRow.length > 0) {
            if (type == "delete") {
                MySwal.fire({
                    title: 'Are You Sure to Delete Products?',
                    icon: 'warning',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText:
                        'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(DeleteBulkProduct({ postData }));
                    }
                });
            }
            if (type == "active") {
                MySwal.fire({
                    title: 'Are You Sure to Active Products?',
                    icon: 'warning',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText:
                        'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        postData.status = 1;
                        dispatch(ChangeBulkProductStatus({ postData }));
                        setSelectedRow([]);
                        setgetAllCheck(false);
                        setgetrowCheck([]);

                    }

                });
            }
            if (type == "inactive") {
                MySwal.fire({
                    title: 'Are You Sure to Inactive Products?',
                    icon: 'warning',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText:
                        'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        postData.status = 0;
                        dispatch(ChangeBulkProductStatus({ postData }));
                        setSelectedRow([]);
                        setgetAllCheck(false);
                        setgetrowCheck([]);
                    }
                });
            }

        }
    }

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        productDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        productDispatch();
    };

    const resetFormFields = () => {
        //setUpdateID("")
        //setFiles([])

        let rdata = formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else if (data.name === "category_id")
                data.value = [];
            else if (data.name === "subcategory_id")
                data.value = [];
            else if (data.name === "itemsize_ids")
                data.value = [];
            else if (data.name === "default_size")
                data.value = [];
            else if (data.name === "uom")
                data.value = 0;
            else if (data.name === "reward")
                data.value = 0;
            else if (data.name === "custom_menus")
                data.value = 0;
            else
                data.value = "";
            return data;
        })
        setFormFields(rdata)
    };

    const Rewards = () => {
        reward = (reward == 0) ? 1 : 0
        setreward(reward);
        productDispatch()
    };

    const productDispatch = () => {
        dispatch(
            productData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
                reward: reward,
                category_id: category_id
            })
        );
    };
    useEffect(() => {
        dispatch(clearData());
        dispatch(itemsizeData({}));
        productDispatch();
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(reward, "reward")
    }, [reward])

    useEffect(() => {
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }

        }

        if (bulkDloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearProdBulkDLoading());
            //navigate('/products');
            productDispatch();
        }

        if (bulkSloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Status Updated Successfully!");
            dispatch(clearProdBulkSLoading());
            //navigate('/products');
            productDispatch();
        }

        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Product Deleted Successfully!");
            dispatch(clearProdDeleteLoading());
            productDispatch();
        }
    }, [errorMessage, bulkDloading, bulkSloading, deleteloading]);

    const { Search } = Input;
    // const suffix = (
    //     <AudioOutlined
    //         style={{
    //             fontSize: 18,
    //             color: 'black',
    //             paddingBottom:'3px'
    //         }}
    //     />
    // );

    const onSearch = (value, _e, info) => {
        console.log(value, _e, info, 'info?.source, value');
        searchQuery = value;
        setSearchQuery(value)
        setPage(0);
        productDispatch()
    }

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setCategory_id(value != undefined ? Number(value) : 0)
        setPage(0)
        dispatch(
            productData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
                reward: reward,
                category_id: value != undefined ? Number(value) : 0
            })
        );
    };
    const onSearch1 = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Products"
                    Breadcrumb={[{ name: "Products" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2>Products</h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor=""><b>Filter by Category : </b></label>
                                    </div>
                                    <div className="col-md-6">
                                        <Select
                                            allowClear={true}
                                            showSearch
                                            style={{ width: '60%' }}
                                            placeholder="Select a category"
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch1}
                                            filterOption={filterOption}
                                            options={categoryArray}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <button className="btn btn-danger mr-1" onClick={(e) => { BulkAction("delete") }}> Delete </button>
                                        <button className="btn btn-success mr-1" onClick={(e) => { BulkAction("active") }}> Active </button>
                                        <button className="btn btn-warning mr-1" onClick={(e) => { BulkAction("inactive") }}> Inactive </button>
                                    </div>
                                    <div className="col-md-8 row">
                                        <div className="col-md-6">
                                            {(!showAdd) ?
                                                // <input type="text" name="search" className="form-control" value={searchQuery}
                                                //     onChange={(e) => { searchData(e) }} placeholder="Search Product" />
                                                <Search placeholder="Search Products...." onSearch={onSearch} enterButton allowClear={() => {
                                                    searchQuery = '';
                                                    setSearchQuery('')
                                                    setPage(0);
                                                    productDispatch()
                                                }} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: '20px'
                                                }}
                                                    size="middle" />
                                                : ''}
                                        </div>
                                        <div className="col-md-6">
                                            <button className="btn btn-info mr-1 float-right" onClick={(e) => { Rewards() }}>  {(reward == 0) ? "Reward Products" : "Products"} </button>
                                            <Link to='/addproducts' className="btn btn-primary mr-1  float-right" onClick={resetFormFields}> Add Products</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <input type="checkbox"
                                                    checked={(getAllCheck) ? "checked" : ""}
                                                    required=""
                                                    onChange={(e) => { checkAllIds('all') }} /><span></span>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "item_code" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("item_code");
                                                    }}
                                                >
                                                    Item Code
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("name");
                                                    }}
                                                >
                                                    Product
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "category_id" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("category_id");
                                                    }}
                                                >
                                                    Category
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "subcategory_id" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("subcategory_id");
                                                    }}
                                                >
                                                    Sub Category
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell className={(reward == 0) ? "" : "hide"}>
                                                Price $
                                            </StyledTableCell>
                                            <StyledTableCell className={(reward == 0) ? "hide" : ""}>
                                                Reward Points
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                Item Size
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                                Sorting No.
                                            </StyledTableCell> */}
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            productDatas && productDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">

                                                        <input type="checkbox"
                                                            checked={(getrowCheck[row.id]) ? "checked" : ""}
                                                            required=""
                                                            onChange={(e) => { checkAllIds(row.id) }} /><span></span>

                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.item_code}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.category?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.subcategory?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="" className={(reward == 0) ? "" : "hide"}>
                                                        {row.price && row.price.length > 0 ?  _.orderBy(row.price, ['position'], ['asc']).map((priceprod, index) => {
                                                            if (index != row.price.length - 1)
                                                                return priceprod?.price + ', '
                                                            else
                                                                return priceprod?.price
                                                        }) : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="" className={(reward == 0) ? "hide" : ""}>
                                                        {row.price && row.price.length > 0 && row.price.map((priceprod, index) => {
                                                            if (index != row.price.length - 1)
                                                                return priceprod?.points + ', '
                                                            else
                                                                return priceprod?.points
                                                        })}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.price && row.price.length > 0 ?
                                                            _.orderBy(row.price, ['position'], ['asc']).map((priceprod, index) => {
                                                                console.log(row.price, 'row.price');
                                                                let itemsize = _.find(itemsizeDatas, { 'id': priceprod?.itemsize_id });
                                                                if (index != row.price.length - 1)
                                                                    return itemsize?.description + ', '
                                                                else
                                                                    return itemsize?.description
                                                            }) : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status === 1
                                                            ? "Active"
                                                            : "In-active"}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <Link to={'/editproducts/' + row.id} onClick={resetFormFields} title="Edit"> <i className="icon-pencil text-info p-2"></i></Link>
                                                        {/* <a href="javascript:void(0);" onClick={(e) => { editProduct(row) }}><i className="icon-pencil text-info p-2"></i></a> */}
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteProduct(row) }} title="Delete"><i className="icon-trash text-danger p-2"></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={productCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
