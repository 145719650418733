import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { TimePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import {
    kioskData,
    Updatekiosk,
    kioskSelector,
    clearLoadingDatas,
    cleargeneralAddLoading,
    cleargeneralUpdateLoading,
    cleargeneralDeleteLoading,
    clearData,
    clearErrormsg,
} from "../../store/reducer/kiosk";
import { API_STATUS } from "../../utils/constants";
import {
    kioskFields
} from "../fields/kioskform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../../services/config";

function Kiosk() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { kioskDatas, loading, addloading, deleteloading, updateloading, errorMessage, kioskCount } = useSelector(kioskSelector);
    const resetForm = kioskFields;
    const [formFields, setFormFields] = useState(kioskFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    const timezoneDropdown = [{ name: 'CST - America/Chicago', id: "America/Chicago" }, { name: 'PST - America/Los_Angeles', id: "America/Los_Angeles" }, { name: 'America/Detroit', id: "America/Detroit" }, { name: 'MST - America/Boise', id: "America/Boise" }]
    const timeInterval = [{ name: '15 mins', id: "15" }, { name: '30 mins', id: "30" }, { name: '45 mins', id: "45" }, { name: '60 mins', id: "60" }]
    const [connectedPrinters, setconnectedPrinters] = useState([]);
    const [kitchenPrinterArray, setKitchenPrinterArray] = useState([]);
    const [frontWindowPrinterArray, setFrontWindowPrinterArray] = useState([])
    const [printerValue, setPrinterValue] = useState({
        frontWindowVal: '',
        kitchenVal: ''
    })
    console.log(connectedPrinters, 'connectedPrinters');

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");

    const format = 'hh:mm A';
    //const formDatas = _.map()

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const CheckOrRadioChange = (value, index) => {
        formFields[index].value = value;
        setFormFields([...formFields]);
    }
    const onChangeTime = (value, time, index, name) => {
        console.log(value, time, "test");
        if (name == "end_time") {
            let cval = new Date(value);
            let getdata = _.filter(formFields, { name: "start_time" })
            console.log(value, getdata[0].tvalue);
            if (getdata[0].tvalue > value) {
                return false;
            }
        }
        if (name == "start_time") {
            let getdata = _.filter(formFields, { name: "end_time" })
            console.log(value, getdata[0].tvalue);
            if (getdata[0].tvalue < value) {
                return false;
            }
        }
        formFields[index].value = time;
        formFields[index].tvalue = value;
        setFormFields([...formFields]);
    };

    const DropdownChange = (value, index) => {
        console.log(value, 'valll')
        formFields[index].value = value;
        setFormFields([...formFields]);
    }

    const submitGeneral = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            //const generaltData = new FormData();
            const datas = formFields.map((data) => {
                if (data?.name === "timezone") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val && val[0].id;
                }
                if (data?.name === "start_interval") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val && val[0].id;
                }
                if (data?.name === "end_interval") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val && val[0].id;
                }
                else {
                    return arr[data?.name] = data.value;
                }
                //return arr[data?.name] = data.value;
            });
            arr.id = updateID;
            const kioskData = arr;
            console.log(kioskData, "generaltData");
            //return false;
            //const generaltData = arr;
            dispatch(Updatekiosk({ kioskData }));

            setshowAdd(false);
        } else {
            setShowvalidator(true);
            console.log("test");
        }
    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
        })
    };

    const generalDispatch = () => {
        dispatch(
            kioskData({})
        );
    };

    useEffect(() => {
        dispatch(clearData())
        generalDispatch()
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(formFields, "formFields")
    }, [formFields])

    useEffect(() => {
        console.log(kioskDatas, "formFields")
        if (kioskDatas && kioskDatas.length) {
            console.log(kioskDatas, "editDatas in")
            const row = kioskDatas[0];
            setUpdateID(row.id);
            let chosen_data = []
            let data = formFields.map((data, index) => {
                if (data.name === "start_time") {
                    data.value = row.start_time;
                    data.tvalue = dayjs(row.start_time, format);
                } if (data.name === "end_time") {
                    data.value = row.end_time;
                    data.tvalue = dayjs(row.end_time, format);
                }
                if (data.name === "start_interval") {
                    let set_status = _.filter(timeInterval, { id: row.start_interval });
                    data.value = set_status;
                }
                if (data.name === "end_interval") {
                    let set_status = _.filter(timeInterval, { id: row.end_interval });
                    data.value = set_status;
                }
                if (data.name === "message")
                    data.value = row.message;
                if (data.name === "schedule")
                    data.value = row.schedule;
                if (data.name === "timezone") {
                    let set_status = _.filter(timezoneDropdown, { id: row.timezone });
                    data.value = set_status;
                }
                return data;
            })
            setFormFields(data);
        }
    }, [kioskDatas])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(cleargeneralDeleteLoading())
            generalDispatch()
        }
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(cleargeneralAddLoading())
            generalDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(cleargeneralUpdateLoading())
            resetFormFields();
            generalDispatch()
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);


    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="KIOSK"
                    Breadcrumb={[{ name: "Kiosk Configuration" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Kiosk Warm Up/Cool Down Configuration</h2>
                                    </div>
                                </div>
                            </div>
                            <div className={"body"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                console.log(fields, 'fields');
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <>&nbsp;
                                                                        <Tooltip title={fields?.placeHolder}>
                                                                            <span className="icon-question"></span>
                                                                        </Tooltip><br />
                                                                        <textarea
                                                                            className="form-control"
                                                                            value={fields?.value}
                                                                            cols="30"
                                                                            name="textarea"
                                                                            required=""
                                                                            rows="2"
                                                                            onChange={(e) => {
                                                                                FieldChange(e, index)
                                                                            }}
                                                                        ></textarea>
                                                                    </>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={(fields?.options == "timezone") ? timezoneDropdown : timeInterval}
                                                                            values={fields?.value}
                                                                            disabled={formFields.includes(fields?.value) ? true : false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={false}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index); }}
                                                                        /> :
                                                                        (fields?.input == "radio") ?
                                                                            <>&nbsp;
                                                                                <Tooltip title={fields?.placeHolder}>
                                                                                    <span className="icon-question"></span>
                                                                                </Tooltip><br />
                                                                                {fields?.options && fields.options.map((item) => {
                                                                                    return <label class="fancy-radio">
                                                                                        <input type="radio" className={fields?.class}
                                                                                            value={item?.value}
                                                                                            name={fields?.name}
                                                                                            checked={(item?.value == fields?.value) ? "checked" : ""}
                                                                                            onChange={(e) => { CheckOrRadioChange(item?.value, index) }} /><span><i></i>{item.option}</span>
                                                                                    </label>
                                                                                })}
                                                                            </>
                                                                            : (fields?.input == "timepicker") ?
                                                                                <TimePicker onChange={(e, timeString) => {
                                                                                    console.log(timeString, e, 'test--timeString=');
                                                                                    onChangeTime(e, timeString, index, fields?.name);
                                                                                }} value={dayjs(fields?.value, format)} format={format}
                                                                                    className="form-control" style={{ display: "flex" }} allowClear={false} />
                                                                                : ''
                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitGeneral(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kiosk;
