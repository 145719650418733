import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { kioskList, kioskUpdate } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "general";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    kioskDatas: null,
    kioskCount: 0
};

export const kioskData = createAsyncThunk(
    `${namespace}/kioskData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(generaltData, secretKey)
            const data = await kioskList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const Updatekiosk = createAsyncThunk(
    `${namespace}/Updatekiosk`,
    async ({ kioskData }, { rejectWithValue, dispatch }) => {
        try {
            let payload = EncryptDecrypt.encryptdata(kioskData, secretKey)
            const data = await kioskUpdate({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const kioskSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        cleargeneralLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
        },
        cleargeneralAddLoading: (state) => {
            state.addloading = "initial";
        },
        cleargeneralUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        cleargeneralDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [kioskData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [kioskData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.generalCount = payload?.data?.count;
            // state.kioskDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.response, "payload")
            state.kioskCount = payloaddatas?.count;
            state.kioskDatas = payloaddatas?.response;
        },
        [kioskData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [Updatekiosk.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [Updatekiosk.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [Updatekiosk.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas, cleargeneralAddLoading, cleargeneralUpdateLoading, cleargeneralDeleteLoading, clearErrormsg } = kioskSlice.actions;

export const kioskSelector = (state) => state.kiosk;

export default kioskSlice.reducer;
