import React, { useState } from "react";
import PageHeader from "../../view/pageheader";
import { Button, Card, Table, Alert, Tag } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
function Credentials() {

    const [copyState, setCopyState] = useState(false)
    const [copyUrl, setcopyUrl] = useState(false)
    const [password, setPassword] = useState(false)

    function copy(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }

    const columns = [
        {
            title: 'Module',
            dataIndex: 'module_name',
            key: 'module_name',
            render: (text, record) => (record?.module_name == 'Kitchen Display' || record?.module_name == 'Order Dispatch') ? <>{record?.module_name} <br /><br />
                <Tag
                    // onClick={() => {
                    //     window.open(
                    //         'https://admin.dipmenu.com',
                    //         "_blank",
                    //         `width=${window.screen.width},height=${window.screen.height},top=0,left=0,fullscreen=yes`
                    //     );
                    // }}
                    // bordered={false}
                    icon={<ExclamationCircleOutlined />}
                    color="red"
                >
                    Open in New Icognito/Private Window while using Admin Panel
                </Tag> </> : record?.module_name,
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: (text, record, index) => {
                if (record.url != '') {
                    return (
                        <span>{record.url}<br /><Button className="mt-2" type="primary" style={{ backgroundColor: copyUrl === index ? 'green' : '' }} onClick={() => {
                            setcopyUrl(index)
                            setPassword(true)
                            setCopyState(true)
                            copy(record.url)
                        }}>{copyUrl === index ? 'Copied' : 'Copy'}</Button></span>
                    )
                }
                else {
                    return <span><center>-</center></span>
                }
            }
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            render: (text, record, index) => {
                if (record.username != '') {
                    return (
                        <span>{record.username}<br /><Button className="mt-2" type="primary" style={{ backgroundColor: copyState === index ? 'green' : '' }} onClick={() => {
                            setCopyState(index)
                            setPassword(true)
                            setcopyUrl(true)
                            copy(record.username)
                        }}>{copyState === index ? 'Copied' : 'Copy'}</Button></span>
                    )
                }
                else {
                    return <span><center>-</center></span>
                }
            }
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (text, record, index) => {
                if (record.password != '') {
                    return (
                        <span>{record.securedPassword}<br /><Button className="mt-2" type="primary" style={{ backgroundColor: password === index ? 'green' : '' }} onClick={() => {
                            // if(record.key === )
                            setPassword(index)
                            setCopyState(true)
                            setcopyUrl(true)
                            copy(record.password)

                        }}>{password === index ? 'Copied' : 'Copy'}</Button></span>
                    )
                } else {
                    return <span><center>-</center></span>
                }
            }
        }
    ]

    const data = [
        {
            key: 0,
            module_name: 'Admin',
            url: `${process.env.REACT_APP_ADMIN_URL}`,
            username: 'horizonadmin@yopmail.com',
            password: 'horizonadmin@1234',
            securedPassword: '***************'
        },
        {
            key: 1,
            module_name: 'Website',
            url: `${process.env.REACT_APP_WEBSITE_URL}`,
            username: '',
            password: ''
        },
        {
            key: 2,
            module_name: 'Kitchen Display',
            url: `${process.env.REACT_APP_KITCHEN_URL}`,
            username: 'kitchendisplay@yopmail.com',
            password: 'kitchendisplay@1234',
            securedPassword: '***************'

        },
        {
            key: 3,
            module_name: 'Order Dispatch',
            url: `${process.env.REACT_APP_ORDER_DISPATCH_URL}`,
            username: 'kitchendispatch@yopmail.com',
            password: 'kitchendispatch@1234',
            securedPassword: '***************'

        },

    ];

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Credentials"
                    Breadcrumb={[{ name: "Credentials" }]}
                />
                <Card>
                    <Table columns={columns} dataSource={data} pagination={false} />
                </Card>
            </div>

        </div>
    )

}

export default Credentials