import React from "react";
import styled from "@emotion/styled";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {Button} from 'antd';


const Container = styled("div")`
  margin: 8px;
  border-radius: 2px;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
//   width: 150px;
  background: white;
  scroll-behavior: smooth;
`;
const Title = styled("h3")`
  padding: 8px;
  font-size:20px
`;

const TaskList = styled("div")`
  padding: 8px;
  flex-grow: 1;
  min-height: 250px;
  transition: background-color ease 0.2s;
  width:225px;
  height:250px;
  overflow-y: hidden; /* Initially hide the scrollbar */
  transition: overflow-y 0.3s ease; /* Add a smooth transition for the scrollbar */

  /* Custom scrollbar styles for Google Chrome */
  &::-webkit-scrollbar {
    width: 6px; /* Adjust the width for a thin scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgray; /* Color of the scrollbar thumb */
  }
  &::-webkit-scrollbar-track {
    background-color: lightgray; /* Color of the scrollbar track */
  }

  &:hover {
    overflow-y: auto; /* Enable vertical scroll when hovering */
  }
  background-color: ${props =>
    props.isDraggingOver ? "#49c5b6" : "white"};
`;
const Column = ({ tasks, column, index }) => {
  console.log(tasks, column, index, 'tasks, column, index');
  return (
    <Draggable draggableId={column.id} index={index} type="column">
      {provided => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Title>{column.title}</Title>
          <Droppable droppableId={column.id} type="task">
            {(provided, snapshot) => (
              <TaskList
                isDraggingOver={snapshot.isDraggingOver}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tasks && tasks.length > 0 && tasks.map((task, index) => {
                  console.log(task, index, 'task, index');
                  return <Draggable draggableId={String(task && task.id)} index={index} type="task">
                    {(provided, snapshot) => (
                      <Container
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        isDragging={snapshot.isDragging}
                      >
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-9">
                            <p style={{ height: 'auto', padding: '5px' }}>{task && task.content}</p>
                            </div>
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Button style={{ backgroundColor: '#49c5b6', height: '35px', width: '3px', color: 'black', fontWeight: 'bold',fontSize:'12px',display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                            </div>
                          </div>
                        </div>
                      </Container>
                    )}
                  </Draggable>
                })}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  );
};



export default Column;
