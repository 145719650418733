import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tryLogin, forgotPassword, resetPassword } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import { toast } from 'react-toastify';

const namespace = "login";

const initialState = {
    loading: "initial",
    forgotloading: "initial",
    resetloading: "initial",
    errorMessage: null,
    loginData: null,
};

export const doLogin = createAsyncThunk(
    `${namespace}/doLogin`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            postData.role = "admin";
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await tryLogin(postData);
            console.log("getScanCount--> ", data);
            toast.success("Loggedin Successfully!");
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const forgotPwd = createAsyncThunk(
    `${namespace}/forgotPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await forgotPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const resetPwd = createAsyncThunk(
    `${namespace}/resetPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await resetPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const loginSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        logOut: () => {
            localStorage.clear();
            window.location.reload(true);
        },
    },
    extraReducers: {
        [doLogin.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [doLogin.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log({ payload });
            state.loginData = payload?.data;
            localStorage.setItem('authToken', payload?.data?.accessToken)
            localStorage.setItem('isAuthenticated', true)
            localStorage.setItem('username', payload?.data?.username)
            localStorage.setItem('role_id', payload?.data?.roleId);

            if (payload?.data?.roleId == 4) {
                localStorage.setItem('kitchenDisplay', true)
            }
            if (payload?.data?.roleId == 5) {
                localStorage.setItem('kitchenDispatch', true)
            }
        },
        [doLogin.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [forgotPwd.pending](state) {
            state.forgotloading = API_STATUS.PENDING;
        },
        [forgotPwd.fulfilled](state, { payload }) {
            state.forgotloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [forgotPwd.rejected](state, action) {
            state.forgotloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [resetPwd.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [resetPwd.fulfilled](state, { payload }) {
            state.resetloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [resetPwd.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, logOut } = loginSlice.actions;

export const loginSelector = (state) => state.login;

export default loginSlice.reducer;
