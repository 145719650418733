import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import { Tree, Tabs, Card, Button } from 'antd';
import {
    categoryData,
    categorySelector,
} from "../../store/reducer/category";
import {
    subcategoryData,
    subcategoryFilterData,
    subCategorySelector,
} from "../../store/reducer/subcategory";
import {
    itemsizeData,
    itemsizeSelector,
} from "../../store/reducer/itemsize";
import {
    productData,
    productsSelector,
} from "../../store/reducer/products";
import {
    clearData,
    sortingUpdate,
    sortingSelector,
} from "../../store/reducer/sorting";
import { custommenuData, custommenuSelector } from "../../store/reducer/custommenu";
import { custommenuitemSelector } from "../../store/reducer/custommenuitem";
import { custommenuitemData } from "../../store/reducer/custommenuitem";
import _, { filter } from 'lodash';
import { API_STATUS } from "../../utils/constants";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { assestURL } from "../../services/config";
import Select from "react-dropdown-select";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListManager } from "react-beautiful-dnd-grid";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


function VerticalSorting() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { productDatas, productCount } = useSelector(productsSelector);
    console.log(productDatas, 'productDatas-check');
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const { itemsizeDatas, itemsizeCount } = useSelector(itemsizeSelector);
    const { subcategoryDatas, subcategoryCount } = useSelector(subCategorySelector);
    const { custommenuDatas } = useSelector(custommenuSelector);
    console.log(custommenuDatas, 'custommenuDatas-check');
    const [customMenuDataArray, setCustomMenuDataArray] = useState([])
    const { custommenuitemDatas } = useSelector(custommenuitemSelector);
    const { updateloading, errorMessage } = useSelector(sortingSelector);
    const [treeData, setTreeData] = useState([]);
    const [subcatgtreeData, setSubcatgtreeData] = useState([]);
    const [customMenuItemTreeData, setCustomMenuItemTreeData] = useState([])
    const [producttreeData, setProducttreeData] = useState([]);
    const [customMenutreeData, setcustomMenutreeData] = useState([]);
    const x = 3;
    const y = 2;
    const z = 1;
    const defaultData = [];
    const [catgId, setCatgID] = useState([]);
    const [subcatgId, setSubcatgID] = useState([]);
    const [productId, setproductId] = useState([]);
    const [tab, setTab] = useState(0);
    const [items, setItems] = useState([]);
    const [categoryItems, setCategoryItems] = useState([])
    const [subCatItems, setSubCatItems] = useState([])
    const [customMenuItems, setCustomMenuItems] = useState([])
    const [productItems, setProductItems] = useState([])
    const [isHorizontalDrag, setIsHorizontalDrag] = useState(false);
    const [tabClickVal, setTabClickVal] = useState(1)
    const [loading, setLoading] = useState(false);

    console.log(loading, 'loading');
    console.log(customMenuItems, custommenuitemDatas, 'customMenuItems')

    const buildData = (datas, type) => {
        if (tabClickVal === 5) {
            let builtdatas = []
            let datas1 = datas.map((catg) => {
                builtdatas.push({ id: catg.id, name: catg.name, image: catg.image, order_no: catg.order_no, weight: catg.weight });
            })
            return _.orderBy(builtdatas, ['order_no'], ['asc']);
        }
        else {
            let builtdatas = []
            let datas1 = datas.map((catg) => {
                console.log(catg, 'catg123');
                builtdatas.push({ id: catg.id, name: catg.name, image: catg.image, order_no: catg.order_no });
            })
            return _.orderBy(builtdatas, ['order_no'], ['asc']);
        }

    };

    const sortCustomMenus = (data, sortingArr) => {
        console.log(data, sortingArr, 'data, sortingArr');
        let result = []
        result = data.sort((a, b) => sortingArr.indexOf(a.id) - sortingArr.indexOf(b.id));
        return result
    }

    // useEffect(()=>{
    //     if(customMenuItems && customMenuItems.length > 0){
    //         let datas = buildData(customMenuItems,4)
    //         console.log(datas,'datasdatas');
    //     }

    // },[customMenuItems])

    const submitsort = (e, index) => {
        console.log(index, 'index');
        let postData = { category: [], subcategory: [], product: [], customMenu: [], customMenuItem: [], productID: '' };
        if (index == 1) {
            postData = { category: categoryItems, subcategory: [], product: [], customMenu: [], customMenuItem: [], productID: '' };
        }
        if (index == 2) {
            postData = { category: [], subcategory: subCatItems, product: [], customMenu: [], customMenuItem: [], productID: '' };
            setLoading(false)
            if (subCatItems.length == 0)
                return false;
        }
        if (index == 3) {
            postData = { category: [], subcategory: [], product: productItems, customMenu: [], customMenuItem: [], productID: '' };
            setLoading(false)
            if (producttreeData.length == 0)
                return false;
        }
        if (index == 4) {
            let data = customMenuItems.map((item, index) => ({
                ...item,
                order_no: Number(index) // You can adjust this expression as needed
            }));
            postData = { category: [], subcategory: [], product: [], customMenu: data, customMenuItem: [], productID: productId[0].id };
            setLoading(false)
            // if (customMenuItems.length == 0)
            // console.log('length-0');
            //     return false;
        }
        if (index == 5) {
            postData = { category: [], subcategory: [], product: [], customMenu: [], customMenuItem: items, productID: '' };
            setLoading(false)
            if (items.length == 0)
                return false;
        }

        console.log(postData, 'postData')
        dispatch(sortingUpdate({ postData }))
    };

    const onSortEnd = (oldIndex, newIndex) => {
        setTreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const onSortSubcatEnd = (oldIndex, newIndex) => {
        setSubcatgtreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const onSortProductEnd = (oldIndex, newIndex) => {
        setProducttreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };


    const onSortCustomMenuEnd = (oldIndex, newIndex) => {
        setcustomMenutreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const onSortCustomMenuItemEnd = (oldIndex, newIndex) => {
        setCustomMenuItemTreeData((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    const filterCustomMenuDatas = (customenuDatas, data_check) => {
        // Filter the customenuDatas array to include only objects with ids present in the data_check array
        const filteredData = customenuDatas.filter(item => data_check.includes(item.id.toString()));
        return filteredData;
    }

    const DropdownChange = (value, index) => {
        console.log(value, index, 'data==');
        let datas = [];
        let filtered_data = [];
        if (value.length > 0) {
            if (index == 1) {
                setCatgID(value);
                setSubcatgID([])
                setproductId([])
                setCustomMenuItems([])
                setProductItems([])
                if (subcategoryDatas && subcategoryDatas.length > 0) {
                    filtered_data = _.filter(subcategoryDatas, { category_id: value[0].id });
                    console.log(filtered_data, "filtered_data")
                    datas = buildData(filtered_data, 2);
                    console.log(datas, 'datas');
                    setSubcatgtreeData(datas);
                    setSubCatItems(datas)
                    setProducttreeData([]);

                }
            } else if (index == 2) {
                setSubcatgID(value);
                setproductId([])
                setCustomMenuItems([])
                setProductItems([])
                if (productDatas && productDatas.length > 0) {
                    filtered_data = _.filter(productDatas, { subcategory_id: value[0].id });
                    console.log(filtered_data, "filtered_data")
                    datas = buildData(filtered_data, 3);
                    setProducttreeData(datas);
                    setProductItems(datas)
                }
            }
            else if (index == 3) {
                setproductId(value);
                if (custommenuDatas && custommenuDatas.length > 0) {
                    filtered_data = _.filter(productDatas, { id: value[0].id });
                    console.log(filtered_data, 'result-filtered_data==1000');
                    // let filterCustomSize = _.find(productDatas.data[0].custom_size, { itemsize_id: `${defaultItems}` })
                    // let datas_check = filtered_data[0].category_id == 4 && filtered_data[0].custom_size.length ? filtered_data[0].custom_size[0].custom_menu_id.split(',') : filtered_data[0].custom_menu_ids.split(',');
                    let datas_check = filtered_data && filtered_data[0] && filtered_data[0]?.custom_menu_ids?.split(',');
                    console.log(datas_check, 'result-datas_check');
                    console.log(customMenuDataArray, 'customMenuDataArray');
                    // var result = customMenuDataArray.filter((e) => {
                    //     console.log(e, datas_check.includes(Number(e.id)), 'eeeeee');
                    //     datas_check.includes(Number(e.id))
                    // });
                    // console.log(result, 'result-55');

                    var filteredData = filterCustomMenuDatas(custommenuDatas, datas_check);
                    console.log(filteredData,'filteredData');

                    if (filtered_data[0].sorted_customs) {
                        filteredData = sortCustomMenus(filteredData, filtered_data[0].sorted_customs)
                    }
                    // console.log(result, 'result123');
                    setcustomMenutreeData(filteredData)
                    setCustomMenuItems(filteredData)
                }
            }
            else if (index == 4) {
                datas = buildData(customMenuItems, 4)
                setCustomMenuItems(datas)
            }
        }
    }

    const CustomMenuDropdownChange = (value, index) => {
        console.log(value, index, catgId, 'customMenuOnChange');
        let datas = []
        let filteredData = []
        if (value && value.length > 0) {
            setCatgID(value);
            if (custommenuitemDatas && custommenuitemDatas.length > 0) {
                filteredData = _.filter(custommenuitemDatas, { custom_menu_id: value[0].id })
                datas = buildData(filteredData, 2);
                setCustomMenuItemTreeData(datas)
                setItems(datas)
            }
        }

    }

    useEffect(() => {
        dispatch(categoryData({ status: 1 }));
        dispatch(subcategoryData({ status: 1 }));
        dispatch(itemsizeData({ status: 1 }));
        dispatch(productData({ status: 1 }));
        dispatch(custommenuData({ status: 1 }))
        dispatch(custommenuitemData({ status: 1 }))
        console.log(process.env.REACT_APP_TITLE, "env");
    }, []);

    useEffect(() => {
        if (custommenuDatas && custommenuDatas.length > 0) {
            setCustomMenuDataArray(custommenuDatas)
        }

    }, [custommenuDatas])

    useEffect(() => {
        if (((subcategoryDatas && subcategoryDatas.length > 0) && (catgId && catgId.length > 0))) {
            let filtered_data = _.filter(subcategoryDatas, { category_id: catgId[0].id });
            console.log(filtered_data, "filtered_data")
            let datas = buildData(filtered_data, 2);
            console.log(datas, 'datas');
            if (datas && datas.length > 0) {
                setSubCatItems(datas);
                setLoading(true)
            }
        }
    }, [subcategoryDatas, catgId])

    useEffect(() => {
        if (((productDatas && productDatas.length > 0) && (catgId && catgId.length > 0) && (subcatgId && subcatgId.length > 0))) {
            let filtered_data = _.filter(productDatas, { subcategory_id: subcatgId[0].id });
            let datas = buildData(filtered_data, 3);
            console.log(datas, '567');
            if (datas && datas.length > 0) {
                setProductItems(datas);
                setLoading(true)
            }
        }

    }, [catgId, subcatgId, productDatas])

    console.log(custommenuDatas, productId, productDatas, 'custommenuDatas,productId,productDatas');

    // useEffect(() => {
    //     if (((custommenuDatas && custommenuDatas.length > 0) && (productId && productId.length > 0) && (productDatas && productDatas.length > 0))) {
    //         let filtered_data = _.filter(productDatas, { id: productId[0].id });
    //         // console.log(filtered_data, 'result-filtered_data==9');
    //         // var result = custommenuDatas.filter((e) => filtered_data[0].custom_menu_ids.includes(e.id));
    //         // console.log(result, 'result==');
    //         console.log(filtered_data, 'result-filtered_data==1000');
    //         // let filterCustomSize = _.find(productDatas.data[0].custom_size, { itemsize_id: `${defaultItems}` })
    //         let datas_check = filtered_data && filtered_data[0] && filtered_data[0]?.custom_menu_ids?.split(',');

    //         var filteredData = filterCustomMenuDatas(custommenuDatas, datas_check);
    //         console.log(filteredData,'filteredData');

    //         if (filtered_data[0].sorted_customs) {
    //             filteredData = sortCustomMenus(filteredData, filtered_data[0].sorted_customs)
    //         }
           
    //         if (filteredData) {
    //             setCustomMenuItems(filteredData)
    //             setLoading(true)
    //         }

    //     }
    // }, [productDatas, custommenuDatas, productId])

    useEffect(() => {
        if (((custommenuitemDatas && custommenuitemDatas.length > 0) && (catgId && catgId.length > 0))) {
            let filteredData = _.filter(custommenuitemDatas, { custom_menu_id: catgId[0].id })
            let datas = buildData(filteredData, 2);
            if (datas && datas.length > 0) {
                setItems(datas)
                setLoading(true)
            }
        }

    }, [catgId, custommenuitemDatas])


    useEffect(() => {
        if (customMenuItemTreeData && customMenuItemTreeData.length > 0) {
            setItems(customMenuItemTreeData)
        }
        if (categoryDatas && categoryDatas.length > 0) {
            setCategoryItems(categoryDatas)
        }
        if (subcatgtreeData && subcatgtreeData.length > 0) {
            setSubCatItems(subcatgtreeData)
        }
        // if (customMenutreeData && customMenutreeData.length > 0) {
        //     setCustomMenuItems(customMenutreeData)
        // }
        if (producttreeData && producttreeData.length > 0) {
            setProductItems(producttreeData)
        }

    }, [producttreeData, customMenutreeData, subcatgtreeData, categoryDatas, customMenuItemTreeData])

    console.log(items, 'sortedItems');

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const updatedItems = [...items];
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        setItems(updatedItems);
    };

    const handleDragStart = (start) => {
        // Check if start.initial and start.initial.client exist
        if (start.initial && start.initial.client) {
            const { movementX, movementY } = start.initial.client;

            // Use movementX and movementY as needed
            const isHorizontalDrag = Math.abs(movementX) > Math.abs(movementY);
            setIsHorizontalDrag(isHorizontalDrag);
        }
    };
    useEffect(() => {
        if (categoryDatas) {
            let datas = buildData(categoryDatas, 1);
            setTreeData(datas);
        }
    }, [categoryDatas]);

    useEffect(() => {

    }, [subcatgtreeData])

    useEffect(() => {
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearData())
            dispatch(categoryData({ status: 1 }));
            dispatch(subcategoryData({ status: 1 }));
            dispatch(productData({ status: 1 }));
            dispatch(custommenuData({ status: 1 }))
            dispatch(custommenuitemData({ status: 1 }))
            // window.location.reload()
            if (catgId.length > 0) {
                DropdownChange(catgId)
            }
            if (subcatgId.length > 0) {
                DropdownChange(subcatgId)
            }
            // setSubcatgtreeData([]);
            // setProducttreeData([]);
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }

        }
    }, [updateloading, errorMessage])

    console.log(treeData, 'treeData');
    console.log(categoryDatas, 'categoryDatas');
    console.log(subcatgtreeData, 'subcatgtreeData');
    console.log(customMenuDataArray, 'customMenuDataArray');
    console.log(customMenuItemTreeData, items, 'customMenuItemTreeData && items');

    const tabClick = (key, event) => {
        console.log(key, 'tabClick');
        setTabClickVal(Number(key))
        setCatgID([]); setSubcatgID([]); setSubcatgtreeData([]); setProducttreeData([]); setproductId([]); setCustomMenuItemTreeData([]); setItems([]); setCustomMenuItems([]); setProductItems([]); setCategoryItems([]); setSubCatItems([])
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: 0,
        margin: `0 0 ${grid}px 0`, // Adjust margin as needed
        // background: isDragging ? '#49c5b6' : 'white',
        minWidth: '50px', // Adjust width as needed
        boxSizing: 'border-box',
        ...draggableStyle,
        width: '90%', // Make each item take the full width of the column
    });

    const getListStyle = isDraggingOver => ({
        display: 'flex',
        flexDirection: 'column', // Align items vertically
        padding: grid,
        // marginLeft: '8%'
        // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    });

    const cardStyle = {
        padding: '0px',
        width: '800px',
        height: tabClickVal === 4 ? '100px' : '125px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)', // Adjust the shadow values as needed
    };


    const DraggableCard = ({ item, index }) => {
        console.log(index, item, 'itemIndex');
        return (
            <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                        {/* Your card design goes here */}
                        {/* <div style={{ padding: '10px', border: '0.1px solid grey', borderRadius: '8px', color: 'white', height: '150px' }}>
                            <div style={{ textAlign: 'center' }}>
                                <img height={100} width={155} src={assestURL + item.image} />
                                <p style={{ color: 'black' }}>{item.name}</p></div>
                        </div> */}
                        {/* <div style={{ padding: '0px', border: '0.1px solid grey', borderRadius: '8px', color: 'white', height: tabClickVal == 4 ? '110px' : '125px', width: '100%' }}>
                            <Button style={{ backgroundColor: '#49c5b6', marginLeft: '90%', marginTop: '1%', height: '30px', width: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', fontWeight: 'bold' }} shape="round">{index + 1}</Button>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className={tabClickVal === 4 ? "col-md-4" : "col-md-5"}>
                                        {tabClickVal === 4 ? '' : <img height={80} width={110} src={assestURL + item.image} />}
                                    </div>
                                    <div className={tabClickVal === 4 ? "col-md-8" : "col-md-7"}>
                                        <span style={{ color: 'black', wordBreak: 'break-word', fontSize: tabClickVal == 1 || tabClickVal == 2 ? '18px' : '16px', }}>{item.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="vertical-sorting" style={{ marginBottom: '20px' }}>
                            <Card className="vertical-sorting" style={cardStyle}>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            {tabClickVal === 4 ? '' : <img height={80} width={100} src={assestURL + item.image} />}
                                        </div>
                                        <div className="col-md-7 mt-3">
                                            <span style={{ color: 'black', wordBreak: 'break-word', fontSize: tabClickVal == 1 || tabClickVal == 2 || tabClickVal == 4 ? '18px' : '16px', }}>{item.name}</span>
                                            {item.weight ?
                                                <p className="mt-2" style={{ fontSize: '13px' }}><b>Weight:{item.weight}</b></p> : ''}
                                        </div>
                                        <div className="col-md-1" style={{ borderLeft: '2px solid darkgrey', height: tabClickVal === 4 ? '55px' : '75px' }}></div>
                                        <div className="col-md-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Button style={{ backgroundColor: '#49c5b6', height: '40px', width: '40px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    };

    const onDragEnd = result => {
        // Your onDragEnd logic here
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const updatedItems = [...items];
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        setItems(updatedItems);
    };

    // const onCategoryDragEnd = result => {
    //     // Your onDragEnd logic here
    //     if (!result.destination) {
    //         return; // Dragged outside the list
    //     }

    //     const updatedItems = [...categoryItems];
    //     const [removed] = updatedItems.splice(result.source.index, 1);
    //     updatedItems.splice(result.destination.index, 0, removed);

    //     setCategoryItems(updatedItems);
    // };

    const onCategoryDragEnd = (result) => {
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex === destinationIndex) {
            // If the source and destination indices are the same, no need to update the state
            return;
        }

        const updatedItems = [...categoryItems];

        // Remove the item from the source position
        const [movedItem] = updatedItems.splice(sourceIndex, 1);

        // Insert the item at the destination position
        updatedItems.splice(destinationIndex, 0, movedItem);

        // Update the state
        setCategoryItems(updatedItems);
    };


    const onSubCatDragEnd = result => {
        // Your onDragEnd logic here
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const updatedItems = [...subCatItems];
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        setSubCatItems(updatedItems);
    };

    const onProductDragEnd = result => {
        // Your onDragEnd logic here
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const updatedItems = [...productItems];
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        setProductItems(updatedItems);
    };

    const onCustomMenuDragEnd = result => {
        // Your onDragEnd logic here
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const updatedItems = [...customMenuItems];
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        setCustomMenuItems(updatedItems);
    };

    return (
        <>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Sorting"
                    Breadcrumb={[{ name: "Sorting" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Sorting</h2>
                                    </div>
                                    <div className="col-md-6 row">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="col-md-12">
                                <Tabs defaultActiveKey="1" onTabClick={tabClick}>
                                    <Tabs.TabPane tab="Sort Category" key="1">

                                        <div className="card p-5">
                                            {/* <SortableList
                                                onSortEnd={onSortEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {treeData && treeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item">
                                                            <img src={assestURL + item.image} />
                                                            <p>{item.name}</p></div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList> */}
                                            {categoryItems && categoryItems.length > 0 ?
                                                <Card>
                                                    <DragDropContext onDragEnd={onCategoryDragEnd}>
                                                        <Droppable droppableId="droppable" direction="vertical">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                                >
                                                                    {categoryItems.map((item, index) => (
                                                                        <DraggableCard key={item.id} item={item} index={index} />
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </Card> : ''}
                                            <br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 1); }}>Save Sorted datas</button>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Sort Subcategory" key="2">

                                        <div className="card p-5">
                                            <div className="col-md-4">
                                                <Select
                                                    className=""
                                                    options={(categoryDatas) ? categoryDatas : []}
                                                    values={catgId}
                                                    disabled={false}
                                                    placeholder={"Select Category"}
                                                    multi={false}
                                                    dropdownHandle={true}
                                                    searchable={true}
                                                    searchBy={"name"}
                                                    labelField={"name"}
                                                    valueField={"id"}
                                                    dropdownHeight="300px"
                                                    onChange={(value) => { DropdownChange(value, "1"); }}
                                                /></div>
                                            <br />

                                            {/* <SortableList
                                                onSortEnd={onSortSubcatEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {subcatgtreeData && subcatgtreeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item">
                                                            <img src={assestURL + item.image} />
                                                            <p>{item.name}</p></div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList> */}
                                            {subCatItems && subCatItems.length > 0 ?
                                                loading ?
                                                    <Card>
                                                        <DragDropContext onDragEnd={onSubCatDragEnd}>
                                                            <Droppable droppableId="droppable" direction="vertical">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        {subCatItems.map((item, index) => (
                                                                            <DraggableCard key={item.id} item={item} index={index} />
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </Card> : <div style={{ textAlign: 'center', color: 'blue', marginTop: '50px', marginBottom: '50px' }}><LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin /></div> : <center className="mt-3 mb-3">No Data to Display...</center>}

                                            <br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 2); }}>Save Sorted datas</button>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Sort Products" key="3">

                                        <div className="card p-5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Select
                                                        className=""
                                                        options={(categoryDatas) ? categoryDatas : []}
                                                        values={catgId}
                                                        disabled={false}
                                                        placeholder={"Select Category"}
                                                        multi={false}
                                                        dropdownHandle={true}
                                                        searchable={true}
                                                        searchBy={"name"}
                                                        labelField={"name"}
                                                        valueField={"id"}
                                                        dropdownHeight="300px"
                                                        onChange={(value) => { DropdownChange(value, "1"); }}
                                                    />
                                                </div> 
                                                <div className="col-md-4">
                                                    <Select
                                                        className=""
                                                        options={(subcatgtreeData) ? subcatgtreeData : []}
                                                        values={subcatgId}
                                                        disabled={false}
                                                        placeholder={"Select Subcategory"}
                                                        multi={false}
                                                        dropdownHandle={true}
                                                        searchable={true}
                                                        searchBy={"name"}
                                                        labelField={"name"}
                                                        valueField={"id"}
                                                        dropdownHeight="300px"
                                                        onChange={(value) => { DropdownChange(value, "2"); }}
                                                    /></div>
                                            </div><br />
                                            {/* <SortableList
                                                onSortEnd={onSortProductEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {producttreeData && producttreeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item">
                                                            <img src={assestURL + item.image} />
                                                            <p>{item.name}</p></div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList> */}
                                            {productItems && productItems.length > 0 ?
                                                loading ?
                                                    <Card>
                                                        <DragDropContext onDragEnd={onProductDragEnd}>
                                                            <Droppable droppableId="droppable" direction="vertical">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        {productItems.map((item, index) => (
                                                                            <DraggableCard key={item.id} item={item} index={index} />
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </Card> : <div style={{ textAlign: 'center', color: 'blue', marginTop: '50px', marginBottom: '50px' }}><LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin /></div> : <center className="mt-3 mb-3">No Data to Display...</center>}
                                            <br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 3); }}>Save Sorted datas</button>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Sort Custom Menu" key="4">
                                        <div className="card p-5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Select
                                                        className=""
                                                        options={(categoryDatas) ? categoryDatas : []}
                                                        values={catgId}
                                                        disabled={false}
                                                        placeholder={"Select Category"}
                                                        multi={false}
                                                        dropdownHandle={true}
                                                        searchable={true}
                                                        searchBy={"name"}
                                                        labelField={"name"}
                                                        valueField={"id"}
                                                        dropdownHeight="300px"
                                                        onChange={(value) => { DropdownChange(value, "1"); }}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Select
                                                        className=""
                                                        options={(subcatgtreeData) ? subcatgtreeData : []}
                                                        values={subcatgId}
                                                        disabled={false}
                                                        placeholder={"Select Subcategory"}
                                                        multi={false}
                                                        dropdownHandle={true}
                                                        searchable={true}
                                                        searchBy={"name"}
                                                        labelField={"name"}
                                                        valueField={"id"}
                                                        dropdownHeight="300px"
                                                        onChange={(value) => { DropdownChange(value, "2"); }}
                                                    /></div>
                                                <div className="col-md-4">
                                                    <Select
                                                        className=""
                                                        options={(producttreeData) ? producttreeData : []}
                                                        values={productId}
                                                        disabled={false}
                                                        placeholder={"Select Product"}
                                                        multi={false}
                                                        dropdownHandle={true}
                                                        searchable={true}
                                                        searchBy={"name"}
                                                        labelField={"name"}
                                                        valueField={"id"}
                                                        dropdownHeight="300px"
                                                        onChange={(value) => { DropdownChange(value, "3"); }}
                                                    /></div>
                                            </div><br />
                                            {/* <SortableList
                                                onSortEnd={onSortCustomMenuEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {customMenutreeData && customMenutreeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item" style={{ height: '100px' }}>
                                                            <p>{item.name}</p>
                                                        </div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList> */}
                                            {customMenuItems && customMenuItems.length > 0 ?
                                                loading ?
                                                    <Card>
                                                        <DragDropContext onDragEnd={onCustomMenuDragEnd}>
                                                            <Droppable droppableId="droppable" direction="vertical">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        {customMenuItems.map((item, index) => (
                                                                            <DraggableCard key={item.id} item={item} index={index} />
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </Card> : <div style={{ textAlign: 'center', color: 'blue', marginTop: '50px', marginBottom: '50px' }}><LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin /></div> : <center className="mt-3 mb-3">No Data to Display...</center>}
                                            <br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 4); }}>Save Sorted datas</button>
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Sort Custom Menu Item" key="5">
                                        <div className="card p-5">
                                            <div className="col-md-4">
                                                <Select
                                                    className=""
                                                    options={(customMenuDataArray) ? customMenuDataArray : []}
                                                    values={catgId}
                                                    disabled={false}
                                                    placeholder={"Select Custom Menu"}
                                                    multi={false}
                                                    dropdownHandle={true}
                                                    searchable={true}
                                                    searchBy={"name"}
                                                    labelField={"name"}
                                                    valueField={"id"}
                                                    dropdownHeight="300px"
                                                    onChange={(value) => { CustomMenuDropdownChange(value, "1"); }}
                                                /></div>
                                            <br />
                                            {/* 
                                            <SortableList
                                                onSortEnd={onSortCustomMenuItemEnd}
                                                className="list"
                                                draggedItemClassName="dragged"
                                            >
                                                {customMenuItemTreeData && customMenuItemTreeData.map((item) => (
                                                    <SortableItem key={item.id}>
                                                        <div className="item">
                                                            <img src={assestURL + item.image} />
                                                            <p>{item.name}</p></div>
                                                    </SortableItem>
                                                ))}
                                            </SortableList> */}
                                            {items && items.length > 0 ?
                                                loading ?
                                                    <Card>
                                                        <DragDropContext onDragEnd={onDragEnd}>
                                                            <Droppable droppableId="droppable" direction="vertical">
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                                    >
                                                                        {items.map((item, index) => (
                                                                            <DraggableCard key={item.id} item={item} index={index} />

                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </Card> : <div style={{ textAlign: 'center', color: 'blue', marginTop: '50px', marginBottom: '50px' }}><LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin /></div> : <center className="mt-3 mb-3">No Data to Display...</center>}
                                            <br />
                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitsort(e, 5); }}>Save Sorted datas</button>
                                        </div>

                                    </Tabs.TabPane>
                                    {/* <Tabs.TabPane tab="Sort Itemsize" key="4">
                                        <Tree
                                            className="draggable-tree"
                                            defaultExpandedKeys={expandedKeys}
                                            draggable
                                            blockNode
                                            loadData={onLoadData}
                                            onDragEnter={onDragEnter}
                                            onDragOver={onDragOver}
                                            onDrop={onDrop}
                                            treeData={treeData}
                                        />
                                    </Tabs.TabPane> */}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}
export default VerticalSorting;