import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
    giftcardData,
    purchasedGiftCardData,
    // AddGiftCard,
    // UpdateGiftCard,
    AddWallet,
    UpdateWallet,
    DeleteGiftCard,
    giftcardSelector,
    clearLoadingDatas,
    clearGiftAddLoading,
    clearGiftUpdateLoading,
    clearGiftDeleteLoading,
    clearData,
    clearErrormsg
} from "../store/reducer/giftcard";
import {
    customerData,
    customersSelector
} from "../store/reducer/customers";
import { API_STATUS } from "../utils/constants";
import {
    giftcardFields
} from "./fields/giftcardform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL, DateFormat } from "../services/config";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

function GiftCard() {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { giftcardDatas, purchasedGiftcardDatas, receiverData, giftcardCount, loading, addloading, deleteloading, updateloading, errorMessage, purchasedGiftcardCount } = useSelector(giftcardSelector);
    const { customerDatas, customerCount } = useSelector(customersSelector);
    const resetForm = giftcardFields;
    const [formFields, setFormFields] = useState(giftcardFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filename, setfilename] = useState('');
    const [fileerror, setfileerror] = useState(false);
    const [dateerror, setdateerror] = useState(false);
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    let [sortBy, setSortBy] = useState("id");
    let [activesortBy, setActiveSortBy] = useState("id");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");
    const [customerOptions, setCustomerOptions] = useState([]);
    const [type, setType] = useState('giftCard')
    const [rowId, setRowId] = useState()
    //const formDatas = _.map()


    console.log(purchasedGiftcardDatas, 'purchasedGiftcardDatas');
    console.log(purchasedGiftcardCount, 'purchasedGiftcardCount');
    console.log(giftcardDatas, 'giftcardDatas');

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        giftcardDispatch();
    };

    const handlePurchasedTableChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        purchasedGiftCardDispatch();
    };

    const onChangeTime = (value, time, index, name) => {
        if (value == null)
            formFields[index].value = "";
        else
            formFields[index].value = value;
        setFormFields([...formFields]);
        setdateerror(false);
    };

    // const deleteProduct = (row) => {
    //     //setshowAdd(true);
    //     //setUpdateID(row.id);
    //     const deleteID = row.id;
    //     MySwal.fire({
    //         title: 'Are You Sure to Delete Product?',
    //         icon: 'warning',
    //         showCancelButton: true,
    //         focusConfirm: false,
    //         confirmButtonText: 'Ok',
    //         cancelButtonText:
    //             'Cancel',
    //     }).then((result) => {
    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
    //             console.log('triggered');
    //             dispatch(giftcardDelete({ deleteID }));
    //         }
    //     })

    // };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        giftcardDispatch();
    };

    const handlePurchasedTableChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        purchasedGiftCardDispatch();
    };

    const FieldChange = (e, index) => {
        if (formFields[index].name == "load_points") {
            const re = /^\d*\.?\d*$/
            if (!e.target.value.match(re) && e.target.value != "") {
                return false;
            }
        }

        if (formFields[index].name == "mobile") {
            const re = /^\d*\.?\d*$/
            if (!e.target.value.match(re) && e.target.value != "") {
                return false;
            }
        }



        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    // const ChangeReadOlny = () => {
    //     let get_indexf = _.findIndex(formFields, { name: "first_name" })
    //     let get_indexl = _.findIndex(formFields, { name: "last_name" })
    //     let get_indexe = _.findIndex(formFields, { name: "email_id" })
    //     let get_indexm = _.findIndex(formFields, { name: "mobile" })
    //     let get_indexb = _.findIndex(formFields, { name: "wallet_points" })

    //     formFields[get_indexf].readonly = false;
    //     formFields[get_indexl].readonly = false;
    //     formFields[get_indexe].readonly = false;
    //     formFields[get_indexm].readonly = false;
    //     formFields[get_indexf].value = "";
    //     formFields[get_indexl].value = "";
    //     formFields[get_indexe].value = "";
    //     formFields[get_indexm].value = "";

    // };

    const DropdownChange = (value, index, type) => {
        console.log(value, 'type12');
        if (value == "") {
            value = [];
        }
        if (type == "user_id") {

            let get_indexf = _.findIndex(formFields, { name: "first_name" })
            let get_indexl = _.findIndex(formFields, { name: "last_name" })
            let get_indexe = _.findIndex(formFields, { name: "email_id" })
            let get_indexm = _.findIndex(formFields, { name: "mobile" })
            let get_indexb = _.findIndex(formFields, { name: "wallet_points" })
            if (value.length > 0) {
                if (value[0].id != "") {
                    setUpdateID(value[0]?.id);
                    formFields[get_indexf].readonly = true;
                    formFields[get_indexl].readonly = true;
                    formFields[get_indexe].readonly = true;
                    formFields[get_indexm].readonly = true;
                    formFields[get_indexb].readonly = true;
                    formFields[get_indexf].value = value[0]?.first_name;
                    formFields[get_indexl].value = value[0]?.last_name;
                    formFields[get_indexe].value = value[0]?.email;
                    formFields[get_indexm].value = value[0]?.mobile;
                    formFields[get_indexb].value = parseFloat(value[0]?.wallet_points).toFixed(2);
                } else {
                    setUpdateID('');
                    // formFields[get_indexf].readonly = false;
                    // formFields[get_indexl].readonly = false;
                    // formFields[get_indexe].readonly = false;
                    // formFields[get_indexm].readonly = false;
                    // formFields[get_indexf].value = "";
                    // formFields[get_indexl].value = "";
                    // formFields[get_indexe].value = "";
                    // formFields[get_indexm].value = "";
                }
            }
        }
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
            console.log(file);
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                setfileUpload(binaryStr);
                setfilePath(file.path);
                setfileerror(false);
                //console.log(binaryStr)
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const thumbs = files.map(file => (
        <div className="dropzone_thum_img" key={file.name}>
            <div className="dropzone_thumb">
                <img
                    src={file.preview}
                    className="dropzone_img"
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: false
    })


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{e.message}</span>
            ))}
        </div>
    ));
    const submitGiftCard = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');


            //const postData = new FormData();
            // const datas = formFields.map((data) => {
            //     console.log(data,'data21');
            //     if (data?.name === "user_id") {
            //         let val = data.value;
            //         console.log(val)
            //         return arr[data?.name] = val[0]?.id;
            //         //data.value = val[0].id;
            //     }
            //     if(data?.name == "load_points"){

            //         return arr['amount'] = data?.value;

            //     }
            //     // return arr[data?.name] = data.value;
            // });
            // if (arr.date != "" && arr.date != null) {
            //     console.log(arr.date);
            //     arr.from_date = dayjs(new Date(arr.date[0])).format('YYYY-MM-DD')
            //     arr.to_date = dayjs(new Date(arr.date[1])).format('YYYY-MM-DD')
            // } else {
            //     setdateerror(true);
            //     return false;
            // }

            // const postData = arr;

            //  return false;
            //const postData = arr;
            if (updateID == "") {
                let arr = {};
                const datas = formFields.map((data) => {
                    console.log(data, 'data21');

                    if (data?.name == "load_points") {

                        return arr['amount'] = data?.value;

                    }
                    if (data?.name == "email_id") {

                        return arr['email'] = data?.value;

                    }
                    if (data?.name == "message") {

                        return arr['message'] = data?.value;

                    }

                });
                arr['code'] = 'NA';
                arr['redeem_flag'] = 0;
                const postData = arr;
                console.log(postData, 'addcoupon');
                dispatch(AddWallet({ postData }));
            } else {

                // postData.id = updateID;
                let arr = {};
                const datas = formFields.map((data) => {
                    console.log(data, 'data21');
                    if (data?.name === "user_id") {
                        let val = data.value;
                        console.log(val)
                        return arr[data?.name] = val[0]?.id;
                        //data.value = val[0].id;
                    }
                    if (data?.name == "load_points") {

                        return arr['amount'] = data?.value;

                    }
                    // return arr[data?.name] = data.value;
                });
                const postData = arr;
                console.log(postData, 'updatecoupon');
                dispatch(UpdateWallet({ postData }));
            }
            setshowAdd(false);
        } else {
            // let get_indexm = _.findIndex(formFields, { name: "date" })
            // if (formFields[get_indexm].value == "") {
            //     setdateerror(true)
            // }
            // if (fileUpload == '' && updateID == "") {
            //     setfileerror(true);
            // }
            setShowvalidator(true);
            console.log("test");
        }
    };

    const editGiftCard = (row) => {

        window.scrollTo(0, 0)
        setshowAdd(true);
        setUpdateID(row.id);
        let date_val = [];
        if (row.from_date && row.from_date != '') {
            let from_date = dayjs(row.from_date).format(DateFormat);
            let to_date = dayjs(row.to_date).format(DateFormat);
            date_val = [dayjs(from_date, DateFormat), dayjs(to_date, DateFormat)]
        }
        let data = formFields.map((data, index) => {
            //console.log(_.filter(statusDropdown, { id: row.status }));
            if (data.name === "first_name")
                data.value = row.first_name;
            if (data.name === "last_name")
                data.value = row.last_name;
            if (data.name === "email_id")
                data.value = row.email_id;
            if (data.name === "mobile")
                data.value = row.mobile;
            if (data.name === "load_points")
                data.value = row.load_points ? row.load_points : '';
            if (data.name === "wallet_points")
                data.value = row.wallet_points ? row.wallet_points : 0;
            if (data.name === "date")
                data.value = date_val;
            // if (data.name === "giftcard_id")
            //     data.value = row.giftcard_id;
            if (data.name === "user_id") {
                let set_status = _.filter(customerOptions, { id: row.user_id });
                data.value = set_status;
            }

            return data;
        });
        console.log(data, "formfields");
        setFormFields(data)
        setfileerror(false);
    };



    const deleteGiftCard = (row) => {
        setshowAdd(false);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Gift Card?',
            html: '',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(DeleteGiftCard({ deleteID }));
            }
        })

    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        setShowvalidator(false);
        // ChangeReadOlny();
        formFields.map((data, index) => {
            data.value = "";
        })
    };

    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        if (type === 'giftCard') {
            giftcardDispatch()
        }
        else {
            purchasedGiftCardDispatch()
        }
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        giftcardDispatch();
    };

    const giftcardDispatch = () => {
        dispatch(
            giftcardData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    const purchasedGiftCardDispatch = () => {
        dispatch(
            purchasedGiftCardData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    useEffect(() => {
        dispatch(clearData())
        giftcardDispatch()
        purchasedGiftCardDispatch()
        dispatch(customerData({}));
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        console.log(formFields, "formFields")
    }, [formFields])

    useEffect(() => {
        console.log(loading, "loading")
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearGiftDeleteLoading())
            purchasedGiftCardDispatch()

        }
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            dispatch(clearGiftAddLoading())
            giftcardDispatch()
        }
        if (updateloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearGiftUpdateLoading())
            resetFormFields();
            setCustomerOptions([]);
            giftcardDispatch();
            dispatch(customerData({}));


        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);

    useEffect(() => {
        console.log(customerDatas, "customerDatas1212");
        if (customerOptions && customerOptions.length == 0) {
            if (customerDatas && customerDatas.length > 0) {
                let catdata = [{ id: "", first_name: "", last_name: "", email: "", mobile: "", wallet_points: "" }];
                customerDatas.map((data) => {
                    return catdata.push({ id: data.id, name: data.first_name + " " + data.last_name, first_name: data.first_name, last_name: data.last_name, email: data.email, mobile: data.mobile, wallet_points: data.wallet })
                });
                setCustomerOptions(catdata)
            }
        }

    }, [customerDatas])

    const view = (row) => {
        if (row.id == rowId) {
            setRowId()
        } else {
            setRowId(row.id)
        }
    }

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Gift Card"
                    Breadcrumb={[{ name: "Gift Card" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <button className="btn btn-info" onClick={() => {
                                            setType('giftCard')
                                            setPage(0)
                                            setSearchQuery('')
                                        }}> Gift Card Transactions</button>
                                        <button to='/addproducts' className="btn btn-primary ml-3" onClick={() => {
                                            setType('purchasedGiftCard')
                                            setPage(0)
                                            setSearchQuery('')
                                        }}>Purchased Gift Cards</button>
                                        <div className="mt-3"><h2>{type === 'giftCard' ? 'Gift Card Transactions' : 'Purchased Gift Cards'}</h2></div>
                                    </div>
                                    <div className="col-md-6 row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-8">
                                            {(!showAdd) ?
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder={type === 'giftCard' ? 'Search by Customer name,Email' : 'Search Gift Code'} />
                                                : ''}
                                        </div>
                                        {/* <div className="col-md-4">
                                            <button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                            setshowAdd(!showAdd);
                                            console.log(resetForm, 'giftcardFields')
                                            resetFormFields()
                                        }}>
                                            {(showAdd) ? "Cancel" : "Add Gift Card"}
                                        </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className={(showAdd) ? "body" : "body hide"}>
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                            {(fields?.input == "text") ?
                                                                <input
                                                                    className={`form-control ` + fields?.class}
                                                                    value={fields?.value}
                                                                    name={fields?.type}
                                                                    required=""
                                                                    readOnly={fields?.readonly}
                                                                    onChange={(e) => { FieldChange(e, index) }}
                                                                />
                                                                : (fields?.input == "textarea") ?
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={fields?.value}
                                                                        cols="30"
                                                                        name="textarea"
                                                                        required=""
                                                                        rows="2"
                                                                        onChange={(e) => {
                                                                            FieldChange(e, index)
                                                                        }}
                                                                    ></textarea>
                                                                    : (fields?.input == "dropdown") ?
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={customerOptions}
                                                                            values={fields?.value}
                                                                            disabled={false}
                                                                            multi={false}
                                                                            dropdownHandle={true}
                                                                            searchable={true}
                                                                            searchBy={"name"}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index, fields?.name); }}
                                                                        />
                                                                        : (fields?.input == "file") ?
                                                                            <section className="row container">
                                                                                <div className="col-md-8">
                                                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                                                        <input {...getInputProps()}
                                                                                            name={fields?.name} />
                                                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                    </div></div>
                                                                                <div className="col-md-4">


                                                                                    <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                        <div className="dropzone_thumb">
                                                                                            <img
                                                                                                src={fields?.value}
                                                                                                className="dropzone_img"
                                                                                            />
                                                                                        </div>
                                                                                    </div> : '')}</div>
                                                                                </div>
                                                                                {fileRejectionItems}
                                                                                <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field is required.</div>
                                                                            </section>
                                                                            : (fields?.input == "rangepicker") ?
                                                                                <>
                                                                                    <RangePicker onChange={(time, timeString) => {

                                                                                        onChangeTime(time, timeString, index, fields?.name);
                                                                                    }}
                                                                                        format={DateFormat}
                                                                                        value={fields?.value}
                                                                                        className="form-control antformrange" />
                                                                                    <div className={(dateerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The date field is required.</div>
                                                                                </>
                                                                                : ''

                                                            }
                                                            {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitGiftCard(e); }}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card p-2">
                            {type === 'giftCard' ?
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "first_name" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("first_name");
                                                        }}
                                                    >
                                                        Customer Name
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "email_id" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("email_id");
                                                        }}
                                                    >
                                                        Email
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "giftcard_id" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("giftcard_id");
                                                    }}
                                                >
                                                    GiftCard ID
                                                </TableSortLabel>
                                            </StyledTableCell> */}
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "load_points" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("amount");
                                                        }}
                                                    >
                                                        Load Amount($)
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        // active={sortBy == "load_points" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                    // onClick={(e) => {
                                                    //     sortByKey("amount");
                                                    // }}
                                                    >
                                                        Transaction Type
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "load_points" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("load_points");
                                                    }}
                                                >
                                                    Balance Amount($)
                                                </TableSortLabel>
                                            </StyledTableCell> */}
                                                {/* <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "from_date" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("from_date");
                                                    }}
                                                >
                                                    From Date
                                                </TableSortLabel>
                                            </StyledTableCell> */}
                                                {/* <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "to_date" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("to_date");
                                                    }}
                                                >
                                                    To Date
                                                </TableSortLabel>
                                            </StyledTableCell> */}
                                                {/* <StyledTableCell align="">Action</StyledTableCell> */}

                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "created_at" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("created_at");
                                                        }}
                                                    >
                                                        Created At
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                giftcardDatas && giftcardDatas.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row?.user?.first_name + ' ' + row?.user?.last_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.user?.email}

                                                        </StyledTableCell>
                                                        {/* <StyledTableCell align="">
                                                        {row.giftcard_id}
                                                    </StyledTableCell> */}
                                                        <StyledTableCell align="">
                                                            {row.amount}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row.redeem_flag == 1 ? "Redeem Code" : 'Dip Wallet'}
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell align="">
                                                        {row?.user?.wallet}
                                                    </StyledTableCell> */}
                                                        {/* <StyledTableCell align="">
                                                        {dayjs(row.from_date).format(DateFormat)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {dayjs(row.to_date).format(DateFormat)}
                                                    </StyledTableCell> */}
                                                        {/* <StyledTableCell align="center">

                                                    </StyledTableCell> */}
                                                        {/* <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { editGiftCard(row) }} title="Edit"><i className="icon-pencil text-info p-2"></i></a>
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteGiftCard(row) }} title="Delete"><i className="icon-trash text-danger p-2" ></i></a>
                                                    </StyledTableCell> */}
                                                        <StyledTableCell align="">
                                                            {dayjs(row.created_at).format('MM-DD-YYYY hh:mm A')}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={8}
                                                    count={giftcardCount}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer> :
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        // active={sortBy == "first_name" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("first_name");
                                                        }}
                                                    >
                                                        Gift Code
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        // active={sortBy == "email_id" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("email_id");
                                                        }}
                                                    >
                                                        Amount
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="">
                                             <TableSortLabel
                                                 active={sortBy == "giftcard_id" ? true : false}
                                                 direction={order}
                                                 className="table-header-column-name"
                                                 onClick={(e) => {
                                                     sortByKey("giftcard_id");
                                                 }}
                                             >
                                                 GiftCard ID
                                             </TableSortLabel>
                                         </StyledTableCell> */}
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        // active={sortBy == "load_points" ? true : false}
                                                        active={sortBy == "first_name" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("first_name");
                                                        }}
                                                    >
                                                        Customer Name
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        // active={sortBy == "load_points" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                    // onClick={(e) => {
                                                    //     sortByKey("amount");
                                                    // }}
                                                    >
                                                        Created At
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="">
                                             <TableSortLabel
                                                 active={sortBy == "load_points" ? true : false}
                                                 direction={order}
                                                 className="table-header-column-name"
                                                 onClick={(e) => {
                                                     sortByKey("load_points");
                                                 }}
                                             >
                                                 Balance Amount($)
                                             </TableSortLabel>
                                         </StyledTableCell> */}
                                                {/* <StyledTableCell align="">
                                             <TableSortLabel
                                                 active={sortBy == "from_date" ? true : false}
                                                 direction={order}
                                                 className="table-header-column-name"
                                                 onClick={(e) => {
                                                     sortByKey("from_date");
                                                 }}
                                             >
                                                 From Date
                                             </TableSortLabel>
                                         </StyledTableCell> */}
                                                {/* <StyledTableCell align="">
                                             <TableSortLabel
                                                 active={sortBy == "to_date" ? true : false}
                                                 direction={order}
                                                 className="table-header-column-name"
                                                 onClick={(e) => {
                                                     sortByKey("to_date");
                                                 }}
                                             >
                                                 To Date
                                             </TableSortLabel>
                                         </StyledTableCell> */}
                                                {/* <StyledTableCell align="">Action</StyledTableCell> */}

                                                <StyledTableCell align="">
                                                    <TableSortLabel
                                                        active={sortBy == "created_at" ? true : false}
                                                        direction={order}
                                                        className="table-header-column-name"
                                                        onClick={(e) => {
                                                            sortByKey("created_at");
                                                        }}
                                                    >
                                                        Action
                                                    </TableSortLabel>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                purchasedGiftcardDatas && purchasedGiftcardDatas.map((row, index) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row.id === rowId ?
                                                                row?.code : '***********'}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.amount}
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell align="">
                                                     {row.giftcard_id}
                                                 </StyledTableCell> */}
                                                        <StyledTableCell align="">
                                                            {row?.user?.first_name + ' ' + row?.user?.last_name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {dayjs(row.created_at).format('MM-DD-YYYY hh:mm A')}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            <a href="javascript:void(0);" onClick={(e) => { view(row) }} title="View">{row.id === rowId ? <i className="fa fa-eye-slash text-info p-2"></i> : <i className="icon-eye text-info p-2"></i>}</a>
                                                            <a href="javascript:void(0);" onClick={(e) => { deleteGiftCard(row) }} title="Delete"><i className="icon-trash text-danger p-2"></i></a>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={8}
                                                    count={purchasedGiftcardCount}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handlePurchasedTableChangePage}
                                                    onRowsPerPageChange={handlePurchasedTableChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GiftCard;
