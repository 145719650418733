import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import PageHeader from "../view/pageheader";
import TableCell from '@mui/material/TableCell';
import {
    getdispatchList,
    kitchenDisplaySelector,
    getDisplayReport,
    updateDeliverStatus,
    cleardisptachLoadingDatas,
    clearErrormsg,
} from "../store/reducer/kitchendisplay";
import {
    notificationData,
    notificationSelector,
} from "../store/reducer/notification";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import dayjs from 'dayjs';
import { DateFormat } from "../services/config";
import { Divider, Space, Tag } from 'antd';
import {
    generalData,
    generalSelector,
} from "../store/reducer/general";
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import swipeImg from '../assets/images/swipe_left.png'
import { toast } from "react-toastify";
import { API_STATUS } from "../utils/constants";
import Swal from 'sweetalert2';
import Countdown from 'react-countdown';
import { Button, Modal } from 'antd';
import gpsImg from '../assets/images/location.png';
import moment from 'moment-timezone';
import NavBar from "../view/navbar";
import { assestURL } from "../services/config";
import { AudioTwoTone } from '@ant-design/icons';

function Kitchendispatch() {
    const dispatch = useDispatch();
    const { pickupDispatchList, driveDispatchList, orderDisplayReport, dispatchloading, delieverdOrderID, deliveryStatusloading, errorMessage } = useSelector(kitchenDisplaySelector);
    const { notificationDatas, notificationCount, loading, addloading, deleteloading, updateloading } = useSelector(notificationSelector);
    const [driveDatas, setdriveDatas] = useState([])
    const [undoTrue, setundoTrue] = useState([])
    const [timeLeft, setTimeLeft] = useState(null);
    const [displayReports, setdisplayReports] = useState('')
    let [timeLeftArr, setTimeLeftArr] = useState([]);
    const [timerSlot, settimerSlot] = useState({ order_id: '', currentTimer: '' })
    const [orderId, setorderId] = useState([])
    const [currentTime, setCurrentTime] = useState('')
    const [audioPlay, setAudioPlay] = useState(false)
    const [audioFile, setAudioFile] = useState("")
    const [audioPlayedIds, setaudioPlayedIds] = useState([])
    const { generalDatas } = useSelector(generalSelector);
    const [deliverlist, setdeliverlist] = useState([])
    const [open, setOpen] = useState(false);
    const [userInteracted, setUserInteracted] = useState(false);
    const [audioQueue, setAudioQueue] = useState([]); // Queue to store IDs for playback
    const [currentAudio, setCurrentAudio] = useState(null); // Store current audio ID being played
    const [playedIds, setPlayedIds] = useState([]); // State to track played ids
    const kitchenDispatch = localStorage.getItem('kitchenDispatch');
    const [audioModal, setAudioModal] = useState(false);

    useEffect(() => {
        if (kitchenDispatch == 'true') {
            setAudioModal(true);
            localStorage.setItem('kitchenDispatch', false);
        }
    }, [kitchenDispatch]);

    useEffect(() => {
        setAudioModal(true);
        dispatch(generalData({}))
        dispatch(
            notificationData({
                query: "",
                page: 1,
                limit: 10,
                sortby: "",
                order: "",
            })
        );
    }, [])

    useEffect(() => {
        if (notificationDatas) {
            const audioData = notificationDatas && notificationDatas.map((row) => { return row.id == 3 ? row.audio : 0 })
            setAudioFile(assestURL + audioData[0])
        }
    }, [notificationDatas])

    useEffect(() => {
        if (generalDatas) {
            let options = {
                timeZone: generalDatas[0].timezone,
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }

            // console.log(dayjs(new Date().toLocaleString("en-US")).format("MM/DD/YYYY hh:mm A"),'timenow');
            //  console.log(options,new Date().toLocaleString([], options),'timenow')
            //  console.log(dayjs(new Date().toLocaleString([])).tz(generalDatas[0].timezone),'timenow')
            setCurrentTime(moment().tz(generalDatas[0].timezone).format('MM-DD-YYYY hh:mm:ss A'))

            const interval = setInterval(() => {
                let options = {
                    timeZone: generalDatas[0].timezone,
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                }
                // setCurrentTime(dayjs(new Date().toLocaleString([], options)).format("MM-DD-YYYY h:mm:ss A"))
                setCurrentTime(moment().tz(generalDatas[0].timezone).format('MM-DD-YYYY hh:mm:ss A'))
            }, 1000);
            return () => clearInterval(interval);
            //const timezone = 
        }
    }, [generalDatas])

    useEffect(() => {

        // create a interval and get the data
        const myInterval = setInterval(() => {
            dispatch((getdispatchList({})))
            dispatch(getDisplayReport({}))
        }, 5000);
        // clear out the interval when unmounting the component
        return () => clearInterval(myInterval);

    }, []);

    useEffect(() => {
        if (driveDispatchList && driveDispatchList.length) {

            let vechicleDetails = driveDispatchList && driveDispatchList.map((item) => {
                let driveDetails = JSON.parse(item.orderMode_details)
                let vechicleData = `${driveDetails.vehicleModel}, ${driveDetails.vehicleColor}`
                return Object.assign({}, item, { orderMode_details: vechicleData });
            })
            setdriveDatas([...vechicleDetails])
        } else {
            setdriveDatas([])
        }

    }, [driveDispatchList])

    // Capture user interaction early (e.g., on initial load or when prompted)
    const handleUserInteraction = () => {
        setUserInteracted(true); // Set flag to true, allowing audio playback later
        localStorage.setItem('userInteracted', true);
        if (audioModal) {
            setAudioModal(false)
        }
    };

    useEffect(() => {
        if (userInteracted) {
            let newQueue = [...audioQueue];

            pickupDispatchList?.forEach((row) => {
                if (row.gps_track === '1' && !newQueue.includes(row.id) && !playedIds.includes(row.id)) {
                    newQueue.push(row.id); // Add to queue only if not played before
                }
            });

            driveDispatchList?.forEach((row) => {
                if (row.gps_track === '1' && !newQueue.includes(row.id) && !playedIds.includes(row.id)) {
                    newQueue.push(row.id); // Add to queue only if not played before
                }
            });

            setAudioQueue(newQueue); // Update the audio queue
        }
    }, [pickupDispatchList, driveDispatchList, userInteracted, playedIds]);

    // Play audio sequentially from the queue
    useEffect(() => {
        if (audioQueue.length > 0 && !currentAudio) {
            const nextAudioId = audioQueue[0];
            setCurrentAudio(nextAudioId);

            const audio = new Audio(`${audioFile}`);

            const handleAudioEnd = () => {
                console.log('Audio playback ended for:', nextAudioId);

                // Mark the id as played
                setPlayedIds((prevPlayedIds) => [...prevPlayedIds, nextAudioId]);

                // Remove the played audio from the queue
                setAudioQueue((prevQueue) => prevQueue.slice(1));
                setCurrentAudio(null); // Allow the next audio to start
            };

            audio.play().then(() => {
                console.log("Audio started playing successfully");
                audio.addEventListener('ended', handleAudioEnd);
            }).catch((error) => {
                console.log('Autoplay failed:', error);
            });

            return () => {
                audio.removeEventListener('ended', handleAudioEnd);
            };
        }
    }, [audioQueue, currentAudio]);


    const deliverDispatch = (orderID) => {
        let postData = orderID
        dispatch((updateDeliverStatus({ postData })))
    };

    const setDeliverStatus = (orderID) => {
        let existingIds = undoTrue
        existingIds.push(orderID)
        setundoTrue([...existingIds])
        timeLeftArr[orderID] = <Countdown date={Date.now() + 10000} renderer={renderer} onComplete={(e) => { deliverDispatch(orderID) }} />
        setTimeLeftArr([...timeLeftArr])
        // setTimeLeft(15);
        setorderId([...existingIds])
        dispatch((getdispatchList({})))
    }


    const setUndoStatus = (orderID) => {
        let removeIds = undoTrue
        let data = removeIds.filter((item) => { return item != orderID })
        setundoTrue([...data])
        // setTimeLeft(0)
        setorderId([...data])
    }

    const leadingActions = (orderId) =>
    (
        <LeadingActions>
            <SwipeAction
                onClick={() => { setUndoStatus(orderId) }}
            >
                <span style={{ backgroundColor: '#de4848', textAlign: 'center', padding: '8px' }}><p style={{ color: 'white', fontSize: '15px' }}><b>Undo</b>&nbsp;<i class="fa fa-times" aria-hidden="true"></i></p></span>
            </SwipeAction>
            {/* <SwipeAction onClick={() => { setDeliverStatus(orderId) }}>
                <span style={{ backgroundColor: '#198754', alignItems: 'center', textAlign: 'center', padding: '6px' }}><p style={{ color: 'white', fontSize: '15px' }}><i class="fa fa-check" aria-hidden="true"></i>&nbsp;
                    <b>Delivered</b></p></span>
            </SwipeAction> */}
        </LeadingActions >
    )
    // }

    const trailingActions = (orderId) => (
        <TrailingActions>
            {/* <SwipeAction
                onClick={() => { setUndoStatus(orderId) }}
            >
                <span style={{ backgroundColor: '#de4848', textAlign: 'center', padding: '8px' }}><p style={{ color: 'white', fontSize: '15px' }}><b>Undo</b>&nbsp;<i class="fa fa-times" aria-hidden="true"></i></p></span>
            </SwipeAction> */}
            <SwipeAction onClick={() => { setDeliverStatus(orderId) }}>
                {/* <div style={{ display: 'flex', alignItems: 'center', padding: '8px', boxSizing: 'border-box' }}> */}
                <span style={{ backgroundColor: '#198754', alignItems: 'center', textAlign: 'center', padding: '6px' }}><p style={{ color: 'white', fontSize: '15px' }}><i class="fa fa-check" aria-hidden="true"></i>&nbsp;
                    <b>Delivered</b></p></span>
                {/* </div> */}
                {/* <i class="fa fa-undo" style={{ color: 'white' }} aria-hidden="true"></i> */}
            </SwipeAction>
        </TrailingActions>
    );

    // const Completionist = () => <span>You are good to go!</span>;

    const renderer = ({ hours, minutes, seconds, completed }) => {
        // if (completed) {
        //     // Render a completed state
        //     return <Completionist />;
        // } else {
        // Render a countdown
        return <span>{seconds}</span>;
        // }
    };

    useEffect(() => {
        if (orderDisplayReport) {
            if (orderDisplayReport && orderDisplayReport.dailyReport[0].count) {
                setdisplayReports(orderDisplayReport.dailyReport[0].count)
            } else {
                setdisplayReports(0)
            }
            if (orderDisplayReport && orderDisplayReport.totalDeliverOrders) {
                setdeliverlist([...orderDisplayReport.totalDeliverOrders])
            } else {
                setdeliverlist([])
            }
        }
    }, [orderDisplayReport])

    useEffect(() => {
        if (deliveryStatusloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            // toast.success("Order Delivered successfully!");
            Swal.fire({
                title: 'Order Delivered!',
                html: `Order <strong>#${delieverdOrderID}</strong> Delivered Successfully!`,
                icon: 'success',
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
            }).then(function () {
                // setundoTrue([])
                // setorderId("")
                // dispatch(cleardisptachLoadingDatas())
                dispatch((getdispatchList({})))
                // window.location.reload()
            });
        }

        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }

            dispatch(clearErrormsg())
        }
    }, [deliveryStatusloading, errorMessage]);

    return (
        <div>
            <div className="container-fluid">
                <div className="row mt-3">
                    <h5>Order Dispatch</h5>
                </div>
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="row" style={{ marginBottom: '35px', marginTop: '5px' }}>
                            <div className="col-md-8">
                                <button type="button" class="btn btn-danger mr-3" style={{ textAlign: 'left', float: 'left' }}><b>TOTAL ORDERS TODAY&nbsp;:&nbsp;{displayReports ? displayReports : 0}</b></button>
                                <button type="button" class="btn btn-success mr-3" style={{ textAlign: 'left', float: 'left' }} onClick={() => setOpen(true)}><b>DELIVERED ORDERS</b></button>
                                {/* {!userInteracted && (
                                    <button type="button" class="btn btn-warning" style={{ textAlign: 'left', float: 'left' }} onClick={handleUserInteraction}>
                                        Click to enable notification audio <i class="fa fa-music"></i>
                                    </button>
                                )} */}
                            </div>
                            <div className="col-md-4">
                                <button type="button" class="btn btn-light btn-lg" style={{ textAlign: 'right', float: 'right', fontSize: '16px' }}><b>{moment(currentTime).format('MM-DD-YYYY hh:mm A')}</b></button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <div className="col-md-8">
                                        <h6>DRIVE-THRU ORDERS - <button type="button" class="btn btn-primary">Active Orders : <b>{driveDatas && driveDatas.length ? driveDatas.length : 0}</b></button></h6>
                                    </div>
                                    {/* <button type="button" class="btn btn-success">Total Active Orders</button></h6> */}
                                    <TableContainer
                                        sx={{
                                            height: 820
                                        }}
                                    >
                                        <Table
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow
                                                >
                                                    <StyledTableCell
                                                    >
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order ID
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Customer Name
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Total($)
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="">
                                                <TableSortLabel
                                                    hideSortIcon={true}
                                                >
                                                    Scheduled Pickup
                                                </TableSortLabel>
                                            </StyledTableCell> */}
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Vehicle Details
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Delivery Status
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    driveDatas && driveDatas.map((row) => (
                                                        <StyledTableRow
                                                        >
                                                            <StyledTableCell sx={{ fontSize: 30 }} align="">
                                                                <div>{(row.gps_track == '1') ? <img src={gpsImg} height={35} width={35} /> : ''}
                                                                    <b style={{ marginLeft: row.gps_track == '1' ? '5px' : '33px' }}>{row.id}</b>
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell sx={{ fontSize: 30 }} align="">
                                                                {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.order_total}
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell align="">
                                                        {(row.schedule_pickup == '1') ? <b>{row.schedulepickup_time}</b> : '-'}
                                                    </StyledTableCell> */}
                                                            < StyledTableCell align="" >
                                                                <b>{row.orderMode_details}</b><br />
                                                                {(row.schedule_pickup == '1') ? <p><i style={{ color: 'red' }} class="fa fa-clock-o"></i>&nbsp;<b style={{ color: 'red' }}>{row.schedulepickup_time}</b></p> : ''}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {(undoTrue.includes(row.id)) ?
                                                                    <SwipeableList className="border borderBtn bg-danger shadow-sm rounded-pill">
                                                                        <SwipeableListItem
                                                                            // trailingActions={trailingActions(row.id)}
                                                                            leadingActions={leadingActions(row.id)}
                                                                        >
                                                                            <>
                                                                                <span className="bg-white shadow-sm dragBtn rounded-circle text-danger"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                                                <span className="px-1 me-3 fw-bold text-white">Undo &nbsp;<i class="fa fa-clock-o"></i>&nbsp;{timeLeftArr[row.id]}s</span>
                                                                                {/* <span className="bg-white shadow-sm dragBtn rounded-circle text-danger"><i class="fa fa-arrow-left" aria-hidden="true"></i></span> */}
                                                                            </>
                                                                        </SwipeableListItem>
                                                                    </SwipeableList>
                                                                    :
                                                                    <SwipeableList className="border borderBtn bg-success shadow-sm rounded-pill">
                                                                        <SwipeableListItem
                                                                            // leadingActions={leadingActions(row.id)}
                                                                            trailingActions={trailingActions(row.id)}
                                                                        >
                                                                            <>
                                                                                {/* <span className="bg-white shadow-sm dragBtn rounded-circle text-success"><i class="fa fa-arrow-right" aria-hidden="true"></i></span> */}
                                                                                <span className="fw-bold text-white text-center px-1 m-2">Update Status</span>
                                                                                <span className="bg-white shadow-sm dragBtn rounded-circle text-success"><i class="fa fa-arrow-left" aria-hidden="true"></i></span>
                                                                            </>
                                                                        </SwipeableListItem>
                                                                    </SwipeableList>
                                                                }

                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <div className="col-md-8">
                                        <h6>FRONT WINDOW ORDERS - <button type="button" class="btn btn-primary">Active Orders : <b>{pickupDispatchList && pickupDispatchList.length ? pickupDispatchList.length : 0}</b></button></h6>
                                    </div>
                                    <TableContainer
                                        sx={{
                                            height: 820
                                        }}
                                    >
                                        <Table
                                            // sx={{
                                            //     height: "max-content"
                                            // }}
                                            stickyHeader
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order ID
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Customer Name
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Order Total($)
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Scheduled
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <TableSortLabel
                                                            hideSortIcon={true}
                                                        >
                                                            Delivery Status
                                                        </TableSortLabel>
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    pickupDispatchList && pickupDispatchList.map((row) => (
                                                        <StyledTableRow >
                                                            <StyledTableCell sx={{ fontSize: "1.5rem" }}>
                                                                <div>
                                                                    {(row.gps_track == '1') ? <>
                                                                        < img src={gpsImg} height={35} width={35} /> </> : ''}
                                                                    <b style={{ marginLeft: row.gps_track == '1' ? '5px' : '35px' }}>{row.id}</b>
                                                                </div>
                                                            </StyledTableCell>
                                                            <StyledTableCell sx={{ fontSize: "1.5rem" }}>
                                                                {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {row.order_total}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {(row.schedule_pickup == '1') ? <p><i style={{ color: 'red' }} class="fa fa-clock-o"></i>&nbsp;<b style={{ color: 'red' }}>{row.schedulepickup_time}</b></p> : '-'}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="">
                                                                {(undoTrue.includes(row.id)) ?
                                                                    <SwipeableList className="border borderBtn bg-danger shadow-sm rounded-pill">
                                                                        <SwipeableListItem
                                                                            // trailingActions={trailingActions(row.id)}
                                                                            leadingActions={leadingActions(row.id)}
                                                                        >
                                                                            <>
                                                                                <span className="bg-white shadow-sm dragBtn rounded-circle text-danger"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>
                                                                                <span className="px-1 me-3 fw-bold text-center text-white">Undo &nbsp;<i class="fa fa-clock-o"></i>&nbsp;{timeLeftArr[row.id]}s</span>
                                                                                {/* <span className="bg-white shadow-sm dragBtn rounded-circle text-danger"><i class="fa fa-arrow-left" aria-hidden="true"></i></span> */}
                                                                            </>
                                                                        </SwipeableListItem>
                                                                    </SwipeableList>
                                                                    :
                                                                    <SwipeableList className="border borderBtn bg-success shadow-sm rounded-pill">
                                                                        <SwipeableListItem
                                                                            // leadingActions={leadingActions(row.id)}
                                                                            trailingActions={trailingActions(row.id)}
                                                                        >
                                                                            <>
                                                                                {/* <span className="bg-white shadow-sm dragBtn rounded-circle text-success"><i class="fa fa-arrow-right" aria-hidden="true"></i></span> */}
                                                                                <span className="fw-bold text-white text-center px-1 ml-2">Update Status</span>
                                                                                <span className="bg-white shadow-sm dragBtn rounded-circle text-success"><i class="fa fa-arrow-left" aria-hidden="true"></i></span>
                                                                            </>
                                                                        </SwipeableListItem>
                                                                    </SwipeableList>
                                                                }

                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* <button type="button" class="btn btn-success">Total Active Orders</button></h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <Modal
                // title="DELIVERED ORDERS"
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={900}
                style={{
                    top: 20,
                }}
                footer={[
                    <Button key="submit" type="primary" onClick={() => { setOpen(false) }}>
                        Cancel
                    </Button>,
                ]}
            >
                <div className="col-md-12">
                    <div className="col-md-8">
                        <h6><b>DELIVERED ORDERS -</b> <button type="button" class="btn btn-primary">Count - <b>{deliverlist && deliverlist.length ? deliverlist.length : 0}</b></button></h6>
                    </div>
                    {/* <button type="button" class="btn btn-success">Total Active Orders</button></h6> */}
                    <TableContainer
                        sx={{
                            height: 450
                        }}
                    >
                        <Table
                            // sx={{
                            //     height: "max-content"
                            // }}
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow
                                >
                                    <StyledTableCell align="">
                                        <TableSortLabel
                                            hideSortIcon={true}
                                        >
                                            Order ID
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                        <TableSortLabel
                                            hideSortIcon={true}
                                        >
                                            Customer Name
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                        <TableSortLabel
                                            hideSortIcon={true}
                                        >
                                            Order Total ($)
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="">
                                        <TableSortLabel
                                            hideSortIcon={true}
                                        >
                                            Order Mode
                                        </TableSortLabel>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    deliverlist && deliverlist.map((row) => (
                                        <StyledTableRow>
                                            <StyledTableCell align="">
                                                <b>{row.id}</b>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {(row.customer_id != null) ? row?.first_name + " " + row?.last_name : ""}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {row.order_total}
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                {(row.schedule_pickup == '1') ? <p><i class="fa fa-clock-o mr-1"></i><b>{row.schedulepickup_time}</b><br /></p> : ''}{(row.order_mode == 1) ? 'Drive-Thru' : 'Pickup Order'}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Modal>
            <Modal
                centered
                title={<p>! Order Notification Permission</p>}
                open={audioModal}
                onOk={() => setAudioModal(false)}
                onCancel={() => setAudioModal(false)}
                style={{
                    top: 20
                }}
                footer={[
                    <Button key="submit" type="primary" onClick={handleUserInteraction}>
                        OK
                    </Button>,
                    <Button onClick={() => { setAudioModal(false) }} danger>
                        Cancel
                    </Button>
                ]}
            >
                <div className="col-md-12" style={{ height: 45 }}>
                    <p>
                        <AudioTwoTone />
                        &nbsp;&nbsp;
                        CLICK <b>'OK'</b> TO ENABLE AUDIO NOTIFICATION FOR ORDERS
                    </p>
                </div>
            </Modal>
        </div >
    )
}

export default Kitchendispatch