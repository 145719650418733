import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import dayjs from "dayjs";
import {
    customerData,
    UpdateCustomer,
    DeleteCustomer,
    customersSelector,
    clearData,
    clearCustUpdateLoading,
    clearCustLoadingDatas,
    clearCustDeleteLoading,
    clearCustBulDeleteLoading,
    clearErrormsg,
    DeleteBulkCustomer
} from "../../store/reducer/customers";
import {
    customerFields
} from "../fields/customerform";
import Select from "react-dropdown-select";

import { API_STATUS } from "../../utils/constants";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function Customers() {
    console.log("insied loop")
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { customerDatas, customerCount, loading, addloading, deleteloading, updateloading, errorMessage, deleteBulkLoading } = useSelector(customersSelector);

    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    const [formFields, setFormFields] = useState(customerFields);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    const [showAdd, setshowAdd] = useState(false);
    const [updateID, setUpdateID] = useState('');
    const [files, setFiles] = useState([]);
    const [showvalidator, setShowvalidator] = useState(false);
    const [rowId, setRowId] = useState()
    const [getAllCheck, setgetAllCheck] = useState(false);
    const [getrowCheck, setgetrowCheck] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    let [sortBy, setSortBy] = useState("first_name");
    let [activesortBy, setActiveSortBy] = useState("first_name");
    let [order, setOrder] = useState("asc");
    let [searchQuery, setSearchQuery] = useState("");

    console.log(showAdd, 'showAdd');

    //const formDatas = _.map()

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        customerDispatch();
        setSelectedRow([]);
        setgetAllCheck(false);
        setgetrowCheck([]);
    };

    //Select All
    const checkAllIds = (id) => {
        if (id == "all") {
            setgetAllCheck(!getAllCheck);
            let sdata = [];
            if (customerDatas) {
                customerDatas.map((row) => {
                    getrowCheck[row.id] = !getAllCheck;
                    sdata.push(row.id);
                });
            }
            if (!getAllCheck) {
                setSelectedRow(sdata);
            } else {
                setSelectedRow([]);
            }
        } else {
            getrowCheck[id] = !getrowCheck[id]
            console.log(getrowCheck, "getrowCheck");
            let sdata = [];
            let data = getrowCheck.filter(function (element, key) {
                if (element !== undefined && element !== false) {
                    sdata.push(key);
                    return key;
                };
            });
            setSelectedRow(sdata);
            console.log(sdata, data, data.length)
        }
    };

    console.log(selectedRow, 'selectedRow');

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        customerDispatch();
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, formFields[index]);
        formFields[index].value = e.target.value;
        console.log(formFields);
        setFormFields([...formFields]);
    };

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        formFields.map((data, index) => {
            if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
        })
    };

    const DropdownChange = (value, index) => {
        console.log(value)
        formFields[index].value = value;
        setFormFields([...formFields]);
    };


    const view = (row) => {
        if (row.id == rowId) {
            setRowId()
        } else {
            setRowId(row.id)
        }
    }

    const editCustomer = (row) => {
        window.scrollTo(0, 0)
        setshowAdd(true);
        setUpdateID(row.id);
        let chosen_data = []
        let data = formFields.map((data, index) => {
            if (data.name === "first_name")
                data.value = row.first_name;
            if (data.name === "last_name")
                data.value = row.last_name;
            if (data.name === "mobile")
                data.value = row.mobile;
            if (data.name === "email")
                data.value = row.email;
            if (data.name === "status") {
                let set_status = _.filter(statusDropdown, { id: row.status });
                console.log(set_status[0]);
                data.value = set_status;
            }
            if (data.name === "password") {
                data.value = "";
            }
            return data;
        });
        console.log(data, "editDatas data");
        setFormFields(data);
    };

    const submitCategory = (e) => {
        if (simpleValidator.current.allValid()) {
            //alert('You submitted the form and stuff!');
            let arr = {};
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                if (data?.name === "status") {
                    let val = data.value;
                    console.log(val)
                    //data.value = val[0].id;
                    return arr[data?.name] = val[0].id;
                } else {
                    return arr[data?.name] = data.value;
                }
            });

            const postData = arr;
            console.log(postData, "postData");
            postData.id = updateID;
            postData.role_id = 2;
            //return false;
            //const postData = arr;
            dispatch(UpdateCustomer({ postData, updateID }));

        } else {
            setShowvalidator(true);
            console.log("test");
        }
    };

    const deleteCustomer = (row) => {
        //setshowAdd(true);
        //setUpdateID(row.id);
        const deleteID = row.id;
        MySwal.fire({
            title: 'Are You Sure to Delete Customer?',
            icon: 'warning',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Ok',
            cancelButtonText:
                'Cancel',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                dispatch(DeleteCustomer({ deleteID }));
            }
        })

    };


    const searchData = (e) => {
        searchQuery = e.target.value;
        setSearchQuery(e.target.value)
        setPage(0);
        customerDispatch()
    }

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        customerDispatch();
    };

    const customerDispatch = () => {
        dispatch(
            customerData({
                query: searchQuery,
                page: page + 1,
                limit: rowsPerPage,
                sortby: sortBy,
                order: order,
            })
        );
    };

    useEffect(() => {
        dispatch(clearData())
        customerDispatch();
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }
            setshowAdd(true);
            dispatch(clearErrormsg())

        }
        if (deleteloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearCustDeleteLoading())
            customerDispatch();
        }
        if (deleteBulkLoading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(clearCustBulDeleteLoading())
            customerDispatch();
        }
        if (updateloading === API_STATUS.FULFILLED) {
            setshowAdd(false);
            toast.dismiss();
            toast.success("Updated Successfully!");
            dispatch(clearCustUpdateLoading());
            resetFormFields();
            customerDispatch();
        }
    }, [errorMessage, deleteloading, updateloading, deleteBulkLoading]);

    const BulkAction = (type) => {
        let postData = { id: selectedRow };
        if (selectedRow.length > 0) {
            if (type == "delete") {
                MySwal.fire({
                    title: 'Are You Sure to Delete Customers?',
                    icon: 'warning',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText:
                        'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(DeleteBulkCustomer({ postData }));
                    }
                });
            }
        }
    }

    console.log(customerDatas, 'customerDatas');

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Customers"
                    Breadcrumb={[{ name: "Customers" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                {/* <div className="row">
                                    <div className="col-md-6">
                                        <h2>Customers</h2>
                                    </div>
                                    <div className="col-md-1">
                                        <button className="btn btn-danger ml-4"
                                            onClick={(e) => { BulkAction("delete") }}
                                        > Delete </button>
                                    </div>

                                    {(showAdd) ?
                                        <div className="col-md-5">
                                            <button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                                setshowAdd(!showAdd);
                                                resetFormFields()
                                            }}>
                                                Cancel
                                            </button>
                                        </div>
                                        : <div className="col-md-5">
                                            <div className="col-md-8 float-right">
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search Customer" />
                                            </div></div>
                                    }
                                </div> */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <h2>Customers</h2>
                                    </div>
                                    <div className="col-md-6 row">
                                        {(showAdd) ? <div className="col-md-8"></div> :
                                            <div className="col-md-4" style={{ marginLeft: showAdd === true ? "40%" : "0%" }}>
                                                <button className="btn btn-danger float-right" style={{ width: '80%' }}
                                                    onClick={(e) => { BulkAction("delete") }}
                                                > Delete </button>
                                            </div>
                                        }
                                        {(showAdd) ?
                                            <div className={showAdd === false ? "col-md-5" : "col-md-3"}>
                                                <button type="button" className="btn btn-primary mr-1  float-right" onClick={(e) => {
                                                    setshowAdd(!showAdd);
                                                    resetFormFields()
                                                }}>
                                                    Cancel
                                                </button>
                                            </div>
                                            :
                                            <div className="col-md-8 float-right">
                                                <input type="text" name="search" className="form-control" value={searchQuery}
                                                    onChange={(e) => { searchData(e) }} placeholder="Search by Customer Name,Email" />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={(showAdd) ? "body" : "body hide"}>
                                    <form className="ng-untouched ng-dirty ng-invalid">
                                        <div className="row p-10">
                                            {
                                                formFields && formFields.map((fields, index) => {
                                                    return (
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>
                                                                {(fields?.input == "text") ?
                                                                    <input
                                                                        className={`form-control ` + fields?.class}
                                                                        value={fields?.value}
                                                                        name={fields?.type}
                                                                        required=""
                                                                        onChange={(e) => { FieldChange(e, index) }}
                                                                    />
                                                                    : (fields?.input == "textarea") ?
                                                                        <textarea
                                                                            className="form-control"
                                                                            value={fields?.value}
                                                                            cols="30"
                                                                            name="textarea"
                                                                            required=""
                                                                            rows="2"
                                                                            onChange={(e) => {
                                                                                FieldChange(e, index)
                                                                            }}
                                                                        ></textarea>
                                                                        : (fields?.input == "dropdown") ?
                                                                            <>
                                                                                <Select
                                                                                    className="js-states"
                                                                                    placeholder=""
                                                                                    options={(fields?.options == "status") ? statusDropdown : []}
                                                                                    values={(fields.value) ? fields?.value : []}
                                                                                    disabled={false}
                                                                                    multi={(fields.multiple) ? fields.multiple : false}
                                                                                    dropdownHandle={true}
                                                                                    searchable={false}
                                                                                    labelField={"name"}
                                                                                    valueField={"id"}
                                                                                    onChange={(value) => { DropdownChange(value, index, fields?.options); }}
                                                                                />
                                                                            </>
                                                                            : ''

                                                                }
                                                                {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }

                                            <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitCategory(e); }}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <input type="checkbox"
                                                    checked={(getAllCheck) ? "checked" : ""}
                                                    required=""
                                                    onChange={(e) => { checkAllIds('all') }} /><span></span>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "first_name" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("first_name");
                                                    }}
                                                >
                                                    Name
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "mobile" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("mobile");
                                                    }}
                                                >
                                                    Mobile
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "email" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("email");
                                                    }}
                                                >
                                                    Email
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "status" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("status");
                                                    }}
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    active={sortBy == "created_at" ? true : false}
                                                    direction={order}
                                                    className="table-header-column-name"
                                                    onClick={(e) => {
                                                        sortByKey("created_at");
                                                    }}
                                                >
                                                    Created At
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            customerDatas && customerDatas.map((row) => (

                                                <StyledTableRow>
                                                    <StyledTableCell align="=">
                                                        <input type="checkbox"
                                                            checked={(getrowCheck[row.id]) ? "checked" : ""}
                                                            required=""
                                                            onChange={(e) => { checkAllIds(row.id) }} /><span></span>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row?.first_name + " " + row?.last_name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.id === rowId ?
                                                            row.mobile : '***********'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.id === rowId ?
                                                            row.email : '***********'
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.status === 1
                                                            ? "Active"
                                                            : "In-active"}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {dayjs(row.created_at).format('MM-DD-YYYY hh:mm A')}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        <a href="javascript:void(0);" onClick={(e) => { view(row) }} title="View">{row.id === rowId ? <i className="fa fa-eye-slash text-info p-2"></i> : <i className="icon-eye text-info p-2"></i>}</a>
                                                        <a href="javascript:void(0);" onClick={(e) => { editCustomer(row) }} title="Edit"><i className="icon-pencil text-info p-2"></i></a>
                                                        <a href="javascript:void(0);" onClick={(e) => { deleteCustomer(row) }} title="Delete"><i className="icon-trash text-danger p-2"></i></a>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50]}
                                                colSpan={8}
                                                count={customerCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Customers;
