import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/horizon-full.svg";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import TablePaginationActions from "../utils/pagination";
import PageHeader from "../view/pageheader";
import {
    dashboardData,
    dashboardrecentProducts,
    dashboardSelector,
    dashboardPieCharts,
    dashboardmonthlyReports,
    dashboardsalesReports
} from "../store/reducer/dashboard";
import { Link } from "react-router-dom";
import { DateFormat } from "../services/config";
import { DualAxes, Bar, Pie, Line, Column, Funnel } from '@ant-design/plots';
import { API_STATUS } from "../utils/constants";
import { assestURL } from "../services/config";
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import numberWithCommas from "../utils/numberWithCommas";
import {
    orderData,
    orderSelector,
    clearLoadingDatas,
    clearData,
    clearErrormsg
} from "../store/reducer/order";
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import locale from 'antd/locale/zh_CN';
import { ConfigProvider, DatePicker, Space } from 'antd';
import { Button } from "@mui/material";

function Dashboard() {
    let currentDate = new Date().getFullYear().toString()
    const { dashboardDatas, errorMessage, dashboardRecentDatas, salesReportDatas, pieChartDatas, monthlyReportDatas } = useSelector(dashboardSelector);
    const { loading, orderDatas, orderCount } = useSelector(orderSelector);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(5);
    let [sortBy, setSortBy] = useState("id");
    let [activesortBy, setActiveSortBy] = useState("id");
    let [order, setOrder] = useState("DESC");
    let [searchQuery, setSearchQuery] = useState("");
    const [monthlyReport, setmonthlyReport] = useState([]);
    const [date, setdate] = useState(currentDate)
    const [totalrevenue, settotalrevenue] = useState('');

    console.log(salesReportDatas, 'salesReportDatas');

    const onChange = (date, dateString) => {
        setdate(dateString)
    };

    const renderMonthlyReport = () => {
        dispatch(dashboardmonthlyReports({ date }))
    }

    useEffect(() => {
        renderMonthlyReport()
    }, [date])

    useEffect(() => {
        if (dashboardDatas) {
            let num = dashboardDatas?.revenue_count[0].total_amount;
            let revenue = numberWithCommas(num);
            settotalrevenue(revenue);

        }

    }, [dashboardDatas])

    // const data = [
    //     {
    //         month: 'January',
    //         sales: 38,
    //     },
    //     {
    //         month: 'February',
    //         sales: 52,
    //     },
    //     {
    //         month: 'March',
    //         sales: 61,
    //     },
    //     {
    //         month: 'April',
    //         sales: 145,
    //     },
    //     {
    //         month: 'May',
    //         sales: 48,
    //     },
    //     {
    //         month: 'June',
    //         sales: 0,
    //     },
    //     {
    //         month: 'July',
    //         sales: 38,
    //     },
    //     {
    //         month: 'August',
    //         sales: 38,
    //     },
    //     {
    //         month: 'September',
    //         sales: 0,
    //     },
    //     {
    //         month: 'October',
    //         sales: 0,
    //     },
    //     {
    //         month: 'November',
    //         sales: 0,
    //     },
    //     {
    //         month: 'December',
    //         sales: 0,
    //     },
    // ];

    const piedata = [
        {
            type: 'Inventory',
            value: pieChartDatas?.inventory_count,
        },
        {
            type: 'Gift Card',
            value: pieChartDatas?.gift_count,
        },
        // {
        //     type: 'Today Initiated Order',
        //     value: pieChartDatas?.initiated_order,
        // },
        {
            type: 'Today Confirmed Order',
            value: pieChartDatas?.confirmed_order,
        },
    ];

    const pieconfig = {
        appendPadding: 10,
        data: piedata,
        angleField: 'value',
        colorField: 'type',
        color: ['#0C78CD', '#FFD700	', '#00FF7F'],
        legend: {
            layout: 'vertical',
            position: 'top-right'
        },
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            position: "top",
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },
        width: 300
    };

    const pieSalesdata = [
        {
            type: 'Front Window Kiosk',
            Sales: salesReportDatas && salesReportDatas?.front_window[0].total_amount ? Number(salesReportDatas?.front_window[0].total_amount) : 0,
        },
        {
            type: 'Drive-Thru Kiosk',
            Sales: salesReportDatas && salesReportDatas?.drive_thru[0].total_amount ? Number(salesReportDatas?.drive_thru[0].total_amount) : 0,
        },
        {
            type: 'Mobile',
            Sales: salesReportDatas && salesReportDatas?.mobile_app[0].total_amount ? Number(salesReportDatas?.mobile_app[0].total_amount) : 0,
        },
        {
            type: 'Website',
            Sales: salesReportDatas && salesReportDatas?.website[0].total_amount ? Number(salesReportDatas?.website[0].total_amount) : 0,
        },
    ];

    const barconfig = {
        appendPadding: 10,
        data: pieSalesdata,
        xField: 'Sales',
        yField: 'type',
        seriesField: 'type', // or seriesField in some cases
        state: {
            unselected: { opacity: 0.5 },
            selected: { lineWidth: 3, stroke: 'red' },
        },
        interactions: [
            {
                type: 'element-single-selected',
            },
        ],
        label: {
            position: 'top',
            style: {
                fill: '#333333',
                fontSize: '14px'
            },
        },
        onReady: (chart) => {
            chart.render();  // Force render to ensure chart is fully rendered before interaction

            chart.on('afterrender', () => {
                // Retry finding geometries after chart is rendered
                const geometries = chart.geometries;
                if (geometries && geometries.length > 0) {
                    const elements = geometries[0].elements;
                    if (elements && elements.length > 0) {
                        // Select the first element
                        chart.setState('selected', (element) => element === elements[0]);
                    } else {
                        console.error('No elements found in the geometry');
                    }
                } else {
                    console.error('No geometries found in the chart');
                }
            });
        },
    };

    console.log(pieSalesdata, "pieSalesdata=");

    const getPrice = (row) => {
        console.log(row.price, row, 'filter---2');
        let filter = _.filter(row.price, { itemsize_id: parseInt(row.default_size) })
        console.log(filter, 'filter');
        return "$ " + parseFloat((filter && filter.length) ? filter[0].price : 0).toFixed(2);
    };

    const config = {
        data: monthlyReport,
        // data: data,
        width: 400,
        xField: 'month',
        yField: 'sales',
        colorField: 'type',
        label: {
            position: 'middle',
            // 'top', 'bottom', 'middle',
            style: {
                fill: '#333333',
                fontSize: '14px'
            },
            // content: function content(_ref) {
            //     console.log(_ref, '_ref=');
            //     return '$ '.concat(_ref.sales);
            // },
        },
        // tooltip: {
        //     customContent: (title, data) => {
        //         return `<div>$ ${title}</div>`;
        //     }
        // },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    const sortByKey = (key) => {
        sortBy = key;
        if (activesortBy == key) {
            if (order == "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
            setOrder(order);
        } else {
            order = "desc";
            activesortBy = key;
            setActiveSortBy(key);
            setOrder(order);
        }
        setSortBy(key);
        LatestorderDispatch();
    };

    const LatestorderDispatch = () => {
        dispatch(
            orderData({
                query: searchQuery,
                page: 1,
                limit: 10,
                sortby: sortBy,
                order: order,
            })
        );
    };

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
        LatestorderDispatch();
    };

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        LatestorderDispatch();
    };

    useEffect(() => {
        if (monthlyReportDatas && monthlyReportDatas.length) {
            setmonthlyReport([...monthlyReportDatas])
        }
    }, [monthlyReportDatas])


    useEffect(() => {
        LatestorderDispatch()
        dispatch(dashboardData({}))
        dispatch(dashboardsalesReports({}))
        dispatch(dashboardrecentProducts({}))
        dispatch(dashboardPieCharts({}))
    }, [])

    useEffect(() => {
        console.log(dashboardRecentDatas, "dashboardRecentDatas")
    }, [dashboardRecentDatas]);

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Dashboard"
                    Breadcrumb={[{ name: "Dashboard" }]}
                />
                <div className="row clearfix">
                    <div className="col">
                        <div className="card overflowhidden number-chart">
                            <div className="body" style={{ background: "linear-gradient(to right,  rgb(235, 51, 73), rgb(244, 92, 67))" }}>
                                <div className="number">
                                    <h6 style={{ color: 'white' }}>TOTAL PRODUCTS</h6>
                                    <span style={{ color: 'white', fontSize: '16px' }}><i className=" icon-basket-loaded"></i> {dashboardDatas?.product_count}</span>
                                </div>
                                <small className="text-muted"></small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card overflowhidden number-chart">
                            <div className="body" style={{ background: "linear-gradient(to right, rgb(40, 48, 72), rgb(133, 147, 152))" }}>
                                <div className="number" >
                                    <h6 style={{ color: 'white' }}>CUSTOMERS</h6>
                                    <span style={{ color: 'white', fontSize: '16px' }}><i className="icon-users"></i> {dashboardDatas?.user_count}</span>
                                </div>
                                <small className="text-muted"></small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card overflowhidden number-chart">
                            <div className="body" style={{ background: "linear-gradient(to right,rgb(230, 92, 0), rgb(249, 212, 35))" }}>

                                <div className="number">
                                    <h6 style={{ color: 'white' }}>TOTAL SALES</h6>
                                    <span style={{ color: 'white', fontSize: '16px' }}><i className="icon-layers"></i> {dashboardDatas?.order_count}</span>
                                </div>
                                <small className="tegxt-muted"></small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card overflowhidden number-chart">
                            <div className="body" style={{ background: "linear-gradient(to right,rgb(218, 68, 83), rgb(137, 33, 107))" }}>

                                <div className="number">
                                    <h6 style={{ color: 'white' }}>TOTAL REVENUE</h6>
                                    <span className="right" style={{ color: 'white', fontSize: '16px' }}><i className="icon-diamond"></i> $  {totalrevenue ? totalrevenue : 0}</span>
                                </div>
                                <small className="text-muted"></small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card overflowhidden number-chart">
                            <div className="body" style={{ background: "linear-gradient(to right, rgb(0, 180, 219), rgb(0, 131, 176))" }}>

                                <div className="number">
                                    <h6 style={{ color: 'white' }}>TOTAL REWARDS</h6>
                                    <span className="right" style={{ color: 'white', fontSize: '16px' }}><i className="icon-badge"></i> {dashboardDatas?.reward_count[0].total_rewards ? dashboardDatas?.reward_count[0].total_rewards : 0} pts</span>
                                </div>
                                <small className="text-muted"></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-7 col-md-6 col-sm-6">
                        <div className="card">
                            <div class="header">
                                <h6>Monthly Recap Report</h6>
                                <ConfigProvider locale={date}>
                                    <DatePicker onChange={onChange} picker="year" defaultValue={null} value={date ? dayjs(date, 'YYYY') : null} />
                                </ConfigProvider>
                            </div>
                            <div class="body">
                                <Column {...config} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-6">
                        <div className="card">
                            <div class="header">
                                <h6></h6>
                            </div>
                            <div class="body">
                                <Pie {...pieconfig} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-lg-7 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="header">
                                <h2>Latest Orders</h2>

                            </div>
                            <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 600 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    hideSortIcon={true}
                                                // active={sortBy == "id" ? true : false}
                                                // direction={order}
                                                // className="table-header-column-name"
                                                // onClick={(e) => {
                                                //     sortByKey("id");
                                                // }}
                                                >
                                                    Order ID
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    hideSortIcon={true}
                                                // active={sortBy == "order_total" ? true : false}
                                                // direction={order}
                                                // className="table-header-column-name"
                                                // onClick={(e) => {
                                                //     sortByKey("order_total");
                                                // }}
                                                >
                                                    Total Payment (in $)
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    hideSortIcon={true}
                                                // active={sortBy == "created_at" ? true : false}
                                                // direction={order}
                                                // className="table-header-column-name"
                                                // onClick={(e) => {
                                                //     sortByKey("created_at");
                                                // }}
                                                >
                                                    Sale Date
                                                </TableSortLabel>
                                            </StyledTableCell>
                                            <StyledTableCell align="">
                                                <TableSortLabel
                                                    hideSortIcon={true}
                                                // active={sortBy == "order_status" ? true : false}
                                                // direction={order}
                                                // className="table-header-column-name"
                                                // onClick={(e) => {
                                                //     sortByKey("order_status");
                                                // }}
                                                >
                                                    Order Status
                                                </TableSortLabel>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            orderDatas && orderDatas.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell align="">
                                                        <Link to={'/orderdetails/' + row.id} >{row.id}</Link>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.order_total}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.created_at ? dayjs(row.created_at).format(DateFormat) : ''}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="">
                                                        {row.order_status == 0 ? "Pending" : "Confirmed"}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        {/* <TableRow> */}
                                        {/* <TablePagination
                                                rowsPerPageOptions={[5, 10]}
                                                // colSpan={8}
                                                // count={[]}
                                                // rowsPerPage={rowsPerPage}
                                                // page={5}
                                                showLastButton={false}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rows per page",
                                                    },
                                                    native: true,
                                                }}
                                                // onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                // ActionsComponent={TablePaginationActions}
                                            /> */}


                                        {/* </TableRow> */}
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            <div className="header">
                                <button type="button" className="btn btn-primary mb-3  pull-right" style={{ paddingLeft: '20px' }} onClick={(e) => {
                                    window.location.href = '/orders'
                                }}>
                                    View All Orders
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="header">
                                <h2>Total Sales Dashboard</h2>
                            </div>
                            <div class="body">
                                <Bar {...barconfig} />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default Dashboard;
