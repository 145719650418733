import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
// import { Button, Modal } from "react-bootstrap";
import ModalPopup from "../../utils/modals";
import { Tree, Tabs, Tooltip, Divider, Card, Tag, Radio, Alert } from 'antd';
import {
    categoryData,
    categorySelector,
} from "../../store/reducer/category";
import {
    subcategoryFilterData,
    subCategorySelector,
    clearSubCatgDatas
} from "../../store/reducer/subcategory";
import {
    itemsizeData,
    itemsizeSelector,
} from "../../store/reducer/itemsize";
import {
    productData,
    AddProduct,
    AddMerchImages,
    UpdateProduct,
    DeleteProduct,
    productsSelector,
    clearProdAddLoading,
    clearErrormsg
} from "../../store/reducer/products";
import {
    custommenuData,
    custommenuDatabyID,
    custommenuSelector,
} from "../../store/reducer/custommenu";

import { API_STATUS } from "../../utils/constants";
import {
    productFields
} from "../fields/productform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { assestURL } from "../../services/config";
import { Button, Modal } from "react-bootstrap";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
// import initialData from "./data";
import Column from "./Column";

function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d')
    const TO_RADIANS = Math.PI / 180
    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}

function AddProducts() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { productDatas, productCount, loading, addloading, deleteloading, updateloading, errorMessage, lastProductId } = useSelector(productsSelector);
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const { custommenuDatas, custommenuDatasbyID } = useSelector(custommenuSelector);
    const { itemsizeDatas, itemsizeCount } = useSelector(itemsizeSelector);
    const { subcategoryDatas, subcategoryCount } = useSelector(subCategorySelector);
    const resetForm = productFields;
    const [formFields, setFormFields] = useState(productFields);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]

    const [page, setPage] = useState(0);
    const [subId, setSubId] = useState(0)
    const [custom_menu, setCustom_menu] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [itemSizeOptions, setItemSizeOptions] = useState([]);
    const [customMenuOptions, setCustomMenuOptions] = useState([]);
    const [defaultSizeOptions, setDefaultSizeOptions] = useState([]);
    const [chosenItems, setChosenItems] = useState([]);
    const [fileerror, setfileerror] = useState(false);
    const [filesizeerror, setfilesizeerror] = useState(false);
    const [showCustommenuCount, setshowCustommenuCount] = useState(false);
    const [customenuCountDatas, setcustomenuCountDatas] = useState([]);
    const [showCustommenuItem, setshowCustommenuItem] = useState(false);
    const [selectedDatas, setselectedDatas] = useState([]);
    const [rowcheckbox, setrowcheckbox] = useState([]);
    const [defaultSelect, setDefaultSelect] = useState([]);
    const [customerror, setcustomerror] = useState(false);
    const [priceerror, setpriceerror] = useState(false);
    const [selectallitem, setselectallitem] = useState([]);
    const [minmaxerror, setminmaxerror] = useState(false);
    const [formerrormsg, setFormErrorMsg] = useState("");
    const [rewardhide, setRewardhide] = useState(false);
    const [custommenuserror, setcustommenuserror] = useState(false);
    const [customenuCountWeight, setcustomenuCountWeight] = useState([]);
    let [thumbsimage, setThumbsImage] = useState([]);
    const [completedCrop, setCompletedCrop] = useState()
    let [imgSRC, setImgSRC] = useState([]);
    let [imgSRCwidth, setImgSRCwidth] = useState("");
    let [imgSRCheight, setImgSRCheight] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const previewCanvasRef = useRef(null);
    const [filename, setfilename] = useState('');
    const imgRef = useRef(null);
    let [scale, setScale] = useState(1);
    const [defaultItemsize, setDefaultItemsize] = useState(0);
    const [customItemSize, setcustomItemSize] = useState([]);
    const [is_weight, setIs_Weight] = useState('1')
    const [columnOrder, setColumnOrder] = useState([]);
    const [initialColumns, setInitialColumns] = useState([
        { position: 1, id: 1, name: 'Quantity', value: 1, custommenu_id: null },
        { position: 2, id: 2, name: 'Item Size', value: 2, custommenu_id: null },
        { position: 3, id: 3, name: 'Item Code', value: 3, custommenu_id: null },
    ])
    const [dynamicTasks, setDynamicTasks] = useState()
    const [uploadfiles, setUploadFiles] = useState([]);
    const [categoryId, setCategoryId] = useState()
    const [newProductId, setNewProductId] = useState()
    const [hybridProduct, setHybridProduct] = useState(false);
    const [sortSizes, setSortSizes] = useState(false)
    const [weightValue, setWeightValue] = useState(0)
    const [customWeightTotal, setcustomWeightTotal] = useState(0);
    // const [columns, setColumns] = useState(initialColumns);
    const [sampleCodeOutput, setsampleCodeOutput] = useState([])
    // const [columns, setColumns] = useState(initialColumns);
    const [dynamicColumns, setDynamicColumns] = useState([]);
    const [merchImageArray, setMerchImageArray] = useState([])
    const [value, setValue] = useState();



    const maxProductWidth = 500;
    const maxProductHeight = 600;

    //const formDatas = _.map()

    const Container = styled("div")`
    border: 1px solid lightgrey;
    margin-bottom: 8px;
    border-radius: 2px;
    padding: 8px;
  background: ${props => (props.isDragging ? "lightgreen" : "white")};
  display: flex;
  background-color: ${props => (props.isDraggingOver ? "#49c5b6" : "inherit")};
`;


    const buildData = (datas) => {
        return _.orderBy(datas, ['order_no'], ['asc']);
    };

    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        locked: true,
        width: maxProductWidth,
        height: maxProductHeight
    })

    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = {
            unit: 'px', // Can be 'px' or '%'
            x: 0,
            y: 0,
            locked: true,
            width: maxProductWidth,
            height: maxProductHeight
        }

        setCrop(crop)
        setCompletedCrop(crop)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onCloseCustommenuCount = () => {
        setshowCustommenuCount(false);
    }

    const onCloseCustommenuItem = () => {
        setshowCustommenuItem(false);
        // setselectedDatas([]);
        // setrowcheckbox([]);
    }

    const onSaveCustommenuItem = () => {
        setshowCustommenuItem(false);
    }

    const weightError = () => {
        toast.error('Maximum Weight limit exceeded for this Product!.');
    };

    const onSaveCustommenuCount = () => {
        let valid_minmax = false;
        let couterror = false;
        let columnname = '';
        // customenuCountDatas.map((item) => {
        //     console.log("final datas", customenuCountDatas)
        //     console.log("min-max details", parseInt(item.min), item.min, item.max, "3")
        //     if ((item.min == '' && item.min != '0') || (item.max == '' && item.max != '0')) {
        //         console.log(item.min, item.max, "1")
        //         valid_minmax = true;
        //     } else if ((parseInt(item.min) >= parseInt(item.max))) {
        //         console.log(item.min, item.max, "2")
        //         valid_minmax = true;
        //     } else if ((parseInt(item.max) > parseInt(item.match_count))) {
        //         couterror = true;
        //     }

        //     if (valid_minmax) {
        //         columnname += "Menu name : " + item.name + ' Min field should be less than Max Field and it is required. <br> '
        //         valid_minmax = '';

        //     } else if (couterror) {
        //         columnname += "Menu Name : " + item.name + ' Maxium value is ' + item.match_count + '<br> '
        //         couterror = '';

        //     }

        // })

        //if (valid_minmax || couterror) {
        // if (columnname != '') {
        //     setFormErrorMsg(columnname);
        //     setminmaxerror(true);
        //     return false;
        // } else {

        setminmaxerror(false);
        // }
        setshowCustommenuCount(false);
    }

    const FieldChange = (e, index) => {
        if (formFields[index].name == "weight") {
            const re = /^\d*\.?\d*$/

            if (!e.target.value.match(re) && e.target.value != "") {

                return false;
            }
        }
        formFields[index].value = e.target.value;
        setFormFields([...formFields]);
    };

    const CheckOrRadioChange = (value, index) => {

        if (formFields[index].name == "custom_menus")
            setCustom_menu(value);
        if (formFields[index].name == "uom" && value == "0" && subId == 4) {
            setIs_Weight('1')
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "hide";
                formFields[get_index].validation = "";
            }
        }
        else if (formFields[index].name == "uom" && value == "1") {
            let get_index = _.findIndex(formFields, { name: "weight" })
            setIs_Weight('0')
            if (get_index >= 0) {
                formFields[get_index].class = "hide";
                formFields[get_index].validation = "";
            }
        } else if (formFields[index].name == "uom" && value == "0") {
            setIs_Weight('1')
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "";
                formFields[get_index].validation = "required";
            }
        }
        if (formFields[index].name == "reward") {
            if (value == 1) {
                setRewardhide(true);
                let data = chosenItems;
                data.map((item) => {
                    return item.price = 0;
                })
                setChosenItems([...data])
            } else {
                setRewardhide(false);
                let data = chosenItems;
                data.map((item) => {
                    item.price = '';
                    item.reward = '';
                    return item;
                })
                setChosenItems([...data])
            }
        }

        formFields[index].value = value;
        setFormFields([...formFields]);
    }

    const DropdownCustom = (value) => {

        let data = customenuCountDatas;
        value.map((items) => {
            let index_chosen = _.findIndex(customenuCountDatas, { name: items.name });

            let index_subcat = _.findIndex(custommenuDatasbyID, { name: items.name });

            if (index_chosen < 0) {
                data.push({ name: items.name, id: items.id, min: 0, max: 1 });
            }
        })
        // let idata = _.omit(data, _.isUndefined)
        data = data.filter(function (element) {
            return element !== undefined;
        });
        let diff = _.differenceBy(data, value, 'name');
        let delete_index = -1;
        if (diff.length > 0)
            delete_index = _.findIndex(data, { name: diff[0]?.name });
        if (delete_index >= 0)
            _.unset(data, delete_index)


        data = data.filter(function (element) {
            return element !== undefined;
        });

        setcustomenuCountDatas([...data]);
        // setcustomItemSize([...data])
        let menuIds = data.map(value => value.id);
        let postData = { ids: menuIds }
        dispatch(custommenuDatabyID({ postData }));

        //*******Ice Category Size based custom menus */
        let temp = customItemSize
        if (temp && temp.length) {
            let customName = customItemSize && customItemSize[0].customMenuCounts.map((item) => {
                return item.customDatas.name
            })
            let valueName = value && value.map((item) => {
                return item.name
            })
            if (customName.length > 0) {
                let dataValue = customItemSize
                for (let i in value) {
                    if (customName.includes(value[i].name)) {
                        let data = dataValue && dataValue.map((item) => {
                            item.customMenuCounts = item.customMenuCounts.filter((items) => {
                                return (
                                    valueName.includes(items.customDatas.name)
                                )
                            })
                            return item
                        })
                        let idValue = []
                        if (data) {
                            data && data[0].customMenuCounts.map((item) => {
                                idValue.push(item.customDatas.id)
                            })
                        }
                        let postData = { ids: idValue }
                        // dispatch(custommenuDatabyID({ postData }));

                        setcustomItemSize([...data])

                    }
                    else {
                        let customDatas = {
                            "id": `${value[i].id}`,
                            "name": value[i].name,
                            "max": "1",
                            "min": "0"
                        }
                        for (let j in customItemSize) {
                            customItemSize[j].customMenuCounts.push({ customDatas: customDatas })
                        }

                        let idValue = []
                        if (customItemSize) {
                            customItemSize && customItemSize[0].customMenuCounts.map((item) => {
                                idValue.push(item.customDatas.id)
                            })
                        }
                        let postData = { ids: idValue }
                        // dispatch(custommenuDatabyID({ postData }));
                    }
                }

                setcustomItemSize([...customItemSize])
            }
        } else {
            let itemSizes = chosenItems.map((item) => { return item.itemsize_id })

            let arr = [];

            let mergeCustom = itemSizes.map((item) => { return data.map((item1) => { return arr.push({ sizeID: item, customDatas: item1 }) }) })

            const result = Object.values(arr.reduce((acc, cur) => {
                acc[cur.sizeID] = acc[cur.sizeID] || { sizeID: cur.sizeID, customMenuCounts: [] };
                acc[cur.sizeID].customMenuCounts.push({ customDatas: cur.customDatas });
                return acc;
            }, {}))


            // function mergeBasedOnKey(list) {
            //     let c = Object.values(list.reduce((a, b) => {

            //         console.log(a, b, 'reduce==');
            //         // a[b.name] = a[b.name] || { name: b.name, value: [] }
            //         // if (typeof (b['value']) == "string") {
            //         //     a[b.name].value.push(b['value'])
            //         // }
            //         // else {
            //         //     a[b.sizeID].value = [...a[b.sizeID].value, ...b.value]
            //         // }

            //         return a

            //     }, {}))
            //     return c
            // }
            // let ans = mergeBasedOnKey(mergeCustom)
            // console.log(ans)

            setcustomItemSize([...result])
        }



        if (selectedDatas.length > 0) {

            let sdata = selectedDatas.map((vals) => {
                let sdelete_index = _.findIndex(data, { "id": vals.custom_menu_id });
                if (sdelete_index < 0) {
                    _.unset(vals)
                    rowcheckbox[vals.custom_menu_id] = [];
                    setrowcheckbox([...rowcheckbox]);
                } else {
                    return vals;
                }
            });
            sdata = sdata.filter(function (element) {
                return element !== undefined;
            });
            setselectedDatas([...sdata]);
        }
    };

    //Update Weight Value 
    useEffect(() => {
        if (((categoryId === 4) && (customenuCountWeight && customenuCountWeight.length > 0) && (is_weight === '1'))) {
            let filteredWeightItem = _.find(customenuCountWeight, { itemsize_id: Number(defaultItemsize) })
            if (filteredWeightItem) {
                setWeightValue(filteredWeightItem && filteredWeightItem.weight_value)
            }
        }
        else {
            let filteredWeightItem = _.find(formFields, { name: "weight" })
            if (filteredWeightItem) {
                setWeightValue(filteredWeightItem && filteredWeightItem.value)
            }
        }
    }, [customenuCountWeight, defaultItemsize, is_weight, formFields])

    useEffect(() => {
        if (lastProductId) {
            setNewProductId(lastProductId)
        }

    }, [lastProductId])

    useEffect(() => {
        dispatch(
            productData({
                query: '',
                page: page + 1,
                limit: rowsPerPage,
                sortby: 'name',
                order: 'asc',
                reward: 0
            })
        );
    }, [])


    const DropdownChange = (value, index, type) => {
        if (type === "subcategory") {
            console.log(value, 'value==');
            if (value && value.length > 0 ? value && value[0].hybrid_category == '1' : false) {
                setHybridProduct(true)
                setIs_Weight('0')
                let get_index = _.findIndex(formFields, { name: "uom" })
                if (get_index >= 0) {
                    formFields[get_index].class = "hide";
                    formFields[get_index].validation = "";
                    formFields[get_index].value = 1;
                }

                let get_indexs = _.findIndex(formFields, { name: "weight" })
                if (get_indexs >= 0) {
                    formFields[get_indexs].class = "hide";
                    formFields[get_indexs].validation = "";
                }
            } else {
                setHybridProduct(false)
                let get_index = _.findIndex(formFields, { name: "uom" })
                if (get_index >= 0) {
                    formFields[get_index].class = "";
                    formFields[get_index].validation = "";
                    formFields[get_index].value = 0;
                }
                if (categoryId != 4) {
                    let get_indexs = _.findIndex(formFields, { name: "weight" })
                    if (get_indexs >= 0) {
                        formFields[get_indexs].class = "";
                        formFields[get_indexs].validation = "required";
                    }
                }
            }
        }

        if (type === 'category') {
            setCategoryId(value && value.length > 0 ? value[0].id : '')
        }

        //if (value.length > 0) {
        if (type === "category") {
            setSubcategoryOptions([]);
            let datas = formFields.map((data, index) => {
                if (data.name === "subcategory_id")
                    data.value = [];
                return data;
            })
            setFormFields(datas)
            if (value.length > 0) {
                let postData = { category_id: value[0].id };
                dispatch(subcategoryFilterData({ postData }))
            }
        }
        if (type == 'defaultsize' && subId == 4) {
            setDefaultItemsize(value && value.length > 0 && value[0].id)
        }
        if (type === "itemsize") {
            let data = chosenItems;
            let new_id = "";
            value.map((items, index) => {
                let index_chosen = _.findIndex(chosenItems, { name: items.name });
                if (index_chosen < 0) {
                    data.push({ name: items.name, itemsize_id: items.id, price: '', points: '', position: index });
                    new_id = items.id;
                }
            })
            // let idata = _.omit(data, _.isUndefined)
            data = data.filter(function (element) {
                return element !== undefined;
            });
            let diff = _.differenceBy(data, value, 'name');
            let delete_index = -1;
            if (diff.length > 0)
                delete_index = _.findIndex(data, { name: diff[0]?.name });
            if (delete_index >= 0) {
                let default_index = _.findIndex(formFields, { name: "default_size" });
                if (default_index >= 0) {
                    let dval = formFields[default_index].value;
                    if (dval.length > 0 && diff[0]?.name == dval[0]?.name) {
                        formFields[default_index].value = [];
                    }
                }
                _.unset(data, delete_index)
            }


            data = data.filter(function (element) {
                return element !== undefined;
            });
            setChosenItems([...data]);

            if (selectedDatas.length > 0) {
                let sdata = selectedDatas.map((vals) => {
                    let iids = vals.itemsize_id;
                    let iprice = vals.price;
                    if (delete_index >= 0) {
                        let findIndexOfTheItem = vals.itemsize_id.indexOf(diff && diff[0] && diff[0].itemsize_id);
                        if (findIndexOfTheItem) {
                            _.unset(vals.itemsize_id, findIndexOfTheItem)
                            _.unset(vals.price, findIndexOfTheItem)
                        }
                        // _.unset(vals.itemsize_id, delete_index)
                        // _.unset(vals.price, delete_index)
                    }
                    else if (new_id != "") {
                        // Check if the new item size ID is already present in vals.itemsize_id
                        if (!vals.itemsize_id.includes(new_id)) {
                            // Add the new item size ID
                            vals.itemsize_id.push(new_id);
                            // Push 0 as the initial price for the new item size ID
                            vals.price.push(0);
                        }
                    }
                    vals.itemsize_id = vals.itemsize_id.filter(function (element) {
                        return element !== undefined;
                    });
                    vals.price = vals.price.filter(function (element) {
                        return element !== undefined;
                    });
                    return vals;
                });
                setselectedDatas([...sdata]);
            }

            //   Weight for IceCream 
            if (customenuCountWeight.length) {
                let dataArr = customenuCountWeight
                dataArr.push({ itemsize_id: `${value?.id}`, itemsize_name: value?.name, weight_value: 0 })
                let filteredData = dataArr.filter((v, i, a) => a.findIndex(v2 => (v2.itemsize_id === v?.itemsize_id)) === i)
                setcustomenuCountWeight([...filteredData])
            }
        }
        formFields[index].value = value;
        setFormFields([...formFields]);
        //}
        if (type == 'category') {
            setSubId(value && value.length > 0 && value[0].id)
        }
    };

    let arr = [];

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        let imagePathArray = []
        setMerchImageArray([])
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        let formdata = new FormData();
        if (categoryId === 5) {
            setUploadFiles(acceptedFiles);
            formdata.append("file", acceptedFiles)
        }

        //setfileUpload(binaryStr);
        acceptedFiles.forEach((file) => {
            if (categoryId === 5) {
                formdata.append("fileToUpload", file);
            }
            setfilename(file.name)
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result

                const img = new Image();
                img.onload = () => {
                    // const width = img.naturalWidth;
                    // const height = img.naturalHeight;


                    // if (width != maxProductWidth || height != maxProductHeight) {
                    //     const errorMessage = {
                    //         file,
                    //         errors: [
                    //             {
                    //                 code: "file-invalid-dimensions",
                    //                 message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
                    //             },
                    //         ],
                    //     };

                    //     rejectedFiles.push(errorMessage);
                    //     setThumbsImage([]);

                    // } else {

                    // console.log(`Image dimensions: ${width}x${height}`);
                    setfileUpload(binaryStr);
                    imagePathArray.push(file.path)
                    if (categoryId === 5) {
                        merchImageArray.push({
                            preview: URL.createObjectURL(file),
                            filePath: file.path,
                            binaryData: binaryStr,
                        })
                        setMerchImageArray(merchImageArray)
                    }
                    setfilePath(categoryId === 5 ? imagePathArray && imagePathArray[0] : file.path);
                    setfileerror(false);
                    // }
                };
                img.src = binaryStr;

            }
            reader.readAsDataURL(file)
        })

        //const data = await imgaeUpload(formdata);
        //console.log("getCount--> ", data);
    }, [categoryId, merchImageArray])



    // const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    //     setFiles(acceptedFiles.map(file => Object.assign(file, {
    //         preview: URL.createObjectURL(file)
    //     })));

    //     if (categoryId === 5) {
    //         setUploadFiles(...uploadfiles, acceptedFiles);
    //         let formdata = new FormData();
    //         formdata.append("file", acceptedFiles)
    //     }

    //     acceptedFiles.forEach((file) => {
    //         console.log(file);
    //         setfilename(file.name)
    //         const reader = new FileReader()

    //         reader.onabort = () => console.log('file reading was aborted')
    //         reader.onerror = () => console.log('file reading has failed')
    //         reader.onload = () => {
    //             // Do whatever you want with the file contents
    //             const binaryStr = reader.result

    //             const img = new Image();
    //             img.onload = () => {
    //                 const width = img.naturalWidth;
    //                 const height = img.naturalHeight;
    //                 setImgSRCwidth(width);
    //                 setImgSRCheight(height);

    //                 if (width < maxProductWidth || height < maxProductHeight) {
    //                     const errorMessage = {
    //                         file,
    //                         errors: [
    //                             {
    //                                 code: "file-invalid-dimensions",
    //                                 message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
    //                             },
    //                         ],
    //                     };

    //                     rejectedFiles.push(errorMessage);
    //                     setThumbsImage([]);
    //                     setfileUpload('')
    //                 } else {

    //                     setShowPreview(true);
    //                     console.log(`Image dimensions: ${width}x${height}`);
    //                     // setfileUpload(binaryStr);
    //                     setfilePath(file.path);
    //                     setfileerror(false);
    //                 }
    //             };
    //             img.src = binaryStr;
    //         }
    //         reader.readAsDataURL(file)
    //     })

    // }, [])

    useEffect(() => {
        if (subId == 4) {
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "hide";
                formFields[get_index].validation = "";
            }
        } else {
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "";
                formFields[get_index].validation = "required";
            }
        }
    }, [subId])

    const onChange = (e) => {
        setValue(e.target.value);
    };


    // useEffect(() => {
    //     if (files.length > 0) {
    //         console.log(files[0].preview, "preview");
    //         setImgSRC(files[0].preview)
    //         let thumbs = files.map((file, index) => (
    //             // <>
    //             //     <div className="dropzone_thum_img" key={file.name}>
    //             //         <div className="dropzone_thumb">
    //             //             <img
    //             //                 src={file.preview}
    //             //                 className="dropzone_img"
    //             //                 // Revoke data uri after image is loaded
    //             //                 onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //             //             />
    //             //         </div>
    //             //     </div><br />
    //             //     <Radio.Group onChange={onChange} value={value}>
    //             //         <Radio value={index + 1}>Default</Radio>
    //             //     </Radio.Group>
    //             // </>
    //             <div className="dropzone_thum_img" key={file.name}>
    //                 <div className="dropzone_thumb">
    //                     <img
    //                         src={file.preview}
    //                         className="dropzone_img"
    //                         // Revoke data uri after image is loaded
    //                         onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //                     />
    //                 </div>
    //                 <Radio.Group onChange={onChange} value={value}>
    //                     <Radio value={index + 1}>Set as Default</Radio>
    //                 </Radio.Group>
    //             </div>


    //         ));
    //         setThumbsImage(thumbs);
    //     }
    // }, [files])


    useEffect(() => {
        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {
            const t = setTimeout(() => {

                // We use canvasPreview as it's much faster than imgPreview.

                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                )
            }, 100);

            return () => {
                clearTimeout(t)
            }
        }

    }, [completedCrop])



    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: {
            'image/*': []
        }, multiple: categoryId === 5 ? true : false,
        maxSize: 1024000,

    })

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{(e.message == 'File is larger than 1024000 bytes') ? 'File size should be less than 1 MB' : e.message}</span>

            ))}
        </div>
    ));
    //Crop Image Modal close
    const Imgmodalclose = () => {
        setShowPreview(false)
    }

    const cropImg = () => {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        //   console.log(previewCanvasRef.current)
        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const reader = new FileReader();

            reader.onload = () => {
                setfileUpload(reader.result);
                setShowPreview(false)
            };
            reader.readAsDataURL(blob);
        })
    };


    const submitCategory = (e) => {

        let itemsize_id = _.map(chosenItems, 'itemsize_id');
        let price = _.map(chosenItems, 'price');

        if (((categoryId === 5) && (value === '' || value === null || value === undefined))) {
            setfileerror(true)
        }
        else {
            setfileerror(false)
        }
        //return false;
        if (simpleValidator.current.allValid()) {
            setShowvalidator(false);
            //alert('You submitted the form and stuff!');
            let arr = {};
            if (fileUpload == '') {
                setfileerror(true);
                return false;
            }
            if (filesizeerror == true) {
                return false;
            }


            //const postData = new FormData();
            const datas = formFields.map((data) => {
                let val = '';
                if (data?.name === "status") {
                    val = data.value;
                    val = val[0].id;
                } else if (data?.name === "category_id") {
                    val = data.value;
                    val = val[0].id;
                } else if (data?.name === "subcategory_id") {
                    val = data.value;
                    val = val[0].id;
                } else if (data?.name === "default_size") {
                    val = data.value;
                    val = val[0].id;
                } else if (data?.name === "reward") {
                    if (data.value == "")
                        val = 0;
                    else
                        val = data.value
                } else {
                    val = data.value
                }
                return arr[data?.name] = val;


            });
            if (arr.custom_menus == 1 && customenuCountDatas.length == 0) {
                setcustommenuserror(true);
                return false;
            } else {
                setcustommenuserror(false);
            }
            if (arr.custom_menus == 1 && (customenuCountDatas.length == 0 || selectedDatas.length == 0)) {
                setcustomerror(true);
                return false;
            }
            let price_valid = true;
            if (!rewardhide) {
                chosenItems.map((item) => {
                    if (item.price == "" || item.price === null || item.price === undefined || item.price === NaN) {
                        price_valid = false;
                    }
                });
            } else {
                chosenItems.map((item) => {
                    if (item.points == "" || item.points === null || item.points === undefined) {
                        price_valid = false;
                    }
                });
            }
            if (price_valid == false) {
                setpriceerror(true);
                return false;
            }

            let newData = []
            for (let i in chosenItems) {
                newData.push({
                    "name": chosenItems[i].name,
                    "itemsize_id": chosenItems[i].itemsize_id,
                    "price": chosenItems[i].price,
                    "points": chosenItems[i].points,
                    "position": Number(i)
                })
            }
            let kitchenData = []
            for (let i in columnOrder) {
                kitchenData.push({
                    "name": columnOrder[i].name,
                    "value": columnOrder[i].value,
                    "custommenu_id": columnOrder[i].value === 4 ? columnOrder[i].id : null,
                    "order_no": Number(i),
                    "position": columnOrder[i].position
                })
            }
            if (kitchenData && kitchenData.length > 0) {
                arr.kitchencode_data = kitchenData
            }

            if (newData && newData.length > 0) {
                arr.itemsize_id = newData;
            }
            // arr.itemsize_id = _.map(chosenItems, 'itemsize_id');
            arr.price = _.map(chosenItems, 'price');
            arr.points = _.map(chosenItems, 'points');
            arr.custom_products = selectedDatas;
            arr.custom_menudata = customenuCountDatas;
            // arr.sorted_custom = selectedDatas.map(item => item.id);
            arr.image = categoryId === 5 ? merchImageArray?.[value]?.binaryData : fileUpload;
            arr.imgpath = categoryId === 5 ? merchImageArray?.[value]?.filePath : filePath;
            arr.is_weight = is_weight;

            arr.hybrid_product = hybridProduct ? 1 : 0;

            delete arr['itemsize_ids'];

            if (defaultItemsize != 0 && subId == 4 && hybridProduct) {
                arr.custom_menudata_size = customItemSize
            }
            else if (defaultItemsize != 0 && subId == 4 && is_weight == '1') {
                arr.customsize_weight = customenuCountWeight
            }
            else if (defaultItemsize != 0 && subId == 4) {
                arr.custom_menudata_size = customItemSize
            }
            const postData = arr;

            if (updateID == "") {

                // dispatch(AddMerchImages({uploadfiles,newProductId}))
                if (value === undefined && categoryId === 5) {
                    toast.error('Set as Default is a mandatory field')
                }
                else {
                    dispatch(AddProduct({ postData }));
                }
            }

            else {
                postData.id = updateID;
                dispatch(UpdateProduct({ postData, updateID }));
            }
        }
        else {
            if (fileUpload == '' && updateID == "") {
                setfileerror(true);
            }
            // setcustommenuserror(false);
            setShowvalidator(true);
        }
    };

    useEffect(() => {
        if (columnOrder.length) {
            let InitialArray = [{
                code_value: 'Quantity',
                value: 1,
                custommenu_id: null
            }]
            const datas = formFields.map((data) => {
                let val = '';
                if (data?.name === "default_size") {
                    val = data.value;
                    if (val.length) {
                        InitialArray.push({
                            code_value: val[0].name,
                            value: 2,
                            custommenu_id: null
                        })
                    }
                }
                else if (data?.name === "item_code") {
                    InitialArray.push({
                        code_value: data.value,
                        value: 3,
                        custommenu_id: null
                    })
                }
            });
            let customDatas = []
            let testData = custommenuDatasbyID && custommenuDatasbyID.map((row, tindex) => {
                row?.custom_menu_items.length > 0 && columnOrder.find((item) => { return item.value == 4 && item.id == row?.id }) && row?.custom_menu_items.map((item, rindex) => {
                    if (selectedDatas.find((value) => { return value.default == 1 && value.item_id == item.id })) {
                        customDatas.push({
                            code_value: item.code,
                            value: 4,
                            custommenu_id: row?.id
                        })
                    }
                })
            }
            )

            let mergeCustomMenus = customDatas.reduce(function (o, cur) {
                // Get the index of the key-value pair.
                var occurs = o.reduce(function (n, item, i) {
                    return (item.custommenu_id === cur.custommenu_id) ? i : n;
                }, -1);

                // If the name is found,
                if (occurs >= 0) {

                    // append the current value to its list of values.
                    o[occurs].code_value = o[occurs].code_value.concat(cur.code_value);

                    // Otherwise,
                } else {

                    // add the current item to o (but make sure the value is an array).
                    var obj = {
                        code_value: [cur.code_value],
                        value: cur.value,
                        custommenu_id: cur.custommenu_id
                    };
                    o = o.concat([obj]);
                }
                return o;
            }, []);


            let finalValArray = [...InitialArray, ...mergeCustomMenus]

            let mergedList = [];
            finalValArray.map((arr1) => {
                mergedList.push({ ...arr1, order_no: columnOrder.find((arr2) => { return ((arr2.value === arr1.value) && (((arr2.value == 4) ? arr2.id : null) == arr1.custommenu_id)) })?.position })
            });

            let kitchenCodeList = buildData(mergedList);
            let tempArr = []
            for (let i in kitchenCodeList) {
                tempArr.push({
                    value: Array.isArray(kitchenCodeList[i].code_value) ? kitchenCodeList[i].code_value.join(" ") : kitchenCodeList[i].code_value
                })
            }

            let finalData = tempArr.map((item) => { return item.value })
            finalData = finalData.join("  ")
            setsampleCodeOutput([...finalData])
        }
    }, [columnOrder, customenuCountDatas, selectedDatas])

    const zoomIn = () => {
        setScale(scale * 2);
    }

    const zoomOut = () => {
        setScale(scale / 2);
    }

    const resetFormFields = () => {
        setUpdateID("")
        setFiles([])
        dispatch(clearSubCatgDatas());
        setSubcategoryOptions([])
        setfileUpload('');
        let datas = formFields.map((data, index) => {
            if (data.name === "category_id")
                data.value = [];
            else if (data.name === "subcategory_id")
                data.value = [];
            else if (data.name === "itemsize_ids")
                data.value = [];
            else if (data.name === "default_size")
                data.value = [];
            else if (data.name === "uom")
                data.value = 0;
            else if (data.name === "weight") {
                data.class = "";
                data.validation = "required";
                data.value = "";
            }
            else if (data.name === "custom_menus")
                data.value = 0;
            else if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
            return data
            //return datas.push(data);
        })
        //console.log(datas, "datas")
        setFormFields(datas)
    };

    const changePrice = (e, index) => {

        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) && e.target.value >= 0 || e.target.value == "") {
            chosenItems[index].price = e.target.value
            setChosenItems([...chosenItems]);
            setpriceerror(false);
        }
    }
    const changeReward = (e, index) => {
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) && e.target.value >= 1 || e.target.value == "") {
            chosenItems[index].points = e.target.value
            setChosenItems([...chosenItems]);
            setpriceerror(false);
        }
    }

    const toggleChecked = (e) => {
        setDefaultItemsize(e.target.value);
    }



    const changeMinMax = (e, index, type) => {
        // if (defaultItemsize) {
        setminmaxerror(false);
        const customenuItem = customenuCountDatas[index];
        // Loop through each item in selectedDatas to find and update the matching item
        selectedDatas.forEach((selectedItem, selectedIndex) => {
            // Check if the custom_menu_id matches with customenuItem.id
            if (selectedItem.custom_menu_id === customenuItem.id) {
                // Update the default property of the matched item to 0
                selectedDatas[selectedIndex].default = 0;
            }
        });

        // Now selectedDatas contains the updated objects with default property set to 0 where matching custom_menu_id is found
        setselectedDatas(selectedDatas)

        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            if (type == "min")
                customenuCountDatas[index].min = e.target.value
            else if (type == "max")
                customenuCountDatas[index].max = e.target.value
            else
                customenuCountDatas[index].max = ''

            setcustomenuCountDatas([...customenuCountDatas]);

            let filter = [];
            let arr = customItemSize;
            if (arr.length) {
                let filter = arr.filter((item) => { return item.size_id != defaultItemsize })
                filter.push({
                    size_id: defaultItemsize,
                    data: customenuCountDatas
                })
            } else {
                filter.push({
                    size_id: defaultItemsize,
                    data: customenuCountDatas
                })
            }
            // let filter = arr.filter((item) => { return item.size_id != defaultItemsize })
            // console.log(filter, 'e.target =filter===');
            // filter.push({
            //     size_id: defaultItemsize,
            //     data: customenuCountDatas
            // })
            // setcustomItemSize([...filter])
            // console.log(filter, 'e.target --arr');
        }
        // } else {
        //     toast.dismiss()
        //     toast.error('Choose Product Size')
        // }
    }

    const changeMinMaxWeight = (e, index, name) => {
        if (defaultItemsize) {
            customenuCountWeight[index] = {
                "itemsize_name": name,
                "itemsize_id": customenuCountWeight[index].itemsize_id ? customenuCountWeight[index].itemsize_id : defaultItemsize,
                "weight_value": e.target.value
            }
            setcustomenuCountWeight([...customenuCountWeight]);
        }
        else {
            toast.dismiss()
            toast.error('Choose Product Size')
        }
    }

    const changeMinMaxCustom = (e, index, type, Ishybrid) => {
        const customenuItem = customenuCountDatas[index];

        // Loop through each item in selectedDatas to find and update the matching item
        selectedDatas.forEach((selectedItem, selectedIndex) => {
            // Check if the custom_menu_id matches with customenuItem.id
            if (selectedItem.custom_menu_id === customenuItem.id) {
                // Update the default property of the matched item to 0
                selectedDatas[selectedIndex].default = 0;
            }
        });

        // Now selectedDatas contains the updated objects with default property set to 0 where matching custom_menu_id is found
        setselectedDatas(selectedDatas)

        if (defaultItemsize) {
            setminmaxerror(false);
            const re = /^\d*\.?\d*$/
            let customData = customItemSize
            if (e.target.value.match(re) || e.target.value == "") {
                if (type == "min") {
                    let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
                    customData[indexvalue].customMenuCounts[index].customDatas
                        = {
                        "id": customData[indexvalue].customMenuCounts[index].customDatas.id,
                        "match_count": customData[indexvalue].customMenuCounts[index].customDatas.match_count,
                        "min": e.target.value,
                        "max": customData[indexvalue].customMenuCounts[index].customDatas.max,
                        "name": customData[indexvalue].customMenuCounts[index].customDatas.name

                    }
                }
                else if (type == "max") {
                    let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
                    if (Ishybrid == "hybrid") {
                        let toppingIndex = customData[indexvalue].customMenuCounts.findIndex((item) => item.customDatas.id == '17')
                        customData[indexvalue].customMenuCounts[toppingIndex].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.match_count,
                            "max": e.target.value,
                            "min": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.min,
                            "name": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.name
                        }
                        let CombinationIndex = customData[indexvalue].customMenuCounts.findIndex((item) => item.customDatas.id == '21')
                        customData[indexvalue].customMenuCounts[CombinationIndex].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.match_count,
                            "max": e.target.value,
                            "min": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.min,
                            "name": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.name
                        }
                    } else {
                        let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
                        customData[indexvalue].customMenuCounts[index].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[index].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[index].customDatas.match_count,
                            "max": e.target.value,
                            "min": customData[indexvalue].customMenuCounts[index].customDatas.min,
                            "name": customData[indexvalue].customMenuCounts[index].customDatas.name

                        }
                    }
                    // customMenuCounts[index].customDatas.max = e.target.value
                }
                else
                    customData.find((item) => item.sizeID == defaultItemsize).customMenuCounts[index].customDatas.max = ''
                setcustomItemSize([...customData]);

                // let filter = [];
                // let arr = customItemSize;
                // if (arr.length) {
                //     let filter = arr.filter((item) => { return item.size_id != defaultItemsize })
                //     console.log(filter, 'customenuCountDatas =filter===');
                //     filter.push({
                //         size_id: defaultItemsize,
                //         data: customenuCountDatas
                //     })
                // } else {
                //     filter.push({
                //         size_id: defaultItemsize,
                //         data: customenuCountDatas
                //     })
                // }
                // let filter = arr.filter((item) => { return item.size_id != defaultItemsize })
                // console.log(filter, 'e.target =filter===');
                // filter.push({
                //     size_id: defaultItemsize,
                //     data: customenuCountDatas
                // })
                // setcustomItemSize([...filter])
                // console.log(filter, 'e.target --arr');
            }
        } else {
            toast.dismiss()
            toast.error('Choose Product Size')
        }
    }

    // const TabName = (row) => {
    //     const get_arr = _.filter(customenuCountDatas, { name: row?.name });
    //     if (get_arr.length > 0)
    //         return row?.name + " (" + get_arr[0].min + ", " + get_arr[0].max + ")";
    //     else
    //         return row?.name;
    // };

    const TabName = (row) => {

        let minValue;
        let maxValue;

        const get_arr = _.filter(customenuCountDatas, { name: row?.name });

        if (categoryId === 4) {
            const filterMatchedObject = customItemSize.filter(item => item.sizeID === Number(defaultItemsize));
            if (filterMatchedObject) {
                const matchedObject = filterMatchedObject.flatMap(item => item.customMenuCounts)?.find(({ customDatas }) => {
                    return Number(customDatas.id) === Number(row.id)
                });
                minValue = matchedObject?.customDatas?.min
                maxValue = matchedObject?.customDatas?.max
            }
        }
        else {
            if (get_arr.length > 0) {
                minValue = get_arr[0].min;
                maxValue = get_arr[0].max
            }
        }

        if (get_arr.length > 0 && is_weight === '0') {
            // minValue = get_arr[0].min;
            // maxValue = get_arr[0].max
            return row?.name + " (" + minValue + ", " + maxValue + ")";
        }
        else
            return row?.name;
    };

    const ItemPrice = (price, row_data, index, item) => {
        //console.log(row_data, price)
        if (row_data)
            return price;
        else {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                return data[index_selected].price[index];
            }
        }

    };

    const ChangeItemPrice = (e, index, item) => {

        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                data[index_selected].price[index] = e.target.value;
            }
            setselectedDatas([...data]);
        }
    };
    const ItemPoints = (price, row_data, index, item) => {

        //console.log(row_data, price)
        if (row_data)
            return price;
        else {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                return data[index_selected].points[index];
            }
        }

    };

    const ChangeItemPoints = (e, index, item) => {

        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                data[index_selected].points[index] = e.target.value;
            }
            setselectedDatas([...data]);
        }
    };

    const selectAll = (row, tindex) => {

        if (typeof (selectallitem[tindex]) === "undefined") {
            selectallitem[tindex] = true
        } else {
            selectallitem[tindex] = !selectallitem[tindex];
        }
        setselectallitem(selectallitem);
        if (row.custom_menu_items && row.custom_menu_items.length > 0) {
            if (selectallitem[tindex]) {
                row.custom_menu_items.map((item, index) => {
                    if (typeof (rowcheckbox[tindex]) === "undefined") {
                        rowcheckbox.push(tindex);
                        rowcheckbox[tindex] = [index];
                    } else {
                        let indexdata = _.indexOf(rowcheckbox[tindex], index)
                        if (indexdata < 0)
                            rowcheckbox[tindex].push(index);

                        rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                            return element !== undefined;
                        });
                    }
                    setrowcheckbox([...rowcheckbox]);
                    let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                    let item_price = _.fill(Array(chosenItems.length), item.price);
                    let item_points = _.fill(Array(chosenItems.length), 0);
                    let data = selectedDatas;
                    let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                    if (index_selected < 0) {
                        data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, points: item_points, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                    } else {
                        //_.unset(data, index_selected);
                    }
                    data = data.filter(function (element) {
                        return element !== undefined;
                    });
                    //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                    setselectedDatas([...data])
                })
            } else {
                row.custom_menu_items.map((item, index) => {
                    if (typeof (rowcheckbox[tindex]) === "undefined") {
                        // rowcheckbox.push(tindex);
                        // console.log(rowcheckbox, "rowcheckbox")
                        // rowcheckbox[tindex] = [index];
                    } else {
                        let indexdata = _.indexOf(rowcheckbox[tindex], index)
                        if (indexdata >= 0)
                            _.unset(rowcheckbox[tindex], indexdata);
                        else
                            rowcheckbox[tindex].push(index);

                        rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                            return element !== undefined;
                        });
                    }
                    setrowcheckbox([...rowcheckbox]);
                    let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                    let item_price = _.fill(Array(chosenItems.length), item.price);
                    let data = selectedDatas;
                    let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                    if (index_selected < 0) {
                        //data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                    } else {
                        _.unset(data, index_selected);
                    }
                    data = data.filter(function (element) {
                        return element !== undefined;
                    });
                    //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                    setselectedDatas([...data])
                    let defaultdata = defaultSelect;
                    let dindex_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
                    if (dindex_selected >= 0) {
                        _.unset(defaultdata, dindex_selected);

                        defaultdata = defaultdata.filter(function (element) {
                            return element !== undefined;
                        });
                        setDefaultSelect(defaultdata);
                    }
                })
            }
        }
        /* if (row.custom_menu_items && row.custom_menu_items.length > 0) {
            row.custom_menu_items.map((item, index) => {
                if (typeof (rowcheckbox[tindex]) === "undefined") {
                    rowcheckbox.push(tindex);
                    console.log(rowcheckbox, "rowcheckbox")
                    rowcheckbox[tindex] = [index];
                }
                else {
                    let indexdata = _.indexOf(rowcheckbox[tindex], index)
                    console.log(indexdata, "indexdata")
                    if (indexdata >= 0)
                        _.unset(rowcheckbox[tindex], indexdata);
                    else
                        rowcheckbox[tindex].push(index);
     
                    rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                        return element !== undefined;
                    });
                }
                console.log(rowcheckbox, "rowcheckbox")
                //rowcheckbox[index] = (rowcheckbox[index]) ? true : false;
                setrowcheckbox([...rowcheckbox]);
                let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                let item_price = _.fill(Array(chosenItems.length), item.price);
                let data = selectedDatas;
                let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                if (index_selected < 0) {
                    data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                } else {
                    _.unset(data, index_selected);
                }
                data = data.filter(function (element) {
                    return element !== undefined;
                });
                //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                setselectedDatas([...data])
            })
     
        } */
    };

    const selectItems = (row, tindex, index, item) => {
        setcustomerror(false);
        if (typeof (rowcheckbox[tindex]) === "undefined") {
            rowcheckbox.push(tindex);
            rowcheckbox[tindex] = [index];
        }
        else {

            let indexdata = _.indexOf(rowcheckbox[tindex], index)
            if (indexdata >= 0)
                _.unset(rowcheckbox[tindex], indexdata);
            else
                rowcheckbox[tindex].push(index);

            rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                return element !== undefined;
            });
        }
        //rowcheckbox[index] = (rowcheckbox[index]) ? true : false;
        setrowcheckbox([...rowcheckbox]);
        let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
        let item_price = _.fill(Array(chosenItems.length), item.price);
        let item_points = _.fill(Array(chosenItems.length), 0);
        let data = selectedDatas;
        let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
        if (index_selected < 0) {
            data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, points: item_points, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
        } else {
            _.unset(data, index_selected);
        }
        data = data.filter(function (element) {
            return element !== undefined;
        });
        //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
        setselectedDatas([...data])
        let defaultdata = defaultSelect;
        let dindex_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
        if (dindex_selected >= 0) {
            _.unset(defaultdata, dindex_selected);

            defaultdata = defaultdata.filter(function (element) {
                return element !== undefined;
            });
            setDefaultSelect(defaultdata);
        }

        if (typeof (selectallitem[tindex]) === "undefined") {
            selectallitem[tindex] = false
        } else {
            let count = row.custom_menu_items.length;
            let datas = _.filter(selectedDatas, { "custom_menu_id": row.id })
            if (count == datas.length)
                selectallitem[tindex] = true;
            else
                selectallitem[tindex] = false;
        }
        setselectallitem(selectallitem)
    };

    useEffect(() => {
        let tempData = customenuCountDatas
        for (let i in tempData) {
            let findEle = _.find(custommenuDatasbyID, { id: tempData[i].id })
            tempData[i].match_count = findEle && findEle.custom_menu_items && findEle.custom_menu_items.length
        }
        setcustomenuCountDatas([...tempData])

    }, [custommenuDatasbyID])

    const checkedBox = (row, type) => {
        let foundindex = _.findIndex(selectedDatas, { item_id: row?.id })
        if (foundindex > 0 && type == "checked") {
            return true;
        }
        else if (type == "checked") {
            return false;
        }
        if (foundindex > 0 && type == "disabled") {
            return false;
        }
        else if (type == "disabled") {
            return true;
        }

    };

    const selectDefault = (row, tindex, rindex, item) => {
        if (is_weight == 1) {
            let existingCustomWeights;
            if (defaultSelect.length) {
                existingCustomWeights = (Number(customWeightTotal) + Number(item.weight));
            } else {
                existingCustomWeights = Number(item.weight)
            }
            let icecreamCustomWeight;
            if (categoryId === 4 && customenuCountWeight.length) {
                let customWeightData = _.find(customenuCountWeight, { itemsize_id: Number(defaultItemsize) })
                icecreamCustomWeight = Number(customWeightData.weight_value)
            } else {
                icecreamCustomWeight = Number(weightValue)
            }
            let data = defaultSelect;
            let datadefault = 0;
            let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
            if (index_selected < 0) {
                if ((Number(existingCustomWeights) <= Number(icecreamCustomWeight))) {
                    setselectedDatas([...selectedDatas])

                    data.push({ custom_menu_id: row.id, item_id: item.id, weight: Number(item.weight) });
                    datadefault = 1;
                }
                else {
                    // window.alert('Maximum Weight limit exceeded for this Product!.')
                    weightError()
                }
            } else {
                _.unset(data, index_selected);
            }
            // console.log(data, selectedDatas, 'index_selected==data');
            data = data.filter(function (element) {
                return element !== undefined;
            });
            //let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
            selectedDatas.map((datas) => {
                if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
                    datas.default = datadefault;
                    return datas;
                }
            });
            let addweight = 0;
            if (data.length > 0) {
                addweight = data.reduce((a, b) => Number(a) + Number(b.weight), 0);
            }
            setcustomWeightTotal(addweight);
            defaultSelect = data;
            setselectedDatas([...selectedDatas])
            setDefaultSelect(data);

        } else {
            let data = defaultSelect;
            let countminmax = _.filter(customenuCountDatas, { id: row.id });
            let minValue;
            let maxValue;

            if (categoryId === 4) {
                const filterMatchedObject = customItemSize.filter(item => item.sizeID === defaultItemsize.toString());
                if (filterMatchedObject) {
                    const matchedObject = filterMatchedObject.flatMap(item => item.customMenuCounts)?.find(({ customDatas }) => {
                        return Number(customDatas.id) === Number(row.id)
                    });
                    minValue = matchedObject?.customDatas?.min
                    maxValue = matchedObject?.customDatas?.max
                }
            }

            let get_min = categoryId === 4 ? minValue : countminmax[0]['min'];
            let get_max = categoryId === 4 ? maxValue : countminmax[0]['max'];

            // let get_min = countminmax[0]['min'];
            // let get_max = countminmax[0]['max'];
            let datadefault = 0;
            let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
            if (index_selected < 0) {
                let getcustomdatas = _.filter(data, { custom_menu_id: row.id });
                let getcustomdatasindex = _.findIndex(data, { custom_menu_id: row.id });
                if (getcustomdatas.length >= parseInt(get_max)) {
                    //var lastItem = data.pop();
                    var lastItem = getcustomdatas.pop();
                    _.unset(data, getcustomdatasindex);
                    selectedDatas.map((datas) => {
                        if (datas.item_id == lastItem.item_id && datas.custom_menu_id == lastItem.custom_menu_id) {
                            datas.default = 0;
                            return datas;
                        }
                    });
                    setselectedDatas([...selectedDatas])
                    //data.splice(-1);
                    //return false;
                }
                data.push({ custom_menu_id: row.id, item_id: item.id });
                datadefault = 1;
            } else {
                _.unset(data, index_selected);
            }
            data = data.filter(function (element) {
                return element !== undefined;
            });
            //let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
            selectedDatas.map((datas) => {
                if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
                    datas.default = datadefault;
                    return datas;
                }
            });
            setselectedDatas([...selectedDatas])
            setDefaultSelect(data);
        }
    };


    useEffect(() => {
        //clearSubCatgDatas();
        //setSubcategoryOptions([]);
        resetFormFields();
        dispatch(categoryData({}));
        dispatch(custommenuData({ status: 1 }));
        dispatch(itemsizeData({ status: 1 }));
        simpleValidator.current.showMessages()
    }, [])


    useEffect(() => {
        if (categoryOptions && categoryOptions.length == 0) {
            if (categoryDatas && categoryDatas.length > 0) {
                let catdata = [];
                const activeCategoryDatas = _.filter(categoryDatas, { status: 1 })
                if (activeCategoryDatas && activeCategoryDatas.length > 0) {
                    activeCategoryDatas.map((data) => {
                        return catdata.push({ id: data.id, name: data.name })
                    });
                }
                setCategoryOptions(catdata)
            }
        }
        //if (itemSizeOptions && itemSizeOptions.length == 0) {
        if (itemsizeDatas && itemsizeDatas.length > 0) {
            let itemsizeoptions = [];
            itemsizeDatas.map((data) => {
                return itemsizeoptions.push({ id: data.id, name: data.description })
            });
            setItemSizeOptions(itemsizeoptions)
        }
        //}
        if (subcategoryOptions && subcategoryOptions.length == 0) {
            if (subcategoryDatas && subcategoryDatas.length > 0) {
                let subcategoryOptions = [];
                const activeSubCategoryDatas = _.filter(subcategoryDatas, { status: 1 })
                if (activeSubCategoryDatas && activeSubCategoryDatas.length > 0) {
                    activeSubCategoryDatas.map((data) => {
                        return subcategoryOptions.push({ id: data.id, name: data.name, hybrid_category: data.hybrid_category })
                    });
                }
                setSubcategoryOptions(subcategoryOptions)
            }
        }
        //if (defaultSizeOptions && defaultSizeOptions.length == 0) {
        if (chosenItems && chosenItems.length >= 0) {
            let defaultSizeOptions = [];
            let customenuWeight = [];
            let chosendata = chosenItems.filter(function (element) {
                return element !== undefined;
            });
            chosendata.map((data) => {
                return defaultSizeOptions.push({ id: data.itemsize_id, name: data.name })
            });

            //Weight Array Form
            chosendata.map((item) => {
                return customenuWeight.push({
                    itemsize_id: item.itemsize_id,
                    itemsize_name: item.name,
                    weight_value: 0
                })
            })
            setcustomenuCountWeight([...customenuWeight])
            setDefaultSizeOptions([...defaultSizeOptions])
        }
        //}

        //if (customMenuOptions && customMenuOptions.length == 0) {
        if (custommenuDatas && custommenuDatas.length > 0) {
            let customMenuOptions = [];
            custommenuDatas.map((data) => {
                return customMenuOptions.push({ id: data.id, name: data.name })
            });
            setCustomMenuOptions(customMenuOptions)
        }
        //}

    }, [itemsizeDatas, categoryDatas, subcategoryDatas, chosenItems, custommenuDatas])

    useEffect(() => {
        if (addloading === API_STATUS.FULFILLED) {
            if (categoryId === 5 && uploadfiles.length > 0 && lastProductId != (null || undefined)) {
                // dispatch(AddProduct({ postData }));
                dispatch(AddMerchImages({ uploadfiles, lastProductId }))
                toast.dismiss();
                toast.success("Added Successfully!");
                dispatch(clearProdAddLoading())
                navigate('/products');
            }
            else {
                toast.dismiss();
                toast.success("Added Successfully!");
                dispatch(clearProdAddLoading())
                navigate('/products');
            }
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }

            dispatch(clearErrormsg())

        }
    }, [addloading, errorMessage]);


    //Custom Menu Item Loop for D&D

    useEffect(() => {
        if (columnOrder && columnOrder.length > 0) {
            const idToPositionMap = Object.fromEntries(columnOrder.map((col) => [col.id, col.position]));

            // Update the positions in the initialColumns array
            const updatedInitialColumns = initialColumns.map((col) => ({
                ...col,
                position: idToPositionMap[col.id], // Update the position based on the mapping
            }));

            // Now, updatedInitialColumns has the correct positions from columnOrder
            setInitialColumns(updatedInitialColumns)
        }

    }, [columnOrder, customenuCountDatas])

    useEffect(() => {
        if (customenuCountDatas && customenuCountDatas.length > 0) {
            let temp = [];
            for (let i in customenuCountDatas) {
                const existingColumn = columnOrder.find((col) => col.id === customenuCountDatas[i].id);

                temp.push({
                    id: customenuCountDatas[i].id,
                    // position: initialColumns.length + parseInt(i) + 1,
                    position: existingColumn ? existingColumn.position : initialColumns.length + parseInt(i) + 1,
                    name: customenuCountDatas[i].name,
                    value: 4,
                });
            }

            // Combine initialColumns and temp, then sort based on position
            const combinedColumns = [...initialColumns, ...temp].sort((a, b) => a.position - b.position);
            setColumnOrder(combinedColumns);
        } else {
            setColumnOrder([...initialColumns.map((col) => ({ ...col, position: col.id }))]);
        }
    }, [customenuCountDatas]);


    // Function to update prices in selectedDatas based on the order of tempItemSizeIdsFromChosenItems
    function updatePrices(selectedDatas, tempItemSizeIdsFromChosenItems) {
        // Iterate over each item in selectedDatas
        selectedDatas.forEach(item => {
            // Get the index of the itemsize_id in tempItemSizeIdsFromChosenItems
            const index = tempItemSizeIdsFromChosenItems.indexOf(item.itemsize_id[0]);
            // If the index is found and the corresponding price exists, update the price in selectedDatas
            if (index !== -1 && item.price[index] !== undefined) {
                // Swap the prices based on the index
                [item.price[0], item.price[index]] = [item.price[index], item.price[0]];
                // Swap the itemsize_id based on the index
                [item.itemsize_id[0], item.itemsize_id[index]] = [item.itemsize_id[index], item.itemsize_id[0]];
            } else {
                // Log any problematic cases for debugging
                console.error('Error: Unable to update price for item:', item);
                console.error('Index:', index);
                console.error('Price at index:', item.price[index]);
            }
        });
    }



    //Set Custom menu items values based on chosenItem
    useEffect(() => {
        if (((chosenItems && chosenItems.length > 0) && (selectedDatas && selectedDatas.length > 0))) {

            let tempItemSizeIdsFromChosenItems = chosenItems.map((item) => {
                return item.itemsize_id
            })
            // let tempSelectedDatas = [...selectedDatas]

            // Call the function to update prices
            if (tempItemSizeIdsFromChosenItems) {
                updatePrices(selectedDatas, tempItemSizeIdsFromChosenItems);
            }


        }

    }, [chosenItems, selectedDatas])


    // const deleteImg = (index) => {
    //     // Create a copy of the merchImageArray
    //     let updatedMerchImageArray = [...merchImageArray];
    //     let updatedUploadFiles = [...uploadfiles]

    //     // Remove the element at the specified index
    //     let splicedImg = updatedMerchImageArray.splice(index, 1);
    //     let splicedUploadFilesImg = updatedUploadFiles.splice(index, 1);

    //     // Update the state with the modified array
    //     setMerchImageArray(updatedMerchImageArray);
    //     setUploadFiles(splicedUploadFilesImg)
    //     setValue()

    //     console.log(splicedImg, 'splicedImg');
    // }

    const deleteImg = (index) => {
        // Create copies of the arrays
        let updatedMerchImageArray = [...merchImageArray];
        let updatedUploadFiles = [...uploadfiles];

        // Remove the element at the specified index from both arrays
        updatedMerchImageArray.splice(index, 1);
        updatedUploadFiles.splice(index, 1);

        // Update the state with the modified arrays
        setMerchImageArray(updatedMerchImageArray);
        setUploadFiles(updatedUploadFiles);
        setValue();

    }


    const onDragEnd = (result) => {
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const newColumnOrder = Array.from(columnOrder);
        const [removed] = newColumnOrder.splice(result.source.index, 1);
        newColumnOrder.splice(result.destination.index, 0, removed);

        // Update the position property based on the new order
        newColumnOrder.forEach((column, index) => {
            column.position = index + 1;
        });

        setColumnOrder(newColumnOrder);
    };

    const itemSizeOnDragEnd = (result) => {
        if (sortSizes === true && result.destination) {


            let tempChosenItems = chosenItems && chosenItems.length && chosenItems.map((item, index) => ({
                ...item,
                position: index
            }))


            if (tempChosenItems && tempChosenItems.length > 0) {

                const newColumnOrder = Array.from(tempChosenItems);

                // Reorder the items in newColumnOrder based on the drag and drop
                const [removed] = newColumnOrder.splice(result.source.index, 1);
                newColumnOrder.splice(result.destination.index, 0, removed);


                // Update the position property based on the new order
                const updatedColumnOrder = newColumnOrder.map((column, index) => {
                    if (column && column.position != null) { // Check if position is not null or undefined
                        // Create a new object to avoid modifying the original object
                        return { ...column, position: index }; // Update position to match the index
                    }
                    return column;
                });

                // Update the merchImageArray with the new columnOrder
                setChosenItems(updatedColumnOrder);

                // Map over chosenItems and extract itemsize_id, name, and position fields
                const updatedItemsizeIds = updatedColumnOrder.map(({ itemsize_id, name, position }) => ({
                    id: itemsize_id,
                    name: name,
                    position: position
                }));

                // Find the field in the formField array with the name "itemsize_ids"
                const itemsizeField = formFields.find(field => field.name === "itemsize_ids");

                // If the field is found, update its value with the updatedItemsizeIds array
                if (itemsizeField) {
                    itemsizeField.value = updatedItemsizeIds;
                }

            }

        }

    };

    const containerStyle = {
        display: 'flex',
        overflowX: 'auto',
        padding: '5px',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0f0f0',
        },
        '&::-webkit-scrollbar-corner': {
            backgroundColor: '#fff',
        },
    };

    const columnStyle = {
        border: '1px solid darkgrey',
        padding: '3px',
        margin: '3px',
        minWidth: '200px',
        height: '125px',
        boxShadow: '5px 5px 10px 5px #888888',
        marginRight: '10px',
    };

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Add Products"
                    Breadcrumb={[{ name: "Add Products" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2>Add Products</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <form className="ng-untouched ng-dirty ng-invalid">
                                <div className="row p-10">
                                    {
                                        formFields && formFields.map((fields, index) => {
                                            return (
                                                <div className={(fields.class == "hide") ? "col-md-6 hide" : (fields.name == "reward") ? "col-md-12" : "col-md-6"}>
                                                    <div className="form-group">
                                                        <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}
                                                        </label>
                                                        {(fields?.input == "text") ?
                                                            <input
                                                                className={`form-control ` + fields?.class}
                                                                value={fields?.value}
                                                                name={fields?.type}
                                                                required=""
                                                                onChange={(e) => { FieldChange(e, index) }}
                                                            />
                                                            : (fields?.input == "textarea") ?
                                                                <textarea
                                                                    className="form-control"
                                                                    value={fields?.value}
                                                                    cols="30"
                                                                    name="textarea"
                                                                    required=""
                                                                    rows="2"
                                                                    onChange={(e) => {
                                                                        FieldChange(e, index)
                                                                    }}
                                                                ></textarea>
                                                                : (fields?.input == "dropdown") ?
                                                                    <>
                                                                        &nbsp;&nbsp;{(fields?.options == "itemsize" && itemSizeOptions.length > 0) ?
                                                                            <>
                                                                                <Button className="btn btn-primary btn-sm" onClick={() => {
                                                                                    setSortSizes(true)
                                                                                }}>Sort Sizes</Button>
                                                                                {sortSizes === true ?
                                                                                    <>
                                                                                        <button type="button" class="btn btn-outline-danger btn-sm ml-2" onClick={() => {
                                                                                            setSortSizes(false)
                                                                                        }}>Close</button>
                                                                                    </>
                                                                                    : ''}
                                                                            </>
                                                                            : ''}
                                                                        <Select
                                                                            className="js-states"
                                                                            placeholder=""
                                                                            options={(fields?.options == "status") ? statusDropdown : (fields?.options == "subcategory" && subcategoryOptions.length > 0) ? subcategoryOptions : (fields?.options == "category" && categoryOptions.length > 0) ? categoryOptions : (fields?.options == "itemsize" && itemSizeOptions.length > 0) ? itemSizeOptions : (fields?.options == "defaultsize" && defaultSizeOptions.length > 0) ? defaultSizeOptions : []}
                                                                            values={(fields.value) ? fields?.value : []}
                                                                            disabled={false}
                                                                            multi={(fields.multiple) ? fields.multiple : false}
                                                                            dropdownHandle={true}
                                                                            searchable={true}
                                                                            searchBy={"name"}
                                                                            labelField={"name"}
                                                                            valueField={"id"}
                                                                            onChange={(value) => { DropdownChange(value, index, fields?.options); }}
                                                                        />
                                                                        {(fields?.dynamic_data && fields?.dynamic_data == true) ?
                                                                            <>
                                                                                {sortSizes === true ?
                                                                                    <Card className="mt-2 mb-2" style={{ width: '475px' }}>
                                                                                        <DragDropContext onDragEnd={itemSizeOnDragEnd}>
                                                                                            <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                                                                                                {(provided) => (
                                                                                                    <div ref={provided.innerRef} {...provided.droppableProps} style={containerStyle}>
                                                                                                        {chosenItems.map((column, index) => (
                                                                                                            <Draggable key={column.position} draggableId={String(column.position)} index={index}>
                                                                                                                {(provided) => (
                                                                                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                        {/* <div style={{ border: '1px solid #ccc', padding: '8px', margin: '8px', minWidth: '200px', backgroundColor: '#f0f0f0' }}> */}
                                                                                                                        {/* <Card style={itemSizeColumnStyle}>
                                                                                                                            <div className="col-md-12">
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-md-9">
                                                                                                                                        <p style={{ fontWeight: '500', fontSize: '13px' }}>{column.name}</p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                                                        <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Card> */}
                                                                                                                        <Tag key={index} color="cyan" style={{ fontSize: '15px' }}>{column.name}</Tag>
                                                                                                                        {/* </div> */}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Draggable>
                                                                                                        ))}
                                                                                                        {provided.placeholder}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Droppable>
                                                                                        </DragDropContext>
                                                                                    </Card> : ''}
                                                                                <TableContainer component={Paper}>
                                                                                    <Table sx={{ minWidth: 400 }} stickyHeader>
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <StyledTableCell className="table-header-column-name">
                                                                                                    Item Size
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell className={(rewardhide) ? "table-header-column-name hide" : "table-header-column-name"}>
                                                                                                    Item Price
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell className={(rewardhide) ? "table-header-column-name" : "table-header-column-name hide"}>
                                                                                                    Reward Points
                                                                                                </StyledTableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {chosenItems && chosenItems.map((items, index) => {
                                                                                                return (items && items.name) ?
                                                                                                    <StyledTableRow>
                                                                                                        <StyledTableCell align="center">
                                                                                                            {items?.name}
                                                                                                        </StyledTableCell>
                                                                                                        <StyledTableCell align="center" className={(rewardhide) ? "hide" : ""}>
                                                                                                            <input type="text" name="itemprice" value={items.price}
                                                                                                                onChange={(e) => {
                                                                                                                    changePrice(e, index)
                                                                                                                }} class="form-control" />
                                                                                                        </StyledTableCell>
                                                                                                        <StyledTableCell align="center" className={(rewardhide) ? "" : "hide"}>
                                                                                                            <input type="text" name="points" value={items.points}
                                                                                                                onChange={(e) => {
                                                                                                                    changeReward(e, index)
                                                                                                                }} class="form-control" />
                                                                                                        </StyledTableCell>
                                                                                                    </StyledTableRow>
                                                                                                    : ''
                                                                                            })}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                                <div className={(priceerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The {(rewardhide) ? "points" : "price"} field is required.</div>
                                                                            </>
                                                                            : ''
                                                                        }
                                                                    </>
                                                                    : (fields?.input == "checkbox") ?
                                                                        <>&nbsp;
                                                                            <Tooltip title={fields?.placeHolder}>
                                                                                <span className="icon-question"></span>
                                                                            </Tooltip><br />
                                                                            <label class="fancy-checkbox">
                                                                                <input type="checkbox" className={fields?.class}
                                                                                    value={fields?.value}
                                                                                    name={fields?.type}
                                                                                    required=""
                                                                                    onChange={(e) => { CheckOrRadioChange(((fields?.value == 1) ? 0 : 1), index) }} /><span></span>
                                                                            </label>
                                                                        </>
                                                                        : (fields?.input == "radio") ?
                                                                            <><br />
                                                                                {fields?.options && fields.options.map((item) => {
                                                                                    return <label class="fancy-radio">
                                                                                        <input type="radio" className={fields?.class}
                                                                                            value={item?.value}
                                                                                            name={fields?.name}
                                                                                            checked={(item?.value == fields?.value) ? "checked" : ""}
                                                                                            onChange={(e) => { CheckOrRadioChange(item?.value, index) }} /><span><i></i>{item.option}</span>
                                                                                    </label>
                                                                                })}

                                                                            </>
                                                                            : (fields?.input == "file") ?
                                                                                <>&nbsp;
                                                                                    <Tooltip title={fields?.placeHolder}>
                                                                                        <span className="icon-question"></span>
                                                                                    </Tooltip><br />
                                                                                    <section className="row container">
                                                                                        <div className="col-md-8">
                                                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                                                <input {...getInputProps()}
                                                                                                    name={fields?.name} />
                                                                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                            </div></div>
                                                                                        {categoryId === 5 ?
                                                                                            // <div className="col-md-12">
                                                                                            //     <div className="thumbcontainer">{(thumbsimage && thumbsimage.length > 0 ? thumbsimage : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                            //         <div className="dropzone_thumb">
                                                                                            //             <img
                                                                                            //                 src={fields?.value}
                                                                                            //                 className="dropzone_img"
                                                                                            //             />
                                                                                            //         </div>
                                                                                            //     </div> : '')}</div>
                                                                                            // </div> 
                                                                                            <div className="col-md-12">
                                                                                                <div className="thumbcontainer">{(thumbsimage && thumbsimage.length > 0 ? thumbsimage :
                                                                                                    // (fields?.value != '') ?
                                                                                                    //  <div className="dropzone_thum_img">
                                                                                                    //             <div className="dropzone_thumb">
                                                                                                    //                 <img
                                                                                                    //                     src={fields?.value}
                                                                                                    //                     className="dropzone_img"
                                                                                                    //                 />
                                                                                                    //             </div>
                                                                                                    //         </div> : '')}</div>
                                                                                                    //     <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : 
                                                                                                    '')}

                                                                                                    {
                                                                                                        merchImageArray.map((imgs, index) => {
                                                                                                            return (imgs != '') ? <div className="dropzone_thum_img">
                                                                                                                <a href="javascript:void(0);" className="close_img" onClick={(e) => { deleteImg(index) }}><i className="error fa fa-times"></i></a>
                                                                                                                <div className="dropzone_thumb">
                                                                                                                    <img
                                                                                                                        src={imgs.preview}
                                                                                                                        className="dropzone_img"
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <Radio.Group onChange={onChange} value={value}>
                                                                                                                    <Radio value={index}>Set as Default</Radio>
                                                                                                                </Radio.Group>
                                                                                                            </div>
                                                                                                                // <div className="row">
                                                                                                                //     <div className="col-md-12">
                                                                                                                //         <div className="dropzone_thum_img">
                                                                                                                //             {/* <Radio.Group onChange={onChange} value={value}>
                                                                                                                //     <Radio value={0}></Radio>
                                                                                                                // </Radio.Group><span style={{fontSize:'10px'}}>Set as Defualt</span><br></br> */}
                                                                                                                //             <a href="javascript:void(0);" className="close_img" onClick={(e) => { deleteImg(imgs, index) }}><i className="error fa fa-times"></i></a>
                                                                                                                //             <div className="dropzone_thumb">
                                                                                                                //                 <img
                                                                                                                //                     src={imgs}
                                                                                                                //                     className="dropzone_img"
                                                                                                                //                 />
                                                                                                                //             </div>
                                                                                                                //         </div><br />
                                                                                                                //         <Radio.Group onChange={onChange} value={value}>
                                                                                                                //             <Radio value={index + 1}>Set as Default</Radio>
                                                                                                                //         </Radio.Group>

                                                                                                                //     </div>
                                                                                                                // </div>
                                                                                                                : ''
                                                                                                        })}</div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="col-md-4">


                                                                                                <div className="thumbcontainer">{(fileUpload ? <div className="dropzone_thum_img">
                                                                                                    <div className="dropzone_thumb">
                                                                                                        <img
                                                                                                            src={fileUpload}
                                                                                                            className="dropzone_img"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div> : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                                    <div className="dropzone_thumb">
                                                                                                        <img
                                                                                                            src={fields?.value}
                                                                                                            className="dropzone_img"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div> : '')}</div>
                                                                                            </div>}
                                                                                        {fileRejectionItems}
                                                                                        <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>{`The image field and ${categoryId === 5 ? `(Set as default)` : ''} is required.`}</div>
                                                                                    </section>
                                                                                </>
                                                                                : ''

                                                        }
                                                        {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        (custom_menu == 1) ?
                                            <>
                                                <div className="row col-md-12">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Custom Menus <span style={{ color: "red" }}>*</span></label>
                                                            <Select
                                                                className="js-states"
                                                                placeholder=""
                                                                options={customMenuOptions}
                                                                values={[]}
                                                                disabled={false}
                                                                multi={true}
                                                                dropdownHandle={true}
                                                                searchable={true}
                                                                searchBy={"name"}
                                                                labelField={"name"}
                                                                valueField={"id"}
                                                                onChange={(value) => { DropdownCustom(value); }}


                                                            />
                                                            <div className={(custommenuserror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>Please select custom menus.</div>

                                                        </div>

                                                    </div>
                                                    {(hybridProduct == true) ?
                                                        <div className="col-md-6">
                                                            <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setshowCustommenuCount(true) }}>Set Custom Menus Hybrid (Min & Max / Weight)</button>
                                                            </div>
                                                        </div> :
                                                        is_weight === '0' ?
                                                            <div className="col-md-6">
                                                                <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setshowCustommenuCount(true) }}>Set Custom Menu's Count</button>
                                                                </div>
                                                            </div> :
                                                            (is_weight === '1' && subId == 4) ?
                                                                <div className="col-md-6">
                                                                    <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setshowCustommenuCount(true) }}>Set Custom Menu's Weight</button>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                    <div className="col-md-12">
                                                        <div className="form-group"><label className="col-md-12">Custom Items</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setshowCustommenuItem(true) }}>Select Custom Items</button>
                                                        </div>
                                                    </div>
                                                    <div className={(customerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>Please select custom items.</div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            {selectedDatas.length > 0 && selectedDatas.map((item) => (
                                                                <span id="submit_btn" className="badge badge-info" onClick={(e) => { e.preventDefault(); }}>{item.item_name}</span>
                                                            ))}
                                                        </div>
                                                    </div>

                                                </div>

                                                <Modal size="xl" show={showCustommenuCount} backdrop="static">
                                                    <Modal.Header>
                                                        <Modal.Title>{(hybridProduct == true) ? "Set Custom Menus Hybrid (Min & Max / Weight)" : (is_weight === '1' && subId == 4) ? "Set Custom Menus Weight" : "Set Custom Menus Count"} </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="row">
                                                            {/* onClick={toggleChecked} */}
                                                            {Number(subId) == 4 ?
                                                                <div className="m-b30 col-md-7 col-sm-8">
                                                                    <h6>Choose Product Size<span className="error">*</span></h6>
                                                                    <div className="btn-group product-item-size" data-toggle="buttons" style={{ display: 'block' }}>
                                                                        {
                                                                            chosenItems && chosenItems.map((row, tindex) => (
                                                                                <label className={(row.itemsize_id == '') ? 'btn active' : 'btn'} >
                                                                                    <input name="options" id="option7" type="radio" value={row.itemsize_id} checked={row.itemsize_id == defaultItemsize} onClick={toggleChecked} /> {row?.name}
                                                                                </label>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div> : ''}
                                                        </div>
                                                        <div className={(minmaxerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>{formerrormsg}</div>
                                                        {
                                                            (hybridProduct) ?
                                                                <>
                                                                    <div className="col-md-8"><Alert message="Both Toppings and Combinations will have the same combined values for Hybrid." type="warning" showIcon /></div>
                                                                    <TableContainer component={Paper}>
                                                                        <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <StyledTableCell align="">
                                                                                        Custom Menus
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        Min
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        Max
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        Weight (Hybrid)
                                                                                    </StyledTableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {defaultItemsize > 0 && customItemSize && customItemSize.length > 0 && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()) && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()) !== undefined && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts.length > 0 && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts.map((row, index) => {
                                                                                    return (
                                                                                        <StyledTableRow>
                                                                                            <StyledTableCell align="">
                                                                                                {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ? <span>{row?.customDatas.name}<br />
                                                                                                    <span style={{ fontWeight: '600', fontSize: '12px' }}>(Combined Hybrid)</span></span>
                                                                                                    : (custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?
                                                                                                        <span>{row?.customDatas.name}<br />
                                                                                                            <span style={{ fontWeight: '600', fontSize: '12px' }}>(Hybrid)</span></span>
                                                                                                        : row?.customDatas.name
                                                                                                }

                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell align="">
                                                                                                {(custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?
                                                                                                    '-' :
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.customDatas.min}
                                                                                                        name="min"
                                                                                                        type="number"
                                                                                                        max={row?.customDatas.match_count}
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMaxCustom(e, index, "min") }}
                                                                                                    />}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell align="">
                                                                                                {(custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?
                                                                                                    // {/* {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ? */ }
                                                                                                    '-' :
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.customDatas.max}
                                                                                                        name="max"
                                                                                                        type="number"
                                                                                                        max={row?.customDatas.match_count}
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMaxCustom(e, index, "max") }}
                                                                                                    />}
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell align="">
                                                                                                {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ?
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.customDatas.max}
                                                                                                        // value={row?.customDatas.max}
                                                                                                        name="max"
                                                                                                        type="number"
                                                                                                        max={row?.customDatas.match_count}
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMaxCustom(e, index, "max", "hybrid") }}
                                                                                                    />
                                                                                                    :
                                                                                                    (custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?

                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.max}
                                                                                                            name="max"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "max") }}
                                                                                                        /> : '-'}
                                                                                            </StyledTableCell>
                                                                                        </StyledTableRow>
                                                                                    )
                                                                                }
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </> :
                                                                (is_weight === '1' && subId == 4) ?
                                                                    <TableContainer component={Paper}>
                                                                        <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <StyledTableCell align="">
                                                                                        Size
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        Weight Value
                                                                                    </StyledTableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {defaultItemsize > 0 && customenuCountWeight && customenuCountWeight.length > 0 && customenuCountWeight.find((item) => item.itemsize_id == defaultItemsize.toString()) && customenuCountWeight.map((row, index) => {
                                                                                    if (row.itemsize_id == defaultItemsize) {
                                                                                        return (
                                                                                            <StyledTableRow>
                                                                                                <StyledTableCell align="">
                                                                                                    {row?.itemsize_name}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.weight_value}
                                                                                                        name="weight"
                                                                                                        type="number"
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMaxWeight(e, index, row?.itemsize_name) }}
                                                                                                    />
                                                                                                </StyledTableCell>
                                                                                            </StyledTableRow>
                                                                                        )
                                                                                    }
                                                                                }
                                                                                )
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer> :
                                                                    subId == 4 ?
                                                                        <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <StyledTableCell align="">
                                                                                            Menu
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                            Min
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                            Max
                                                                                        </StyledTableCell>
                                                                                    </TableRow>
                                                                                </TableHead>

                                                                                <TableBody>
                                                                                    {defaultItemsize > 0 && customItemSize && customItemSize.length && customItemSize.find((item) => item.sizeID == defaultItemsize).customMenuCounts && customItemSize.find((item) => item.sizeID == defaultItemsize).customMenuCounts.length > 0 && customItemSize.find((item) => item.sizeID == defaultItemsize).customMenuCounts.map((row, index) => {
                                                                                        return (
                                                                                            <StyledTableRow>
                                                                                                <StyledTableCell align="">
                                                                                                    {row?.customDatas.name}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.customDatas.min}
                                                                                                        name="min"
                                                                                                        type="number"
                                                                                                        max={row?.customDatas.match_count}
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMaxCustom(e, index, "min") }}
                                                                                                    />
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.customDatas.max}
                                                                                                        name="max"
                                                                                                        type="number"
                                                                                                        max={row?.customDatas.match_count}
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMaxCustom(e, index, "max") }}
                                                                                                    />
                                                                                                </StyledTableCell>
                                                                                            </StyledTableRow>
                                                                                        )
                                                                                    }
                                                                                    )}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                        :
                                                                        <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <StyledTableCell align="">
                                                                                            Menu
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                            Min
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                            Max
                                                                                        </StyledTableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        customenuCountDatas && customenuCountDatas.map((row, index) => (
                                                                                            <StyledTableRow>
                                                                                                <StyledTableCell align="">
                                                                                                    {row?.name}
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.min}
                                                                                                        name="min"
                                                                                                        type="number"
                                                                                                        max={row?.match_count}
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMax(e, index, "min") }}
                                                                                                    />
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        value={row?.max}
                                                                                                        name="max"
                                                                                                        type="number"
                                                                                                        max={row?.match_count}
                                                                                                        required=""
                                                                                                        onChange={(e) => { changeMinMax(e, index, "max") }}
                                                                                                    />
                                                                                                </StyledTableCell>
                                                                                            </StyledTableRow>
                                                                                        ))
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                        }
                                                    </Modal.Body>

                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={onCloseCustommenuCount}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={onSaveCustommenuCount}>
                                                            Save
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>

                                                <Modal size="xl" show={showCustommenuItem} backdrop="static">
                                                    <Modal.Header>
                                                        <Modal.Title>Set Custom Menus Items</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <b>{is_weight === '1' ? `Product Weight ${categoryId === 4 ? '(Default Product Size)' : ''} : ${weightValue}` : ''}</b>
                                                        <ToastContainer
                                                            position="top-right" // Position the toast container above the modal
                                                            autoClose={5000} // Adjust as needed
                                                            hideProgressBar={false}
                                                            newestOnTop={false}
                                                            closeOnClick
                                                            rtl={false}
                                                            pauseOnFocusLoss
                                                            draggable
                                                            pauseOnHover
                                                        />
                                                        <Tabs defaultActiveKey="0">
                                                            {
                                                                custommenuDatasbyID && custommenuDatasbyID.map((row, tindex) => (
                                                                    <Tabs.TabPane tab={TabName(row)} key={tindex}>
                                                                        <div className="row col-md-12 p-5" style={{ height: '500px', overflowY: 'scroll' }}>
                                                                            <label class="fancy-checkbox">
                                                                                <input type="checkbox" className=""
                                                                                    value=""
                                                                                    name=""
                                                                                    required=""
                                                                                    checked={(typeof (selectallitem[tindex]) === "undefined") ? false : selectallitem[tindex]}
                                                                                    onChange={(e) => selectAll(row, tindex)}
                                                                                /><span>Select All Items</span>
                                                                            </label>
                                                                            {row?.custom_menu_items.length > 0 && row?.custom_menu_items.map((item, rindex) => (
                                                                                <div className="col-md-3">
                                                                                    <div className="card card-border text-center">
                                                                                        <label class="fancy-checkbox float-right" style={{ position: "absolute", left: "85%", top: "2%" }}>
                                                                                            <input type="checkbox" className=""
                                                                                                value=""
                                                                                                name={rindex}
                                                                                                checked={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? false : true}
                                                                                                //checked={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? false : true}
                                                                                                required=""
                                                                                                onClick={(e) => selectItems(row, tindex, rindex, item)}
                                                                                            // (rowcheckbox[tindex][rindex] == true) ? true :
                                                                                            /><span></span>
                                                                                        </label>
                                                                                        <label for={rindex}>
                                                                                            <img src={assestURL + item?.image}
                                                                                                onClick={(e) => selectItems(row, tindex, rindex, item)} style={{ width: "auto", height: "125px", marginTop: "10px" }} class="card-img-top" alt="..." />
                                                                                        </label>
                                                                                        <div className="card-body">
                                                                                            <h5 class="card-title">{item?.name}</h5>
                                                                                            <p className="card-text">{item?.price}</p>
                                                                                            <p className="card-text" style={{ fontSize: '12px' }}>Weight :{item?.weight}</p>
                                                                                            <div>
                                                                                                <table>
                                                                                                    {chosenItems && chosenItems.map((items, ciindex) => (
                                                                                                        <tr>
                                                                                                            <td style={{ width: "20%" }}>
                                                                                                                <label>{items?.name}</label>
                                                                                                            </td>
                                                                                                            <td style={{ width: "80%" }} className={(rewardhide) ? "hide" : ""}>
                                                                                                                <input type="text"
                                                                                                                    className="form-control"
                                                                                                                    value={ItemPrice(item?.price, (_.indexOf(rowcheckbox[tindex], rindex) < 0), ciindex, item)}
                                                                                                                    name="priceItem"
                                                                                                                    onChange={(e) => ChangeItemPrice(e, ciindex, item)}
                                                                                                                    disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false}
                                                                                                                //disabled={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? true : false} 
                                                                                                                />
                                                                                                                {/* //(rowcheckbox[tindex][rindex] == true) ? false : */}
                                                                                                            </td>
                                                                                                            <td style={{ width: "80%" }} className={(rewardhide) ? "" : "hide"}>
                                                                                                                <input type="text"
                                                                                                                    className="form-control"
                                                                                                                    value={ItemPoints(0, (_.indexOf(rowcheckbox[tindex], rindex) < 0), ciindex, item)}
                                                                                                                    name="priceItem"
                                                                                                                    onChange={(e) => ChangeItemPoints(e, ciindex, item)}
                                                                                                                    disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false}
                                                                                                                //disabled={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? true : false} 
                                                                                                                />
                                                                                                                {/* //(rowcheckbox[tindex][rindex] == true) ? false : */}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </table>
                                                                                            </div><br />
                                                                                            <label class="fancy-checkbox">
                                                                                                <input type="checkbox" className=""
                                                                                                    value=""
                                                                                                    name={"default-" + rindex}
                                                                                                    required=""
                                                                                                    // checked={(_.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id }) >= 0) ? true : false}
                                                                                                    checked={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id, default: 1 }) >= 0) ? true : false}
                                                                                                    onChange={(e) => selectDefault(row, tindex, rindex, item)}
                                                                                                    //disabled={(_.indexOf(rowcheckbox[tindex], rindex) < 0) ? true : false}
                                                                                                    disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false}
                                                                                                /><span>Set Default</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <img src={assestURL + item?.image} style={{ width: "auto", height: "125px" }} />
                                                                                    {chosenItems && chosenItems.map((items, index) => (
                                                                                        <>
                                                                                            <div> {items?.name}</div>
                                                                                            <div> {item?.price}</div>
                                                                                        </>
                                                                                    ))}
                                                                                    <div>{item?.name}</div>
                                                                                    <div>{item?.price}</div> */}

                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Tabs.TabPane>
                                                                ))

                                                            }
                                                        </Tabs>
                                                    </Modal.Body>

                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={onCloseCustommenuItem}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" onClick={onSaveCustommenuItem}>
                                                            Save
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </>
                                            : ""
                                    }
                                    <Divider />
                                    <label htmlFor="">Kitchen Code<span style={{ color: 'red' }}>*</span></label>
                                    <Card className="mt-2 ml-4 mb-2" style={{ width: '95%' }}>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps} style={containerStyle}>
                                                        {columnOrder.map((column, index) => (
                                                            <Draggable key={column.position} draggableId={String(column.position)} index={index}>
                                                                {(provided) => (
                                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        {/* <div style={{ border: '1px solid #ccc', padding: '8px', margin: '8px', minWidth: '200px', backgroundColor: '#f0f0f0' }}> */}
                                                                        <Card style={columnStyle}>
                                                                            <div className="col-md-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-9">
                                                                                        <p style={{ fontWeight: '900', fontSize: '15px' }}>{column.name}</p>
                                                                                    </div>
                                                                                    <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Card>
                                                                        {/* </div> */}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Card>
                                    <Divider />
                                    <label htmlFor="">Sample Kitchen Code Output&nbsp;
                                        <Tooltip title={"Sample Output displays only Default custom menu items"}>
                                            <span className="icon-question"></span>
                                        </Tooltip><br /></label>
                                    <Card className="mt-2 ml-4 mb-2" style={{ width: '95%' }}>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <p style={{ fontWeight: '900', fontSize: '15px' }}>{sampleCodeOutput}</p>
                                            </div>
                                        </div>
                                    </Card>
                                    <button id="submit_btn" className="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitCategory(e); }}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size="lg" show={showPreview} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {/* <button onClick={zoomIn}>Zoom in</button>
                        <button onClick={zoomOut}>Zoom out</button> */}
                        {imgSRC && (
                            <ReactCrop
                                crop={crop}
                                //onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onChange={c => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={false}
                                maxHeight={maxProductHeight}
                                maxWidth={maxProductWidth}
                                minWidth={maxProductWidth}
                                minHeight={maxProductHeight}
                                locked={true}
                                style={{ overflow: "scroll", height: "400px", width: imgSRCwidth }}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSRC}
                                    style={{ transform: `scale(${scale})`, width: imgSRCwidth, height: imgSRCheight, maxWidth: "unset" }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}

                        {!!completedCrop && (
                            <>
                                <div style={{ display: "none" }}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>)}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={cropImg}>
                        Crop
                    </Button>
                    <Button variant="primary" onClick={Imgmodalclose} >
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddProducts;
