import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboardCard, dashboardRecentProduct, dashboardPieChart, dashboardSaleReport, dashboardmonthlyReport } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "dashboard";

const initialState = {
    loading: "initial",
    addloading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    dashboardDatas: null,
    dashboardCount: 0,
    dashboardDataById: null,
    dashboardRecentDatas: null,
    pieChartDatas: null,
    salesReportDatas: null,
    pieChartloading: "initial",
    saleChartloading: "initial",
    monthlyReportDatas: null,
    monthlyloading: "initial",
    recentloading: "initial"
};

export const dashboardData = createAsyncThunk(
    `${namespace}/dashboardData`,
    async ({ query = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await dashboardCard(query);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const dashboardrecentProducts = createAsyncThunk(
    `${namespace}/dashboardrecentProducts`,
    async ({ query = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await dashboardRecentProduct(query);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const dashboardPieCharts = createAsyncThunk(
    `${namespace}/dashboardPieCharts`,
    async ({ query = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await dashboardPieChart(query);
            console.log("getCount-->pie ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const dashboardmonthlyReports = createAsyncThunk(
    `${namespace}/dashboardmonthlyReports`,
    async ({ date }, { rejectWithValue, dispatch }) => {
        try {
            console.log(date, "interdata--date");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await dashboardmonthlyReport({ date });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const dashboardsalesReports = createAsyncThunk(
    `${namespace}/dashboardsalesReports`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await dashboardSaleReport();
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const dashboardSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [dashboardData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [dashboardData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.dashboardCount = payload?.data?.count;
            // state.dashboardDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload===")
            state.dashboardDatas = payloaddatas;
        },
        [dashboardData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },

        [dashboardrecentProducts.pending](state) {
            state.recentloading = API_STATUS.PENDING;
        },
        [dashboardrecentProducts.fulfilled](state, { payload }) {
            state.recentloading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload")
            state.dashboardRecentDatas = payloaddatas?.data;
        },
        [dashboardrecentProducts.rejected](state, action) {
            state.recentloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },

        [dashboardPieCharts.pending](state) {
            state.pieChartloading = API_STATUS.PENDING;
        },
        [dashboardPieCharts.fulfilled](state, { payload }) {
            state.pieChartloading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload--")
            state.pieChartDatas = payloaddatas
        },
        [dashboardPieCharts.rejected](state, action) {
            state.pieChartloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [dashboardsalesReports.pending](state) {
            state.saleChartloading = API_STATUS.PENDING;
        },
        [dashboardsalesReports.fulfilled](state, { payload }) {
            state.saleChartloading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload--")
            state.salesReportDatas = payloaddatas
        },
        [dashboardsalesReports.rejected](state, action) {
            state.saleChartloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [dashboardmonthlyReports.pending](state) {
            state.monthlyloading = API_STATUS.PENDING;
        },
        [dashboardmonthlyReports.fulfilled](state, { payload }) {
            state.monthlyloading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload")
            state.monthlyReportDatas = payloaddatas?.monthlyReport;
        },
        [dashboardmonthlyReports.rejected](state, action) {
            state.monthlyloading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },

    },
});

export const { clearData, clearErrormsg } = dashboardSlice.actions;

export const dashboardSelector = (state) => state.dashboard;

export default dashboardSlice.reducer;
