import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const tryLogin = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/signin", postData);
};
export const forgotPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/forgot-password", postData);
};
export const resetPassword = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/auth/reset-password", postData);
};

/* Category*/
export const categoryList = (query, page, limit, sortby, order, status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/category?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&status=" + status, Http.getAuthToken());
};
export const categoryAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    console.log(baseUrl, "baseUrl")
    return Http.post(baseUrl + "/category/add_category", postData, Http.getAuthToken());
};
export const categoryUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/category/" + updateID, postData, Http.getAuthToken());
};
export const categoryDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/category/" + deleteID, Http.getAuthToken());
};

/* Sub Category*/
export const subcategoryList = (query, page, limit, sortby, order, status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/subcategory?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&status=" + status, Http.getAuthToken());
};
export const subcategoryAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/subcategory/add_subcategory", postData, Http.getAuthToken());
};
export const subcategoryUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/subcategory/" + updateID, postData, Http.getAuthToken());
};
export const subcategoryDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/subcategory/" + deleteID, Http.getAuthToken());
};
export const subcategoryFilter = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/subcategory/filter", postData, Http.getAuthToken());
};

/* Itemsize */
export const itemsizeList = (query, page, limit, sortby, order, status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/itemsize?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&status=" + status, Http.getAuthToken());
};
export const itemsizeAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/itemsize/add_itemsize", postData, Http.getAuthToken());
};
export const itemsizeUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/itemsize/" + updateID, postData, Http.getAuthToken());
};
export const itemsizeDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/itemsize/" + deleteID, Http.getAuthToken());
};


/* Itemsize */
export const productsList = (query, page, limit, sortby, order, reward, status, category_id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/products?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&reward=" + reward + "&status=" + status + "&category_id=" + category_id, Http.getAuthToken());
};
export const productsAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/products/add_product/", postData, Http.getAuthToken());
};

export const productsAddMerchImages = (tempMerchArray) => {
    const baseUrl = getBaseEndpointUrl();
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(baseUrl + "/products/addmerchImages", tempMerchArray, options);
};

export const productsUpdateMerchImages = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(baseUrl + "/products/editmerchimages", postData, options);
};

export const productsAddMultipleImage = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/addmerchImages", postData, Http.getAuthToken());
};

export const productsUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/products/" + updateID, postData, Http.getAuthToken());
};
export const productsDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/products/" + deleteID, Http.getAuthToken());
};
export const productsListById = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/products/" + id, Http.getAuthToken());
};
export const productsCustomMenus = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/products/custommenu/" + id, Http.getAuthToken());
};
export const productsBulkDelete = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/products/bulkdelete", postData, Http.getAuthToken());
};
export const productsBulkStatus = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/products/bulkstatus", postData, Http.getAuthToken());
};
export const updateSorting = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/products/sortproduct", postData, Http.getAuthToken());
};

/* Customers */
export const customersList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/user/role/2?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const customerUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/user/" + updateID, postData, Http.getAuthToken());
};
export const customerDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/user/" + deleteID, Http.getAuthToken());
};
export const customersBulkDelete = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/bulkdelete", postData, Http.getAuthToken());
};

/* Custom Menu*/
export const custommenuList = (query, page, limit, sortby, order, include, status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/custommenu?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&include=" + include + "&status=" + status, Http.getAuthToken());
};
export const custommenuListById = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/custommenu/listbyId", postData, Http.getAuthToken());
};
export const custommenuAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/custommenu/add_custommenu", postData, Http.getAuthToken());
};
export const custommenuUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/custommenu/" + updateID, postData, Http.getAuthToken());
};
export const custommenuDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/custommenu/" + deleteID, Http.getAuthToken());
};

/* Custom Menu Item*/
export const custommenuitemList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/custommenuitem?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const custommenuitemAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/custommenuitem/add_custommenuitem", postData, Http.getAuthToken());
};
export const custommenuitemUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/custommenuitem/" + updateID, postData, Http.getAuthToken());
};
export const custommenuitemDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/custommenuitem/" + deleteID, Http.getAuthToken());
};
export const custommenuitemFilter = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/custommenuitem/filter", postData, Http.getAuthToken());
};

/* Pos*/
export const posList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/pos", Http.getAuthToken());
};
export const posUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/pos/" + updateID, postData, Http.getAuthToken());
};

/* Banner*/
export const bannerList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/banner?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const bannerAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.post(baseUrl + "/banner/add_banner", postData, options);
};
export const bannerUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    return Http.put(baseUrl + "/banner/" + updateID, postData, options);
};
export const bannerDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/banner/" + deleteID, Http.getAuthToken());
};
export const bannerListById = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/banner/" + id, Http.getAuthToken());
};

export const imgaeUpload = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    const options = Http.getAuthToken()
    options.headers['Content-Type'] = "multipart/form-data";
    console.log(options.headers, "headers")
    return Http.post(baseUrl + "/banner/upload_img", postData, options);
}


/* Notification*/
export const notificationList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/notification?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const notificationAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/notification/add_notification", postData, Http.getAuthToken());
};
export const notificationUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/notification/" + updateID, postData, Http.getAuthToken());
};
export const notificationDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/notification/" + deleteID, Http.getAuthToken());
};

/* AboutUs*/
export const aboutusList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/aboutus?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const aboutusAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/aboutus/add_aboutus", postData, Http.getAuthToken());
};
export const aboutusUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/aboutus/" + updateID, postData, Http.getAuthToken());
};
export const aboutusDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/aboutus/" + deleteID, Http.getAuthToken());
};
export const aboutusListById = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/aboutus/" + id, Http.getAuthToken());
};

/* ContactUs*/
export const contactusList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/contactus?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const contactusUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/contactus/" + updateID, postData, Http.getAuthToken());
};
export const contactusListById = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/contactus/" + id, Http.getAuthToken());
};

/* Giftcard */
export const giftcardList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/adminTransactionList", postData, Http.getAuthToken());
};

export const purchasedGiftCardList = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/adminGiftcardList", postData, Http.getAuthToken());
};

// export const giftcardAdd = (postData) => {
//     const baseUrl = getBaseEndpointUrl();
//     return Http.post(baseUrl + "/giftcard/add_giftcard", postData, Http.getAuthToken());
// };
// export const giftcardUpdate = (postData, updateID) => {
//     const baseUrl = getBaseEndpointUrl();
//     return Http.put(baseUrl + "/giftcard/" + updateID, postData, Http.getAuthToken());
// };
export const giftwalletAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/addtocoupon", postData, Http.getAuthToken());
};
export const giftwalletUpdate = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/user/updateWallet", postData, Http.getAuthToken());
};
// export const giftcardDelete = (deleteID) => {
//     const baseUrl = getBaseEndpointUrl();
//     return Http.delete(baseUrl + "/giftcard/" + deleteID, Http.getAuthToken());
// };

export const giftcardDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + `/user/deletegiftcard/${deleteID}`, Http.getAuthToken());
};

/* Kitchen Section*/
export const kitchensectionList = (query, page, limit, sortby, order, include, status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/kitchensection?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&include=" + include + "&status=" + status, Http.getAuthToken());
};
export const kitchensectionListById = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/kitchensection/listbyId", postData, Http.getAuthToken());
};
export const kitchensectionAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/kitchensection/add_kitchensection", postData, Http.getAuthToken());
};
export const kitchensectionUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/kitchensection/" + updateID, postData, Http.getAuthToken());
};
export const kitchensectionDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/kitchensection/" + deleteID, Http.getAuthToken());
};

/* Kitchen Code*/
export const kitchencodeList = (query, page, limit, sortby, order, include, status) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/kitchencode?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order + "&include=" + include + "&status=" + status, Http.getAuthToken());
};
export const kitchencodeListById = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/kitchencode/listbyId", postData, Http.getAuthToken());
};
export const kitchencodeAdd = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/kitchencode/add_kitchencode", postData, Http.getAuthToken());
};
export const kitchencodeUpdate = (postData, updateID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/kitchencode/" + updateID, postData, Http.getAuthToken());
};
export const kitchencodeDelete = (deleteID) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.delete(baseUrl + "/kitchencode/" + deleteID, Http.getAuthToken());
};

/* Pos*/
export const generalList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/general", Http.getAuthToken());
};
export const generalUpdate = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/general/", postData, Http.getAuthToken());
};

export const printerList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/general/connectedPrinters", Http.getAuthToken());
};

/* Kiosk*/
export const kioskList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/kiosk", Http.getAuthToken());
};
export const kioskUpdate = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.put(baseUrl + "/kiosk/updatekiosk", postData, Http.getAuthToken());
};

/* Dashbaord Card*/
export const dashboardCard = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard?query=" + query, Http.getAuthToken());
};

export const dashboardRecentProduct = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/recent_products?query=" + query, Http.getAuthToken());
};

export const dashboardPieChart = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/pie_chart?query=" + query, Http.getAuthToken());
};

export const dashboardSaleReport = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/dashboard/sales_reports", Http.getAuthToken());
};

export const dashboardmonthlyReport = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/dashboard/monthly_reports", postData, Http.getAuthToken());
};
/* Order */
export const orderList = (query, page, limit, sortby, order) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/order?query=" + query + "&page=" + page + "&limit=" + limit + "&sortby=" + sortby + "&order=" + order, Http.getAuthToken());
};
export const ordersListById = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/order/" + id, Http.getAuthToken());
};
export const ordersKitchenById = (id) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/order/CreateOrderPdf/" + id, Http.getAuthToken());
}

//* Reports *
export const getReportData = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/report/getReports", postData, Http.getAuthToken());
};

export const getTableNames = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/report/getTables", Http.getAuthToken());
};

//* Kitchen Display *
export const getKitchenDisplay = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/kitchendisplay/displaylist", Http.getAuthToken());
};

export const getKitchenDisplayReport = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/kitchendisplay/displayreport", Http.getAuthToken());
};

//* Kitchen Dispatch *
export const getkitchenDispatch = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/kitchendisplay/dispatchlist", Http.getAuthToken());
};

export const updateDelivered = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/kitchendisplay/updatedelivery", postData, Http.getAuthToken());
};

//* Color Picker *

export const getColorList = () => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/colorpicker/getColorList", Http.getAuthToken());
};

export const addSavedcolor = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/colorpicker/addsavedColors", postData, Http.getAuthToken());
};

export const addSelectedcolor = (postData) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.post(baseUrl + "/colorpicker/addselectedColors", postData, Http.getAuthToken());
};

