import Swal from 'sweetalert2';


export default class alertmessage {
    static sweetalert(message) {
        let title = "SESSION EXPIRED..!";
        let text = message;
        // if(message=="UnAuthorized!"){
            if(message=="User Session Expired!"){
            title = "User Session Expired..!";
            text = "Re-Login Again";
        }
        Swal.fire({
            title: title,
            text: "Your session has expired, please click OK to login",
            type: "danger"
        }).then(function () {
            localStorage.clear();
            window.location.reload(true);
        });
    }

}
