import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import PageHeader from "../../view/pageheader";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
// import { Button, Modal } from "react-bootstrap";
import {
    categoryData,
    categorySelector,
} from "../../store/reducer/category";
import {
    subcategoryData,
    subcategoryFilterData,
    subCategorySelector,
    clearSubCatgDatas
} from "../../store/reducer/subcategory";
import {
    itemsizeData,
    itemsizeSelector,
} from "../../store/reducer/itemsize";
import {
    productData,
    AddProduct,
    UpdateProduct,
    UpdateMerchImages,
    DeleteProduct,
    productById,
    productsSelector,
    clearProdUpdateLoading,
    productCustomProducts,
    clearErrormsg
} from "../../store/reducer/products";
import {
    custommenuData,
    custommenuDatabyID,
    custommenuSelector,
} from "../../store/reducer/custommenu";
import { API_STATUS } from "../../utils/constants";
import {
    productFields
} from "../fields/productform";
import Select from "react-dropdown-select";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import SimpleReactValidator from 'simple-react-validator';
import _, { isObject, result } from 'lodash';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { DayTableModel } from "@fullcalendar/core";
import { assestURL } from "../../services/config";
import { Tree, Tabs, Divider, Tooltip, Card, Radio, Input, Tag, Alert, message, Spin } from 'antd';
import Badge from 'react-bootstrap/Badge';
import { Button, Modal, Toast } from "react-bootstrap";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import { forgotPassword } from "../../services/api";
// import {
//     CloseOutlined
// } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { CloseOutlined, Login } from "@mui/icons-material";
import axios from 'axios';


function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
    const ctx = canvas.getContext('2d')
    const TO_RADIANS = Math.PI / 180
    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)


    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
}

function EditProducts() {
    const params = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const simpleValidator = useRef(new SimpleReactValidator())
    const { productDatas, productCount, productDataById, customProductDatas, loading, addloading, deleteloading, updateloading, errorMessage } = useSelector(productsSelector);
    const { categoryDatas, categoryCount } = useSelector(categorySelector);
    const { itemsizeDatas, itemsizeCount } = useSelector(itemsizeSelector);
    const [customItemSize, setcustomItemSize] = useState([]);
    const [defaultItemsize, setDefaultItemsize] = useState(0);
    const { subcategoryDatas, subcategoryCount } = useSelector(subCategorySelector);
    const resetForm = productFields;
    const [formFields, setFormFields] = useState(resetForm);
    const [tempFormFields, setTempFormFields] = useState(resetForm);
    const [existingImage, setExistingImage] = useState([])
    const [editID, setEditID] = useState(params?.id);
    const [editDatas, setEditDatas] = useState([]);
    const [showAdd, setshowAdd] = useState(false);
    const statusDropdown = [{ name: 'Active', id: 1 }, { name: 'In-Active', id: 0 }]
    const [hybridProduct, setHybridProduct] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showvalidator, setShowvalidator] = useState(false);
    const [fileUpload, setfileUpload] = useState('');
    const [filePath, setfilePath] = useState('');
    const [updateID, setUpdateID] = useState('');
    const fileReader = new FileReader();
    const [files, setFiles] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [itemSizeOptions, setItemSizeOptions] = useState([]);
    const [defaultSizeOptions, setDefaultSizeOptions] = useState([]);
    let [chosenItems, setChosenItems] = useState([]);
    const [deleteItems, setdeleteItems] = useState([]);
    const [custom_menu, setCustom_menu] = useState(0);
    const [customMenuOptions, setCustomMenuOptions] = useState([]);
    const { custommenuDatas, custommenuDatasbyID } = useSelector(custommenuSelector);
    const [customenuCountDatas, setcustomenuCountDatas] = useState([]);
    const [customenuCountWeight, setcustomenuCountWeight] = useState([]);
    const [showCustommenuCount, setshowCustommenuCount] = useState(false);
    const [showCustommenuItem, setshowCustommenuItem] = useState(false);
    const [customerror, setcustomerror] = useState(false);
    const [selectedDatas, setselectedDatas] = useState([]);
    const [tempSelectedDatas, setTempSelectedDatas] = useState([])
    const [rowcheckbox, setrowcheckbox] = useState([]);
    let [defaultSelect, setDefaultSelect] = useState([]);
    const [selectedCustomMenu, setSelectedCustomMenu] = useState([]);
    const [prevselectedItem, setprevselectedItem] = useState([]);
    const [deleteCustomMenu, setDeleteCustomMenu] = useState([]);
    const [priceerror, setpriceerror] = useState(false);
    const [selectallitem, setselectallitem] = useState([]);
    const [minmaxerror, setminmaxerror] = useState(false);
    const [rewardhide, setRewardhide] = useState(false);
    const [formerrormsg, setFormErrorMsg] = useState("");
    const [custommenuserror, setcustommenuserror] = useState(false);
    const [subId, setSubId] = useState(0)
    const [fileerror, setfileerror] = useState(false);
    const [filesizeerror, setfilesizeerror] = useState(false);
    const [customWeightTotal, setcustomWeightTotal] = useState(0);
    let [thumbsimage, setThumbsImage] = useState([]);
    const [completedCrop, setCompletedCrop] = useState()
    let [imgSRC, setImgSRC] = useState([]);
    let [imgSRCwidth, setImgSRCwidth] = useState("");
    let [imgSRCheight, setImgSRCheight] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const previewCanvasRef = useRef(null);
    const [filename, setfilename] = useState('');
    const imgRef = useRef(null);
    let [scale, setScale] = useState(1);
    const [is_weight, setIs_Weight] = useState('1')
    const [columnOrder, setColumnOrder] = useState([]);
    const [initialColumns, setInitialColumns] = useState([
        { position: 1, id: uuidv4(), name: 'Quantity', value: 1, custommenu_id: null },
        { position: 2, id: uuidv4(), name: 'Item Size', value: 2, custommenu_id: null },
        { position: 3, id: uuidv4(), name: 'Item Code', value: 3, custommenu_id: null },
    ])
    const [uploadfiles, setUploadFiles] = useState([]);
    const [deletedImgs, setdeletedImgs] = useState([]);
    const [categoryId, setCategoryId] = useState()
    const [merchImageLength, setMerchImageLength] = useState(false)
    const [sampleCodeOutput, setsampleCodeOutput] = useState([])
    const [totalImages, setTotalImages] = useState([])
    const [merchImageValue, setMerchImageValue] = useState()
    const [sortSizes, setSortSizes] = useState(false)
    const [tempChosenItems, setTempChosenItems] = useState([])
    const [weightValue, setWeightValue] = useState()
    const [openModal, setOpenModal] = useState(false)
    const [merchImageArray, setMerchImageArray] = useState([])
    const [value, setValue] = useState();
    const [defaultImageBinary, setDefaultImageBinary] = useState(null); // State to store the binary data of the default image
    const [existingCustomMenuItemWeight, setIsExistingCustomMenuItemWeight] = useState(0)
    let countVariable = 1
    let updatedFormFields;

    let values = ['mini', 'small', 'large']
    let imageField;

    const maxProductWidth = 500;
    const maxProductHeight = 600;

    const buildData = (datas) => {
        return _.orderBy(datas, ['order_no'], ['asc']);
    };

    const weightError = () => {
        toast.dismiss();
        toast.error('Maximum Weight limit exceeded for this Product!.', { closeButton: false });
    };

    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        x: 0,
        y: 0,
        locked: true,
        width: maxProductWidth,
        height: maxProductHeight
    })

    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = {
            unit: 'px', // Can be 'px' or '%'
            x: 0,
            y: 0,
            locked: true,
            width: maxProductWidth,
            height: maxProductHeight
        }

        setCrop(crop)
        setCompletedCrop(crop)
    }

    console.log(customProductDatas, 'customProductDatas==');
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const FieldChange = (e, index) => {
        console.log(e.target.value, 'eeee');
        if (formFields[index].name == "weight") {
            selectedDatas && selectedDatas.map((item) => {
                item.default = 0
            })
            setIsExistingCustomMenuItemWeight(0)
            const re = /^\d*\.?\d*$/
            if (!e.target.value.match(re) && e.target.value != "") {
                return false;
            }
            setDefaultSelect([])
        }
        formFields[index].value = e.target.value;
        setFormFields([...formFields]);
        setTempFormFields([...tempFormFields])
    };

    const setCustomMenus = (e) => {
        e.preventDefault();


        setshowCustommenuCount(true)
    }

    const CheckOrRadioChange = (value, index) => {
        if (formFields[index].name == "custom_menus")
            setCustom_menu(value);
        if (formFields[index].name == "uom" && value == "0" && subId == 4) {
            setIs_Weight('1')
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "hide";
                formFields[get_index].validation = "";
            }
        }
        else if (formFields[index].name == "uom" && value == "1") {
            setIs_Weight('0')
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "hide";
                formFields[get_index].validation = "";
            }
        } else if (formFields[index].name == "uom" && value == "0") {
            setIs_Weight('1')
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "";
                formFields[get_index].validation = "required";
            }
        }
        if (formFields[index].name == "reward") {
            if (value == 1) {
                setRewardhide(true);
                let data = chosenItems;
                data.map((item) => {
                    return item.price = 0;
                })
                setChosenItems([...data])
            } else {
                setRewardhide(false);
                let data = chosenItems;
                data.map((item) => {
                    item.price = '';
                    item.reward = '';
                    return item;
                })
                setChosenItems([...data])
            }
        }

        formFields[index].value = value;
        setFormFields([...formFields]);
    }

    const DropdownChange = (value, index, type) => {
        if (type === "subcategory") {
            if (value && value.length > 0 ? value && value[0].hybrid_category == '1' : false) {
                setHybridProduct(true)
                setIs_Weight('0')
                let get_index = _.findIndex(formFields, { name: "uom" })
                if (get_index >= 0) {
                    formFields[get_index].class = "hide";
                    formFields[get_index].validation = "";
                    formFields[get_index].value = 1;
                }

                let get_indexs = _.findIndex(formFields, { name: "weight" })
                if (get_indexs >= 0) {
                    formFields[get_indexs].class = "hide";
                    formFields[get_indexs].validation = "";
                }
            } else {
                setHybridProduct(false)
                let get_index = _.findIndex(formFields, { name: "uom" })
                if (get_index >= 0) {
                    formFields[get_index].class = "";
                    formFields[get_index].validation = "";
                }
                if (categoryId != 4) {
                    let get_indexs = _.findIndex(formFields, { name: "weight" })
                    if (get_indexs >= 0) {
                        formFields[get_indexs].class = "";
                        formFields[get_indexs].validation = "";
                    }
                }
            }
        }

        if (type === "category") {
            setSubcategoryOptions([]);
            let datas = formFields.map((data, index) => {
                if (data.name === "subcategory_id")
                    data.value = [];
                return data;
            })
            if (value) {
                setCategoryId(value && value[0].id)
            }
            setFormFields(datas)
            let postData = { category_id: value && value[0].id };
            dispatch(subcategoryFilterData({ postData }))
        }
        if (type == 'category') {
            setSubId(value && value.length > 0 && value[0].id)
            if (value && value.length > 0 && value[0].id == 4) {
                let get_index = _.findIndex(formFields, { name: "weight" })
                if (get_index >= 0) {
                    formFields[get_index].class = "hide";
                    formFields[get_index].validation = "";
                }
            }
        }
        if (type == 'defaultsize') {
            setDefaultItemsize(value && value.length > 0 && value[0].id)
        }

        // if (type == 'defaultsize' && subId == 4) {
        //     setDefaultItemsize(value && value.length > 0 && value[0].id)
        // }
        // if (type === "itemsize" && editDatas && typeof (editDatas.name) !== "undefined") {
        //     console.log(formFields, "chosenItems1")
        //     console.log(value, "value");
        //     let data = chosenItems;
        //     console.log(chosenItems, "chosenItems1")
        //     console.log(editDatas, "chosenItems12")
        //     //editDatas && typeof (editDatas.name) !== "undefined"
        //     let new_id = "";
        //     value.map((items) => {
        //         console.log(items, 'items1');
        //         let price = "";
        //         let points = "";
        //         let index_chosen = _.findIndex(chosenItems, { name: items.name });
        //         let positionObj;
        //         let geteditedprice;
        //         if (editDatas) {
        //              geteditedprice = _.filter(editDatas.price, { itemsize_id: items.id });
        //             positionObj = _.find(editDatas.price, { itemsize_id: items.id })
        //             console.log(editDatas, geteditedprice, 'geteditedprice');
        //             if (geteditedprice.length > 0) {
        //                 price = parseFloat(geteditedprice[0].price);
        //                 points = parseFloat(geteditedprice[0].points);
        //             }
        //         }
        //         if (index_chosen < 0) {
        //             data.push({ name: items.name, itemsize_id: items.id, price: geteditedprice && geteditedprice.length > 0 ? price : 0, points: points, position: positionObj && positionObj.position });
        //             new_id = items.id;
        //         }
        //     })
        //     console.log(new_id, 'new_id==');
        //     // let idata = _.omit(data, _.isUndefined)
        //     data = data.filter(function (element) {
        //         return element !== undefined;
        //     });
        //     let diff = _.differenceBy(data, value, 'name');
        //     console.log(diff, 'diff');
        //     let delete_index = -1;
        //     if (diff.length > 0) {
        //         delete_index = _.findIndex(data, { name: diff[0]?.name });
        //         const delete_data = _.filter(data, { name: diff[0]?.name });
        //         console.log(delete_data, "delete_data");
        //         setdeleteItems(deleteItems, ...delete_data);
        //     }
        //     if (delete_index >= 0) {
        //         let default_index = _.findIndex(formFields, { name: "default_size" });
        //         if (default_index >= 0) {
        //             let dval = formFields[default_index].value;
        //             if (dval.length > 0 && diff[0]?.name == dval[0]?.name) {
        //                 formFields[default_index].value = [];
        //             }
        //             console.log(dval, "value123");
        //         }
        //         _.unset(data, delete_index)
        //     }

        //     data = data.filter(function (element) {
        //         return element !== undefined;
        //     });

        //     if (customItemSize.length) {
        //         let dataArr = customItemSize
        //         let customData = []
        //         dataArr.map((item) => {
        //             item.customMenuCounts.map((value) => {
        //                 console.log(value, 'value==');
        //                 let customDatasVal = {
        //                     "id": `${value?.customDatas.id}`,
        //                     "name": value?.customDatas.name,
        //                     "max": "1",
        //                     "min": "0"
        //                 }
        //                 customData.push({ customDatas: customDatasVal })

        //                 // const newArr = customItemSize.map(v => ({ ...v, size: true }))
        //             })
        //         })
        //         console.log(customData, 'customData==');
        //         let filteredData = customData.filter((v, i, a) => a.findIndex(v2 => (v2.customDatas?.id === v?.customDatas?.id)) === i)
        //         // console.log(filteredData, 'filteredData=');
        //         console.log(customData, 'customData==');
        //         let newArr = customItemSize
        //         newArr.push({ sizeID: new_id, customMenuCounts: filteredData })
        //         console.log(newArr, 'newArr==');
        //         setcustomItemSize([...newArr])
        //     }
        //     if (customenuCountWeight.length) {
        //         let customenuWeight = [];
        //         //Weight Array Form
        //         value.map((item) => {
        //             return customenuWeight.push({
        //                 itemsize_id: item.id,
        //                 itemsize_name: item.name,
        //                 weight_value: customenuCountWeight && customenuCountWeight.length && customenuCountWeight.find(data => data.itemsize_id == item.id) ? customenuCountWeight && customenuCountWeight.length && customenuCountWeight.find(data => data.itemsize_id == item.id).weight_value : 0
        //             })
        //         })
        //         setcustomenuCountWeight([...customenuWeight])
        //         // let filteredData = dataArr.filter((v, i, a) => a.findIndex(v2 => (v2.itemsize_id === v?.itemsize_id)) === i)
        //         // console.log(filteredData, 'filteredData=33');
        //         // setcustomenuCountWeight([...filteredData])
        //     }

        //     // const maxPosition = Math.max(...data.map(item => item.position));

        //     // // If maxPosition is a finite number, increment it by one for the new item's position
        //     // const newPosition = Number.isFinite(maxPosition) ? maxPosition + 1 : data.length;

        //     // // Update the position of the new item
        //     // data[data.length - 1].position = newPosition;
        //     // console.log(data, 'data!*&');
        //     setChosenItems([...data]);
        //     // setChosenItems(_.orderBy(data, ['position'], ['asc']))
        //     if (selectedDatas.length > 0) {
        //         let sdata = selectedDatas.map((vals) => {
        //             let iids = vals.itemsize_id;
        //             let iprice = vals.price;
        //             console.log(iids, iprice,'iids, iprice,geteditedprice')
        //             if (delete_index >= 0) {
        //                 _.unset(vals.itemsize_id, delete_index)
        //                 _.unset(vals.price, delete_index)
        //             }
        //             //this was wrong,for newitem id price[0] value was being updated
        //             // else if (new_id != "") {
        //             //     vals.itemsize_id.push(new_id);
        //             //     vals.price.push(vals.item_price);
        //             // }
        //             else if (new_id != "") {
        //                 vals.itemsize_id.push(new_id);
        //                 vals.price.push(0); // Push 0 as the initial price for the new itemsize_id
        //             }
        //             vals.itemsize_id = vals.itemsize_id.filter(function (element) {
        //                 return element !== undefined;
        //             });
        //             vals.price = vals.price.filter(function (element) {
        //                 return element !== undefined;
        //             });
        //             return vals;
        //         });
        //         console.log(sdata);
        //         console.log(sdata,'logggg1');
        //         setselectedDatas([...sdata]);
        //         // setTempSelectedDatas([...sdata])
        //     }
        // }
        if (type === "itemsize" && editDatas && typeof (editDatas.name) !== "undefined") {
            let data = chosenItems;
            //editDatas && typeof (editDatas.name) !== "undefined"
            let new_id = "";
            value.map((items) => {
                let price = "";
                let points = "";
                let index_chosen = _.findIndex(chosenItems, { name: items.name });
                let positionObj;
                let geteditedprice;
                if (editDatas) {
                    geteditedprice = _.filter(editDatas.price, { itemsize_id: items.id });
                    positionObj = _.find(editDatas.price, { itemsize_id: items.id })
                    if (geteditedprice.length > 0) {
                        price = parseFloat(geteditedprice[0].price);
                        points = parseFloat(geteditedprice[0].points);
                    }
                }
                if (index_chosen < 0) {
                    data.push({ name: items.name, itemsize_id: items.id, price: geteditedprice && geteditedprice.length > 0 ? price : 0, points: points, position: positionObj && positionObj.position });
                    new_id = items.id;
                }
            })
            // let idata = _.omit(data, _.isUndefined)
            data = data.filter(function (element) {
                return element !== undefined;
            });
            let diff = _.differenceBy(data, value, 'name');
            let delete_index = -1;
            if (diff.length > 0) {
                // delete_index = _.findIndex(data, { name: diff[0]?.name });
                // const delete_data = _.filter(data, { name: diff[0]?.name });
                delete_index = _.findIndex(data, { itemsize_id: diff[0]?.itemsize_id });
                const delete_data = _.filter(data, { itemsize_id: diff[0]?.itemsize_id });
                setdeleteItems(deleteItems, ...delete_data);
            }
            if (delete_index >= 0) {
                let default_index = _.findIndex(formFields, { name: "default_size" });
                if (default_index >= 0) {
                    let dval = formFields[default_index].value;
                    if (dval.length > 0 && diff[0]?.name == dval[0]?.name) {
                        formFields[default_index].value = [];
                    }
                }
                _.unset(data, delete_index)
            }

            data = data.filter(function (element) {
                return element !== undefined;
            });

            if (customItemSize.length) {
                let dataArr = customItemSize
                let customData = []
                dataArr.map((item) => {
                    item.customMenuCounts.map((value) => {
                        let customDatasVal = {
                            "id": `${value?.customDatas.id}`,
                            "name": value?.customDatas.name,
                            "max": "1",
                            "min": "0"
                        }
                        customData.push({ customDatas: customDatasVal })

                        // const newArr = customItemSize.map(v => ({ ...v, size: true }))
                    })
                })
                let filteredData = customData.filter((v, i, a) => a.findIndex(v2 => (v2.customDatas?.id === v?.customDatas?.id)) === i)
                // console.log(filteredData, 'filteredData=');
                let newArr = customItemSize
                newArr.push({ sizeID: new_id, customMenuCounts: filteredData })
                setcustomItemSize([...newArr])
            }
            if (customenuCountWeight.length) {
                let customenuWeight = [];
                //Weight Array Form
                value.map((item) => {
                    return customenuWeight.push({
                        itemsize_id: item.id,
                        itemsize_name: item.name,
                        weight_value: customenuCountWeight && customenuCountWeight.length && customenuCountWeight.find(data => data.itemsize_id == item.id) ? customenuCountWeight && customenuCountWeight.length && customenuCountWeight.find(data => data.itemsize_id == item.id).weight_value : 0
                    })
                })
                setcustomenuCountWeight([...customenuWeight])
                // let filteredData = dataArr.filter((v, i, a) => a.findIndex(v2 => (v2.itemsize_id === v?.itemsize_id)) === i)
                // console.log(filteredData, 'filteredData=33');
                // setcustomenuCountWeight([...filteredData])
            }

            // const maxPosition = Math.max(...data.map(item => item.position));

            // // If maxPosition is a finite number, increment it by one for the new item's position
            // const newPosition = Number.isFinite(maxPosition) ? maxPosition + 1 : data.length;

            // // Update the position of the new item
            // data[data.length - 1].position = newPosition;
            // console.log(data, 'data!*&');
            setChosenItems([...data]);
            // setChosenItems(_.orderBy(data, ['position'], ['asc']))

            if (selectedDatas.length > 0) {
                let sdata = selectedDatas.map((vals) => {
                    let iids = vals.itemsize_id;
                    let iprice = vals.price;
                    if (delete_index >= 0) {
                        // Remove the deleted item size ID and its corresponding price
                        let findIndexOfTheItem = vals.itemsize_id.indexOf(diff && diff[0] && diff[0].itemsize_id);
                        if (findIndexOfTheItem) {
                            vals.itemsize_id.splice(findIndexOfTheItem, 1);
                            vals.price.splice(findIndexOfTheItem, 1);
                        }
                    } else if (new_id != "") {
                        // Check if the new item size ID is already present in vals.itemsize_id
                        if (!vals.itemsize_id.includes(new_id)) {
                            // Add the new item size ID
                            vals.itemsize_id.push(new_id);
                            // Push 0 as the initial price for the new item size ID
                            vals.price.push(0);
                        }
                    }
                    return vals;
                });
                setselectedDatas([...sdata]);
            }


        }
        formFields[index].value = value;
        setFormFields([...formFields]);
    };

    // useEffect(()=>{
    //     if(existingImage && existingImage.length > 0){
    //         const filteredImages = existingImage.filter(image => image !== '');
    //         setExistingImage(filteredImages)
    //     }

    // },[])

    useEffect(() => {
        if (categoryId === 5) {
            if (value === undefined || value === '') {
                setfileerror(true)
                setShowvalidator(true);
            } else {
                setfileerror(false)
                setShowvalidator(false);
            }
        }

    }, [value, categoryId])

    useEffect(() => {
        if (categoryId === 5 && productDataById) {
            // Assuming productDataById.image contains "product_images/1711626830767_OliveGreen Tshirt.png"
            const filenameParts = productDataById.image.split('_');
            const filename = filenameParts[2]; // This will give you "OliveGreen Tshirt.png"

            // Find the index in merchImageArray where the path matches the filename
            const index = merchImageArray.findIndex(item => item.path === filename);

            // If index is found, it will be the position in the array (starting from 0). If not found, index will be -1.

            if (index === -1) {
                setfileerror(true)
            }
            else {
                setValue(index)
                setfileerror(false)
            }
        }
    }, [productDataById, merchImageArray, categoryId])


    const deleteImg = (index) => {
        // Create a copy of the merchImageArray
        let updatedMerchImageArray = [...merchImageArray];
        let updatedExistingImage = [...existingImage]

        // Remove the element at the specified index
        let splicedImg = updatedMerchImageArray.splice(index, 1);
        let splicedExisImg = updatedExistingImage.splice(index, 1)

        // Update the state with the modified array
        setMerchImageArray(updatedMerchImageArray);
        setExistingImage(updatedExistingImage)
        setValue()
    }


    // const deleteImg = (imgs, index) => {
    //     console.log(imgs, index, 'imgs, index');
    //     const filteredImages = existingImage.filter(image => image !== '');

    //     if (index >= 0 && filteredImages.length > 0) {
    //         // Splice the array to remove the item at the specified index
    //         filteredImages.splice(index, 1);
    //         setExistingImage(filteredImages)
    //     }
    //     let img_index = _.findIndex(formFields, { name: "image" })
    //     if (img_index >= 0) {
    //         let images = formFields[img_index].value;
    //         console.log(images, "hi")
    //         _.unset(images, index);
    //         images = images.filter(function (element) {
    //             return element !== undefined;
    //         });
    //         console.log(images);
    //         formFields[img_index].value = images;
    //         console.log(formFields)
    //         setFormFields([...formFields])
    //         setdeletedImgs([...deletedImgs, imgs]);
    //     }

    // };

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        let formdata = new FormData();
        if (categoryId === 5 && merchImageLength === true) {
            // setfilesizeerror(true);
            setUploadFiles(...uploadfiles, acceptedFiles);
            setfileUpload(acceptedFiles);
            formdata.append("file", acceptedFiles)
        }
        acceptedFiles.forEach((file) => {
            setfilename(file.name)
            if (categoryId === 5 && merchImageLength === true) {
                formdata.append("fileToUpload", file);
            }
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                const img = new Image();
                img.onload = () => {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;
                    setImgSRCwidth(width);
                    setImgSRCheight(height);

                    // if (width < maxProductWidth || height < maxProductHeight) {
                    //     const errorMessage = {
                    //         file,
                    //         errors: [
                    //             {
                    //                 code: "file-invalid-dimensions",
                    //                 message: 'The image dimensions must be at least ' + maxProductWidth + ' x ' + maxProductHeight + ' pixels',
                    //             },
                    //         ],
                    //     };

                    //     rejectedFiles.push(errorMessage);
                    //     setThumbsImage([]);
                    //     setfileUpload('')
                    // } else {
                    if (categoryId === 5 && merchImageLength === true) {
                        setShowPreview(false);
                    } else {
                        setShowPreview(false);
                    }
                    setfileUpload(binaryStr);
                    setfilePath(file.path);
                    // setfileerror(false);
                    // }
                };
                img.src = binaryStr;
            }
            reader.readAsDataURL(file)
        })

    }, [merchImageLength, categoryId])

    //Update Weight Value 
    useEffect(() => {
        if (((categoryId === 4) && (customenuCountWeight && customenuCountWeight.length > 0) && (is_weight === '1'))) {
            let filteredWeightItem = _.find(customenuCountWeight, { itemsize_id: Number(defaultItemsize) })
            if (filteredWeightItem) {
                setWeightValue(filteredWeightItem && filteredWeightItem.weight_value)
            }
        }
        else {
            let filteredWeightItem = _.find(formFields, { name: "weight" })
            if (filteredWeightItem) {
                setWeightValue(filteredWeightItem && filteredWeightItem.value)
            }
        }
    }, [customenuCountWeight, defaultItemsize, is_weight, formFields])

    //filePath for existing Image 
    useEffect(() => {
        if (((filePath == '') && (categoryId === 5))) {
            if (existingImage && existingImage.length > 0) {
                const firstImage = existingImage[0];
                const splitValue = firstImage.split('_')[2];
                setfilePath(splitValue)
            }
        }
    }, [existingImage, categoryId])


    useEffect(() => {
        if (columnOrder.length) {
            let InitialArray = [{
                code_value: 'Quantity',
                value: 1,
                custommenu_id: null
            }]
            const datas = formFields.map((data) => {
                let val = '';
                if (data?.name === "default_size") {
                    val = data.value;
                    if (val.length) {
                        InitialArray.push({
                            code_value: val[0].name,
                            value: 2,
                            custommenu_id: null
                        })
                    }
                }
                else if (data?.name === "item_code") {
                    InitialArray.push({
                        code_value: data.value,
                        value: 3,
                        custommenu_id: null
                    })
                }
            });
            let customDatas = []
            let testData = custommenuDatasbyID && custommenuDatasbyID.map((row, tindex) => {
                row?.custom_menu_items.length > 0 && columnOrder.find((item) => { return item.value == 4 && item.id == row?.id }) && row?.custom_menu_items.map((item, rindex) => {
                    if (selectedDatas.find((value) => { return value.default == 1 && value.item_id == item.id })) {
                        customDatas.push({
                            code_value: item.code,
                            value: 4,
                            custommenu_id: row?.id
                        })
                    }
                })
            }
            )

            let mergeCustomMenus = customDatas.reduce(function (o, cur) {
                // Get the index of the key-value pair.
                var occurs = o.reduce(function (n, item, i) {
                    return (item.custommenu_id === cur.custommenu_id) ? i : n;
                }, -1);

                // If the name is found,
                if (occurs >= 0) {

                    // append the current value to its list of values.
                    o[occurs].code_value = o[occurs].code_value.concat(cur.code_value);

                    // Otherwise,
                } else {

                    // add the current item to o (but make sure the value is an array).
                    var obj = {
                        code_value: [cur.code_value],
                        value: cur.value,
                        custommenu_id: cur.custommenu_id
                    };
                    o = o.concat([obj]);
                }
                return o;
            }, []);


            let finalValArray = [...InitialArray, ...mergeCustomMenus]

            let mergedList = [];
            finalValArray.map((arr1) => {
                mergedList.push({ ...arr1, order_no: columnOrder.find((arr2) => { return ((arr2.value === arr1.value) && (((arr2.value == 4) ? arr2.id : null) == arr1.custommenu_id)) })?.position })
            });

            let kitchenCodeList = buildData(mergedList);
            let tempArr = []
            for (let i in kitchenCodeList) {
                tempArr.push({
                    value: Array.isArray(kitchenCodeList[i].code_value) ? kitchenCodeList[i].code_value.join(" ") : kitchenCodeList[i].code_value
                })
            }

            let finalData = tempArr.map((item) => { return item.value })
            finalData = finalData.join("  ")
            setsampleCodeOutput([...finalData])
        }
    }, [columnOrder, customenuCountDatas, selectedDatas])

    useEffect(() => {
        if (subId == 4 || is_weight == '0') {
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "hide";
                formFields[get_index].validation = "";
            }
        } else {
            let get_index = _.findIndex(formFields, { name: "weight" })
            if (get_index >= 0) {
                formFields[get_index].class = "";
                formFields[get_index].validation = "required";
            }
        }
    }, [subId, is_weight])

    // useEffect(() => {
    //     if (files.length > 0) {
    //         console.log(files[0].preview, "preview");
    //         setImgSRC(files[0].preview)
    //         let imgObj = _.find(formFields, { name: 'image' })
    //         let thumbs = files.map((file, index) => {
    //             return <div className="dropzone_thum_img">
    //                 <div className="dropzone_thumb">
    //                     <img
    //                         src={file.preview}
    //                         className="dropzone_img"
    //                     // Revoke data uri after image is loaded
    //                     // onLoad={() => { URL.revokeObjectURL(file.preview) }}
    //                     />
    //                 </div>
    //             </div>
    //         });
    //         setThumbsImage(thumbs);
    //     }
    // }, [files])


    useEffect(() => {
        if (formFields && categoryId === 5) {
            let imgObj = _.find(formFields, { name: 'image' })

            const splitImgName = (path) => {
                const imagePath = isObject(path) ? path?.path : path
                const pathParts = imagePath.split("/");
                const fileName = pathParts[pathParts.length - 1];
                const fileNameParts = fileName.split("_");
                return fileNameParts[fileNameParts.length - 1];
            }

            if (imgObj && imgObj.value && Array.isArray(imgObj.value) && imgObj.value.length > 0) {
                const updatedMerchImageArray = imgObj.value.map((path, index) => ({
                    path: splitImgName(path),
                    defaultPath: path
                }));
                setMerchImageArray(updatedMerchImageArray);
            }
        }
    }, [formFields, categoryId])

    useEffect(() => {
        if (files.length > 0 && categoryId === 5) {
            const filesArray = files.map((file, index) => ({
                path: file.path,
                defaultPath: file.preview
            }));
            setMerchImageArray(prevState => [...prevState, ...filesArray]);
        }
    }, [files, categoryId])

    useEffect(() => {
        if (completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current) {
            const t = setTimeout(() => {

                // We use canvasPreview as it's much faster than imgPreview.

                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                )
            }, 100);

            return () => {
                clearTimeout(t)
            }
        }

    }, [completedCrop])

    useEffect(() => {
        if (((files && files.length > 0) || (formFields && formFields.length > 0))) {
            let imageValue = formFields && formFields[12] && formFields[12].value && formFields[12].value[0] != undefined ? formFields[12].value[0] : files && files.length && files.length > 0 && assestURL + files[0].path
            setMerchImageValue(imageValue)
        }
    }, [formFields, files])

    //Drag and Drop

    useEffect(() => {
        if (columnOrder && columnOrder.length > 0) {
            const idToPositionMap = Object.fromEntries(columnOrder.map((col) => [col.id, col.position]));

            // Update the positions in the initialColumns array
            const updatedInitialColumns = initialColumns.map((col) => ({
                ...col,
                position: idToPositionMap[col.id], // Update the position based on the mapping
            }));

            // Now, updatedInitialColumns has the correct positions from columnOrder
            setInitialColumns(updatedInitialColumns)
        }

    }, [columnOrder, customenuCountDatas])

    useEffect(() => {
        if (((editDatas && editDatas.product_kitchencode && editDatas.product_kitchencode.length > 0) && (columnOrder && columnOrder.length === 0))) {
            // setColumnOrder(editDatas.product_kitchencode);
            const updatedColumnOrder = editDatas.product_kitchencode.map((data) => ({
                ...data,
                id: data.value === 4 ? Number(data.custommenu_id) : uuidv4(),
            }));

            setColumnOrder(_.orderBy(updatedColumnOrder, ['position'], ['asc']));
        }
        else if (((customenuCountDatas && customenuCountDatas.length > 0) && (initialColumns && initialColumns.length > 0) && (columnOrder && columnOrder.length === 0))) {
            let temp = [];
            for (let i in customenuCountDatas) {
                // const existingColumn = columnOrder.find((col) => col.id === customenuCountDatas[i].id);
                temp.push({
                    id: customenuCountDatas[i].id,
                    position: initialColumns.length + parseInt(i) + 1,
                    // position: existingColumn ? existingColumn.position : initialColumns.length + parseInt(i) + 1,
                    name: customenuCountDatas[i].name,
                    value: 4,
                });
            }

            // Combine initialColumns and temp, then sort based on position
            const combinedColumns = [...initialColumns, ...temp].sort((a, b) => a.position - b.position);
            setColumnOrder(combinedColumns);
        }
    }, [editDatas, customenuCountDatas, initialColumns])

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }, multiple: (categoryId === 5) ? true : false,
        maxSize: 1024000,

    })
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div class="invalid-feedback show">
            {errors.map(e => (
                <span>{(e.message == 'File is larger than 1024000 bytes') ? 'File size should be less than 1 MB' : e.message}</span>

            ))}
        </div>
    ));
    //Crop Image Modal close
    const Imgmodalclose = () => {
        setShowPreview(false)
    }

    const cropImg = () => {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }
        //   console.log(previewCanvasRef.current)
        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }
            const reader = new FileReader();

            reader.onload = () => {
                setfileUpload(reader.result);
                setShowPreview(false)
            };
            reader.readAsDataURL(blob);
        })
    };

    const submitCategory = (e) => {
        let itemsize_id = _.map(chosenItems, 'itemsize_id');
        let price = _.map(chosenItems, 'price');
        //return false;
        if (simpleValidator.current.allValid()) {
            setShowvalidator(false);
            if (filesizeerror == true) {
                return false;
            }
            if (((files && files.length == 0) && (existingImage && existingImage.length == 0) && (categoryId === 5))) {
                setfileerror(true);
                return false;
            }

            // if (((categoryId === 4) && (fileUpload && fileUpload.length == 0))) {
            //     setfileerror(true);
            //     return false;
            // }
            //alert('You submitted the form and stuff!');
            let arr = {};
            //const postData = new FormData();
            const datas = formFields.map((data) => {
                let val = '';
                if (data?.name === "status") {
                    val = data.value;
                    val = val[0].id;
                } else if (data?.name === "category_id") {
                    val = data.value;
                    val = val[0].id;
                } else if (data?.name === "subcategory_id") {
                    val = data.value;
                    val = val[0].id;
                } else if (data?.name === "default_size") {
                    val = data.value;
                    val = val[0].id;
                } else {
                    val = data.value
                }
                return arr[data?.name] = val;


            });
            if (arr.custom_menus == 1 && customenuCountDatas.length == 0) {
                setcustommenuserror(true);
                return false;
            } else {
                setcustommenuserror(false);
            }
            if (arr.custom_menus == 1 && (customenuCountDatas.length == 0 || selectedDatas.length == 0)) {
                setcustomerror(true);
                return false;
            }
            let price_valid = true;
            if (!rewardhide) {
                chosenItems.map((item) => {
                    if (item.price == "" || item.price === null || item.price === undefined || item.price === NaN) {
                        price_valid = false;
                    }
                    if (item.price == 0 || item.price == 0.00) {
                        price_valid = true
                    }
                });
            } else {
                chosenItems.map((item) => {
                    if (item.points == "" || item.points === null || item.points === undefined) {
                        price_valid = false;
                    }
                });
            }
            if (price_valid == false) {
                setpriceerror(true);
                return false;
            }

            let newData = []
            for (let i in chosenItems) {
                newData.push({
                    "name": chosenItems[i].name,
                    "itemsize_id": chosenItems[i].itemsize_id,
                    "price": chosenItems[i].price,
                    "points": chosenItems[i].points,
                    "position": Number(i)
                })
            }

            if (newData && newData.length > 0) {
                arr.itemsize_id = newData;
            }
            let kitchenData = []
            for (let i in columnOrder) {
                kitchenData.push({
                    "name": columnOrder[i].name,
                    "value": columnOrder[i].value,
                    "custommenu_id": columnOrder[i].value === 4 ? columnOrder[i].id : null,
                    "order_no": Number(i),
                    "position": Number(i) + 1
                })
            }
            if (kitchenData && kitchenData.length > 0) {
                arr.kitchencode_data = kitchenData
            }
            // let actualFileName;
            // if (categoryId === 5) {
            //     const imagePath = merchImageValue
            //     // Split the path by "/"
            //     const pathParts = imagePath.split("/");
            //     // Get the last part, which should be the file name
            //     const fileName = pathParts[pathParts.length - 1];
            //     console.log(fileName, 'pathParts'); // Output: "1706857651185_OliveGreen Tshirt.png"
            //     const fileNameParts = fileName.split("_");
            //     console.log(fileNameParts[fileNameParts.length - 1], 'fileNameParts');
            //     actualFileName = fileNameParts[fileNameParts.length - 1];
            //     console.log(actualFileName, 'actualFileName'); // Output: "OliveGreen Tshirt.png"
            // }
            // console.log(actualFileName, 'actualFileName---');

            let deleteitem = [];
            prevselectedItem.map((item) => {
                let delete_indexitem = _.findIndex(selectedDatas, { item_id: item?.item_id });
                if (delete_indexitem < 0) {
                    deleteitem.push({ custom_item_id: item?.item_id, product_id: updateID })
                }
            });
            let deletesize = [];
            deleteItems.map((item) => {
                let delete_indexitem = _.findIndex(chosenItems, { itemsize_id: item?.itemsize_id });
                if (delete_indexitem < 0) {
                    deletesize.push({ itemsize_id: item?.itemsize_id })
                }
            });
            let deletecustom = []
            deleteCustomMenu.map((item) => {
                let delete_indexitem = _.findIndex(selectedCustomMenu, { id: item?.id });
                if (delete_indexitem < 0) {
                    deletecustom.push({ id: item?.id })
                }
            });
            console.log(customenuCountDatas, customItemSize, customenuCountWeight, "customenuCountDatas=");

            arr.prevdatas = _.map(deleteitem, 'custom_item_id');
            arr.deletedsize_id = _.map(deletesize, 'itemsize_id');
            arr.deletecustom_id = _.map(deletecustom, 'id');
            arr.price = _.map(chosenItems, 'price');
            arr.points = _.map(chosenItems, 'points');
            arr.custom_products = selectedDatas;
            arr.custom_menudata = customenuCountDatas;
            arr.is_weight = is_weight;
            arr.hybrid_product = hybridProduct ? 1 : 0;
            arr.sorted_custom = selectedCustomMenu.map(item => item.id);
            if (defaultItemsize != 0 && subId == 4 && hybridProduct) {
                arr.custom_menudata_size = customItemSize
            }
            else if (defaultItemsize != 0 && subId == 4 && is_weight == '1') {
                arr.customsize_weight = customenuCountWeight
            }
            else if (defaultItemsize != 0 && subId == 4) {
                arr.custom_menudata_size = customItemSize
            }
            arr.image = categoryId === 5 ? (defaultImageBinary === null ? '' : defaultImageBinary) : fileUpload;
            // arr.image = (categoryId === 5 && merchImageLength === true) ? merchImageValue : fileUpload;
            arr.imgpath = categoryId === 5 ? merchImageArray?.[value]?.path : filePath;
            delete arr['itemsize_ids'];
            const postData = arr;
            //return false;

            postData.id = updateID;
            if ((categoryId === 5) && (value === '' || value === undefined || value === null)) {
                toast.error('Set as Default is a mandatory field')
            }
            else {
                dispatch(UpdateProduct({ postData, updateID }));
            }
            // if (categoryId === 5) {
            //     UpdateMerchImages({ uploadfiles, updateID })
            // }

        } else {
            setShowvalidator(true);
        }
    };

    const changePrice = (e, index) => {
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) && e.target.value >= 0 || e.target.value == "") {
            chosenItems[index].price = e.target.value
            setChosenItems([...chosenItems]);
            setpriceerror(false);
        }
    }
    const changeReward = (e, index) => {
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            chosenItems[index].points = e.target.value
            setChosenItems([...chosenItems]);
            setpriceerror(false);
        }
    }

    const toggleChecked = (e) => {
        setDefaultItemsize(e.target.value);
    }

    const DropdownCustom = (value) => {

        setColumnOrder((prevColumnOrder) => {
            const valueToKeep = [1, 2, 3];

            // Remove items that are not in selectedValues
            const newColumnOrder = prevColumnOrder.filter((item) => {
                const existsInSelectedValues = value.find((value) => value.id === item.id);
                return existsInSelectedValues || valueToKeep.includes(item.value);
            });

            // Add new items from selectedValues
            value.forEach((selectedValue) => {
                const existsInColumnOrder = newColumnOrder.find((item) => item.id === selectedValue.id);
                if (!existsInColumnOrder) {
                    newColumnOrder.push({
                        ...selectedValue,
                        position: newColumnOrder.length + 1,
                        value: 4,
                    });
                }
            });

            // Sort the array based on position
            newColumnOrder.sort((a, b) => a.position - b.position);
            return _.orderBy(newColumnOrder, ['position'], ['asc']);
        });
        console.log(customenuCountDatas, "customenuCountDatas==");

        // const uniqueAges = [...new Set(customenuCountDatas.map(obj => obj.id))];
        // console.log(uniqueAges, "uniqueAges==");
        console.log(subId, is_weight, 'working-before');

        if ((subId == '4' && is_weight == '0') || hybridProduct) {
            console.log('working-before--1');
            let temp = customItemSize
            setSelectedCustomMenu(value);
            if (temp && temp.length) {
                let customName = customItemSize && customItemSize[0].customMenuCounts.map((item) => {
                    return item.customDatas.name
                })
                let valueName = value && value.map((item) => {
                    return item.name
                })
                console.log('working==');

                if (customName.length > 0) {
                    let dataValue = customItemSize
                    if (value?.length > 0) {
                        let check = valueName.filter((item) => {
                            return !customName.includes(item)
                        })
                        if (!check.length) {
                            let data = dataValue && dataValue.map((item) => {
                                item.customMenuCounts = item.customMenuCounts.filter((items) => {
                                    return (
                                        valueName.includes(items.customDatas.name)
                                    )
                                })
                                return item
                            })
                            let idValue = []
                            if (data) {
                                data && data[0].customMenuCounts.map((item) => {
                                    idValue.push(item.customDatas.id)
                                })
                            }
                            let postData = { ids: idValue }
                            dispatch(custommenuDatabyID({ postData }));
                            setcustomItemSize([...data])

                        }
                        else {
                            let ValueIndex = value.filter((item) => { return item.name == check[0] })
                            let customDatas = {
                                "id": `${ValueIndex[0].id}`,
                                "name": ValueIndex[0].name,
                                "max": "1",
                                "min": "0"
                            }
                            for (let j in customItemSize) {
                                customItemSize[j].customMenuCounts.push({ customDatas: customDatas })
                            }

                            let idValue = []
                            if (customItemSize) {
                                customItemSize && customItemSize[0].customMenuCounts.map((item) => {
                                    idValue.push(item.customDatas.id)
                                })
                            }
                            let postData = { ids: idValue }
                            dispatch(custommenuDatabyID({ postData }));
                        }

                        // for (let i in value) {
                        //     if (customName.includes(value[i].name)) {
                        //         let data = dataValue && dataValue.map((item) => {
                        //             item.customMenuCounts = item.customMenuCounts.filter((items) => {
                        //                 return (
                        //                     valueName.includes(items.customDatas.name)
                        //                 )
                        //             })
                        //             return item
                        //         })
                        //         let idValue = []
                        //         if (data) {
                        //             data && data[0].customMenuCounts.map((item) => {
                        //                 idValue.push(item.customDatas.id)
                        //             })
                        //         }
                        //         let postData = { ids: idValue }
                        //         console.log(postData, 'postData==1');
                        //         // dispatch(custommenuDatabyID({ postData }));
                        //         setcustomItemSize([...data])

                        //     }
                        //     else {
                        //         let customDatas = {
                        //             "id": `${value[i].id}`,
                        //             "name": value[i].name,
                        //             "max": "1",
                        //             "min": "0"
                        //         }
                        //         for (let j in customItemSize) {
                        //             customItemSize[j].customMenuCounts.push({ customDatas: customDatas })
                        //         }

                        //         let idValue = []
                        //         if (customItemSize) {
                        //             customItemSize && customItemSize[0].customMenuCounts.map((item) => {
                        //                 idValue.push(item.customDatas.id)
                        //             })
                        //         }
                        //         let postData = { ids: idValue }
                        //         console.log(postData, 'postData==2');
                        //         dispatch(custommenuDatabyID({ postData }));
                        //     }
                        // }
                    } else {
                        setcustomItemSize([])
                        setselectedDatas([])
                        let postData = { ids: [] }
                        dispatch(custommenuDatabyID({ postData }));
                    }

                    setcustomItemSize([...customItemSize])

                    let customData = customItemSize
                    let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
                    if (selectedDatas.length > 0) {
                        let customData = customItemSize
                        let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
                        let custom_menuID = customData[indexvalue].customMenuCounts
                        let sdata = selectedDatas.map((vals) => {
                            let sdelete_index = custom_menuID.findIndex((item) => item.customDatas.id == vals.custom_menu_id)
                            if (sdelete_index < 0) {
                                _.unset(vals)
                                rowcheckbox[vals.custom_menu_id] = [];
                                setrowcheckbox([...rowcheckbox]);
                            } else {
                                return vals;
                            }
                        });
                        sdata = sdata.filter(function (element) {
                            return element !== undefined;
                        });
                        setselectedDatas([...sdata]);
                        // setTempSelectedDatas([...sdata])
                    }
                }
            }
        } else {
            console.log('working-before123');

            let arr = [];
            let data = customenuCountDatas;
            value.map((items) => {
                let index_chosen = _.findIndex(customenuCountDatas, { name: items.name });
                if (index_chosen < 0) {
                    data.push({ name: items.name, id: items.id, min: 0, max: 1 });
                }
            })
            // let idata = _.omit(data, _.isUndefined)
            data = data.filter(function (element) {
                return element !== undefined;
            });
            let diff = _.differenceBy(data, value, 'name');
            let delete_index = -1;
            if (diff.length > 0)
                delete_index = _.findIndex(data, { name: diff[0]?.name });
            if (delete_index >= 0)
                _.unset(data, delete_index)

            data = data.filter(function (element) {
                return element !== undefined;
            });
            setSelectedCustomMenu(value);
            const result = Object.values(arr.reduce((acc, cur) => {
                acc[cur.sizeID] = acc[cur.sizeID] || { sizeID: cur.sizeID, customMenuCounts: [] };
                acc[cur.sizeID].customMenuCounts.push({ customDatas: cur.customDatas });
                return acc;
            }, {}))

            setcustomenuCountDatas([...data]);
            let menuIds = data.map(value => value.id);
            let postData = { ids: menuIds }
            dispatch(custommenuDatabyID({ postData }));

            if (selectedDatas.length > 0) {
                let sdata = selectedDatas.map((vals) => {
                    let sdelete_index = _.findIndex(data, { "id": vals.custom_menu_id });
                    if (sdelete_index < 0) {
                        _.unset(vals)
                        rowcheckbox[vals.custom_menu_id] = [];
                        setrowcheckbox([...rowcheckbox]);
                    } else {
                        return vals;
                    }
                });
                sdata = sdata.filter(function (element) {
                    return element !== undefined;
                });
                setselectedDatas([...sdata]);
                // setTempSelectedDatas([...sdata])
            }
        }
    };

    const onCloseCustommenuCount = () => {
        setshowCustommenuCount(false);
    }

    const onCloseCustommenuItem = () => {
        setshowCustommenuItem(false);
        // setselectedDatas([]);
        // setrowcheckbox([]);
    }

    const onSaveCustommenuItem = () => {
        setselectedDatas([...selectedDatas])
        setshowCustommenuItem(false);
    }

    //Reference 
    // const onSaveCustommenuCount = () => {
    //     let valid_minmax = false;

    //     let couterror = false;
    //     let columnname = '';
    //     customenuCountDatas.map((item) => {

    //         // if ((item.min == '' && item.min != '0') || (item.max == '' && item.max != '0')) {
    //         //     console.log(item.min, item.max, "1")
    //         //     valid_minmax = true;
    //         // } else if ((parseInt(item.min) >= parseInt(item.max))) {
    //         //     console.log(item.min, item.max, "2")
    //         //     valid_minmax = true;
    //         // }

    //         if ((item.min == '' && item.min != '0') || (item.max == '' && item.max != '0')) {
    //             console.log(item.min, item.max, "1")
    //             // valid_minmax = true;
    //         } else if ((parseInt(item.min) >= parseInt(item.max))) {
    //             console.log(item.min, item.max, "2")
    //             // valid_minmax = true;
    //         } else if ((parseInt(item.max) > parseInt(item.match_count))) {
    //             couterror = true;
    //         }

    //         if (valid_minmax) {
    //             columnname += "Menu name : " + item.name + ' Min field should be less than Max Field and it is required. '
    //             valid_minmax = '';
    //         } else if (couterror) {
    //             columnname += "Menu Name : " + item.name + ' Maximum value is ' + item.match_count
    //             couterror = '';
    //         }
    //     })


    //     // if (valid_minmax) {
    //     //     setminmaxerror(true);
    //     //     return false;
    //     // } else {
    //     //     setminmaxerror(false);
    //     // }


    //     //if (valid_minmax || couterror) {
    //     if (columnname != '') {
    //         setFormErrorMsg(columnname);
    //         setminmaxerror(true);
    //         return false;
    //     } else {

    //         setminmaxerror(false);
    //     }

    //     setshowCustommenuCount(false);
    // }

    const onSaveCustommenuCount = () => {
        let valid_minmax = false;
        let couterror = false;
        let columnname = '';
        // customenuCountDatas.map((item) => {
        //     console.log("final datas", customenuCountDatas)
        //     console.log("min-max details", parseInt(item.min), item.min, item.max, "3")
        //     if ((item.min == '' && item.min != '0') || (item.max == '' && item.max != '0')) {
        //         console.log(item.min, item.max, "1")
        //         valid_minmax = true;
        //     } else if ((parseInt(item.min) >= parseInt(item.max))) {
        //         console.log(item.min, item.max, "2")
        //         valid_minmax = true;
        //     } else if ((parseInt(item.max) > parseInt(item.match_count))) {
        //         couterror = true;
        //     }

        //     if (valid_minmax) {
        //         columnname += "Menu name : " + item.name + ' Min field should be less than Max Field and it is required. <br> '
        //         valid_minmax = '';

        //     } else if (couterror) {
        //         columnname += "Menu Name : " + item.name + ' Maxium value is ' + item.match_count + '<br> '
        //         couterror = '';

        //     }

        // })

        //if (valid_minmax || couterror) {
        // if (columnname != '') {
        //     setFormErrorMsg(columnname);
        //     setminmaxerror(true);
        //     return false;
        // } else {

        setminmaxerror(false);
        // }
        setshowCustommenuCount(false);

    }

    const changeMinMaxCustom = (e, index, type, Ishybrid) => {

        const customenuItem = customenuCountDatas[index];

        let customData = customItemSize
        let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
        let custom_menuID = parseInt(customData[indexvalue].customMenuCounts[index].customDatas.id)

        if ((subId == '4' && is_weight == '0') || hybridProduct) {
            // Loop through each item in selectedDatas to find and update the matching item
            selectedDatas.forEach((selectedItem, selectedIndex) => {
                // Check if the custom_menu_id matches with customenuItem.id
                if (selectedItem.custom_menu_id === custom_menuID) {
                    // Update the default property of the matched item to 0
                    selectedDatas[selectedIndex].default = 0;
                }
            });
        } else {
            // Loop through each item in selectedDatas to find and update the matching item
            selectedDatas.forEach((selectedItem, selectedIndex) => {
                // Check if the custom_menu_id matches with customenuItem.id
                if (selectedItem.custom_menu_id === customenuItem.id) {
                    // Update the default property of the matched item to 0
                    selectedDatas[selectedIndex].default = 0;
                }
            });
        }
        // Now selectedDatas contains the updated objects with default property set to 0 where matching custom_menu_id is found
        setselectedDatas(selectedDatas)

        // setTempSelectedDatas(selectedDatas)

        // Now selectedDatas contains the updated objects with default property set to 0 where matching custom_menu_id is found

        if (defaultItemsize) {
            setminmaxerror(false);
            const re = /^\d*\.?\d*$/
            let customData = customItemSize
            if (e.target.value.match(re) || e.target.value == "") {
                if (type == "min") {
                    let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
                    if (Ishybrid == "hybrid") {
                        let toppingIndex = customData[indexvalue].customMenuCounts.findIndex((item) => item.customDatas.id == '17')
                        customData[indexvalue].customMenuCounts[toppingIndex].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.match_count,
                            "min": e.target.value,
                            "max": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.max,
                            "name": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.name
                        }
                        let CombinationIndex = customData[indexvalue].customMenuCounts.findIndex((item) => item.customDatas.id == '21')
                        customData[indexvalue].customMenuCounts[CombinationIndex].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.match_count,
                            "min": e.target.value,
                            "max": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.max,
                            "name": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.name
                        }
                    } else {
                        customData[indexvalue].customMenuCounts[index].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[index].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[index].customDatas.match_count,
                            "min": e.target.value,
                            "max": customData[indexvalue].customMenuCounts[index].customDatas.max,
                            "name": customData[indexvalue].customMenuCounts[index].customDatas.name
                        }
                    }
                }
                else if (type == "max") {
                    let indexvalue = customData.findIndex((item) => item.sizeID == defaultItemsize)
                    if (Ishybrid == "hybrid") {
                        let toppingIndex = customData[indexvalue].customMenuCounts.findIndex((item) => item.customDatas.id == '17')
                        customData[indexvalue].customMenuCounts[toppingIndex].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.match_count,
                            "max": e.target.value,
                            "min": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.min,
                            "name": customData[indexvalue].customMenuCounts[toppingIndex].customDatas.name
                        }
                        let CombinationIndex = customData[indexvalue].customMenuCounts.findIndex((item) => item.customDatas.id == '21')
                        customData[indexvalue].customMenuCounts[CombinationIndex].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.match_count,
                            "max": e.target.value,
                            "min": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.min,
                            "name": customData[indexvalue].customMenuCounts[CombinationIndex].customDatas.name
                        }
                    } else {
                        customData[indexvalue].customMenuCounts[index].customDatas
                            = {
                            "id": customData[indexvalue].customMenuCounts[index].customDatas.id,
                            "match_count": customData[indexvalue].customMenuCounts[index].customDatas.match_count,
                            "max": e.target.value,
                            "min": customData[indexvalue].customMenuCounts[index].customDatas.min,
                            "name": customData[indexvalue].customMenuCounts[index].customDatas.name

                        }
                    }
                }
                else
                    customData.find((item) => item.sizeID == defaultItemsize).customMenuCounts[index].customDatas.max = ''
                setcustomItemSize([...customData]);

                // let filter = [];
                // let arr = customItemSize;
                // if (arr.length) {
                //     let filter = arr.filter((item) => { return item.size_id != defaultItemsize })
                //     console.log(filter, 'customenuCountDatas =filter===');
                //     filter.push({
                //         size_id: defaultItemsize,
                //         data: customenuCountDatas
                //     })
                // } else {
                //     filter.push({
                //         size_id: defaultItemsize,
                //         data: customenuCountDatas
                //     })
                // }
                // let filter = arr.filter((item) => { return item.size_id != defaultItemsize })
                // console.log(filter, 'e.target =filter===');
                // filter.push({
                //     size_id: defaultItemsize,
                //     data: customenuCountDatas
                // })
                // setcustomItemSize([...filter])
                // console.log(filter, 'e.target --arr');
            }
        } else {
            toast.dismiss()
            toast.error('Choose Product Size')
        }
    }

    const changeMinMaxWeight = (e, index, name) => {
        if (defaultItemsize) {
            customenuCountWeight[index] = {
                "itemsize_name": name,
                "itemsize_id": customenuCountWeight[index].itemsize_id ? customenuCountWeight[index].itemsize_id : defaultItemsize,
                "weight_value": e.target.value
            }
            setcustomenuCountWeight([...customenuCountWeight]);
        }
        else {
            toast.dismiss()
            toast.error('Choose Product Size')
        }
    }

    const changeMinMax = (e, index, type) => {
        setminmaxerror(false);

        const customenuItem = customenuCountDatas[index];
        // Loop through each item in selectedDatas to find and update the matching item
        selectedDatas.forEach((selectedItem, selectedIndex) => {
            // Check if the custom_menu_id matches with customenuItem.id
            if (selectedItem.custom_menu_id === customenuItem.id) {
                // Update the default property of the matched item to 0
                selectedDatas[selectedIndex].default = 0;
            }
        });

        // Now selectedDatas contains the updated objects with default property set to 0 where matching custom_menu_id is found
        setselectedDatas(selectedDatas)
        // setTempSelectedDatas(selectedDatas)

        // Now selectedDatas contains the updated objects with default property set to 0 where matching custom_menu_id is found
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            if (type == "min")
                customenuCountDatas[index].min = e.target.value
            else if (type == "max")
                customenuCountDatas[index].max = e.target.value
            else
                customenuCountDatas[index].max = ''

            setcustomenuCountDatas([...customenuCountDatas]);
        }
    }

    // const ItemPrice = (price, row_data, index, item, items) => {
    //     console.log(item,row_data.id,'itemmmmm');
    //     let index_selected = _.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row_data.id });
    //     console.log(index_selected,price,'index_selected');
    //     if (index_selected < 0) {
    //         return parseFloat(price);
    //     } else {
    //         let item_index_selected = selectedDatas[index_selected].itemsize_id.indexOf(items.itemsize_id);
    //         console.log(index_selected,item_index_selected,Number(selectedDatas[index_selected]?.price[item_index_selected]),'index_selecteditem_index_selected');
    //         //console.log(selectedDatas[index_selected].itemsize_id, items,"item_index_selected")
    //         //console.log(item_index_selected,"item_index_selected")
    //         return selectedDatas[index_selected]?.price[item_index_selected];
    //         // let data = selectedDatas;
    //         // let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
    //         // if (index_selected >= 0 && data[index_selected].price[index] != "") {
    //         //     console.log(row_data, price, parseFloat(data[index_selected].price[index]), "ItemPrice1")
    //         //     return parseFloat(data[index_selected].price[index]);
    //         // }
    //     }

    // };

    const ItemPrice = (price, row_data, index, item, items) => {
        let index_selected = _.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row_data.id });

        if (index_selected < 0) {
            // If the item is not found in selectedDatas, return the original price
            return parseFloat(price);
        } else {
            // Find the index of the item size ID in the selectedDatas array
            let item_index_selected = selectedDatas[index_selected].itemsize_id.indexOf(items.itemsize_id);

            if (item_index_selected !== -1) {
                // If the item size ID is found, return its corresponding price
                return selectedDatas[index_selected].price[item_index_selected];
            } else {
                // If the item size ID is not found, handle the error gracefully
                console.error('Item size ID not found in selectedDatas:', items.itemsize_id);
                return parseFloat(price); // Return the original price or handle the error as needed
            }
        }
    };

    const ChangeItemPrice = (e, index, item, items) => {
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                let item_index_selected = data[index_selected].itemsize_id.indexOf(items.itemsize_id);
                data[index_selected].price[item_index_selected] = e.target.value !== '' ? Number(e.target.value) : e.target.value;
            }
            setselectedDatas([...data]);
            // setTempSelectedDatas([...data])
        }
    };

    const ItemPoints = (price, row_data, index, item, items) => {
        let index_selected = _.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row_data.id });
        if (index_selected < 0) {
            return parseFloat(price);
        } else {
            let item_index_selected = selectedDatas[index_selected].itemsize_id.indexOf(items.itemsize_id);
            //console.log(selectedDatas[index_selected].itemsize_id, items,"item_index_selected")
            //console.log(item_index_selected,"item_index_selected")
            return selectedDatas[index_selected]?.points[item_index_selected];
            // let data = selectedDatas;
            // let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            // if (index_selected >= 0 && data[index_selected].price[index] != "") {
            //     console.log(row_data, price, parseFloat(data[index_selected].price[index]), "ItemPrice1")
            //     return parseFloat(data[index_selected].price[index]);
            // }
        }

    };

    const ChangeItemPoints = (e, index, item, items) => {
        const re = /^\d*\.?\d*$/
        if (e.target.value.match(re) || e.target.value == "") {
            let data = selectedDatas;
            let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
            if (index_selected >= 0) {
                let item_index_selected = data[index_selected].itemsize_id.indexOf(items.itemsize_id);
                data[index_selected].points[item_index_selected] = e.target.value;
            }
            setselectedDatas([...data]);
            // setTempSelectedDatas([...data])
        }
    };

    const TabName = (row) => {

        let minValue;
        let maxValue;

        const get_arr = _.filter(customenuCountDatas, { name: row?.name });

        if (categoryId === 4) {
            const filterMatchedObject = customItemSize.filter(item => Number(item.sizeID) === Number(defaultItemsize));
            if (filterMatchedObject) {
                const matchedObject = filterMatchedObject.flatMap(item => item.customMenuCounts)?.find(({ customDatas }) => {
                    return Number(customDatas.id) === Number(row.id)
                });
                minValue = matchedObject?.customDatas?.min
                maxValue = matchedObject?.customDatas?.max
            }
        }
        else {
            if (get_arr.length > 0) {
                minValue = get_arr[0].min;
                maxValue = get_arr[0].max
            }
        }

        if (get_arr.length > 0 && is_weight === '0') {
            // minValue = get_arr[0].min;
            // maxValue = get_arr[0].max
            return row?.name + " (" + minValue + ", " + maxValue + ")";
        }
        else
            return row?.name;
    };

    const selectAll = (row, tindex) => {
        if (typeof (selectallitem[tindex]) === "undefined") {
            selectallitem[tindex] = true
        } else {
            selectallitem[tindex] = !selectallitem[tindex];
        }
        setselectallitem(selectallitem);
        if (row.custom_menu_items && row.custom_menu_items.length > 0) {
            if (selectallitem[tindex]) {
                row.custom_menu_items.map((item, index) => {
                    if (typeof (rowcheckbox[tindex]) === "undefined") {
                        rowcheckbox.push(tindex);
                        rowcheckbox[tindex] = [index];
                    } else {
                        let indexdata = _.indexOf(rowcheckbox[tindex], index)
                        if (indexdata < 0)
                            rowcheckbox[tindex].push(index);

                        rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                            return element !== undefined;
                        });
                    }
                    setrowcheckbox([...rowcheckbox]);
                    let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                    let item_price = _.fill(Array(chosenItems.length), item.price);
                    let item_points = _.fill(Array(chosenItems.length), 0);
                    let data = selectedDatas;
                    let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                    if (index_selected < 0) {
                        data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, points: item_points, itemsize_id: itemsize_ids, item_price: item.price, default: 0, position: item.position });
                    } else {
                        //_.unset(data, index_selected);
                    }
                    data = data.filter(function (element) {
                        return element !== undefined;
                    });
                    //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                    setselectedDatas([...data])
                    // setTempSelectedDatas([...data])
                })
            } else {
                row.custom_menu_items.map((item, index) => {
                    if (typeof (rowcheckbox[tindex]) === "undefined") {
                        // rowcheckbox.push(tindex);
                        // console.log(rowcheckbox, "rowcheckbox")
                        // rowcheckbox[tindex] = [index];
                    } else {
                        let indexdata = _.indexOf(rowcheckbox[tindex], index)
                        if (indexdata >= 0)
                            _.unset(rowcheckbox[tindex], indexdata);
                        else
                            rowcheckbox[tindex].push(index);

                        rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                            return element !== undefined;
                        });
                    }
                    setrowcheckbox([...rowcheckbox]);
                    let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                    let item_price = _.fill(Array(chosenItems.length), item.price);
                    let item_points = _.fill(Array(chosenItems.length), 0);
                    let data = selectedDatas;
                    let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                    if (index_selected < 0) {
                        //data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                    } else {
                        _.unset(data, index_selected);
                    }
                    data = data.filter(function (element) {
                        return element !== undefined;
                    });
                    //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                    setselectedDatas([...data])
                    // setTempSelectedDatas([...data])
                    let defaultdata = defaultSelect;
                    let dindex_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
                    if (dindex_selected >= 0) {
                        _.unset(defaultdata, dindex_selected);

                        defaultdata = defaultdata.filter(function (element) {
                            return element !== undefined;
                        });
                        setDefaultSelect(defaultdata);
                    }
                })
            }
        }
        /* if (row.custom_menu_items && row.custom_menu_items.length > 0) {
            row.custom_menu_items.map((item, index) => {
                if (typeof (rowcheckbox[tindex]) === "undefined") {
                    rowcheckbox.push(tindex);
                    console.log(rowcheckbox, "rowcheckbox")
                    rowcheckbox[tindex] = [index];
                }
                else {
                    let indexdata = _.indexOf(rowcheckbox[tindex], index)
                    console.log(indexdata, "indexdata")
                    if (indexdata >= 0)
                        _.unset(rowcheckbox[tindex], indexdata);
                    else
                        rowcheckbox[tindex].push(index);
     
                    rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                        return element !== undefined;
                    });
                }
                console.log(rowcheckbox, "rowcheckbox")
                //rowcheckbox[index] = (rowcheckbox[index]) ? true : false;
                setrowcheckbox([...rowcheckbox]);
                let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
                let item_price = _.fill(Array(chosenItems.length), item.price);
                let data = selectedDatas;
                let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
                if (index_selected < 0) {
                    data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
                } else {
                    _.unset(data, index_selected);
                }
                data = data.filter(function (element) {
                    return element !== undefined;
                });
                //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
                setselectedDatas([...data])
            })
     
        } */
    };

    const selectItems = (row, tindex, index, item) => {
        setcustomerror(false);
        if (typeof (rowcheckbox[tindex]) === "undefined") {
            rowcheckbox.push(tindex);
            rowcheckbox[tindex] = [index];
        }
        else {

            let indexdata = _.indexOf(rowcheckbox[tindex], index)
            if (indexdata >= 0)
                _.unset(rowcheckbox[tindex], indexdata);
            else
                rowcheckbox[tindex].push(index);

            rowcheckbox[tindex] = rowcheckbox[tindex].filter(function (element) {
                return element !== undefined;
            });
        }
        //rowcheckbox[index] = (rowcheckbox[index]) ? true : false;
        setrowcheckbox([...rowcheckbox]);
        let itemsize_ids = chosenItems.map((value) => value.itemsize_id);
        let item_price = _.fill(Array(chosenItems.length), item.price);
        let item_points = _.fill(Array(chosenItems.length), 0);
        let data = selectedDatas;
        let index_selected = _.findIndex(selectedDatas, { item_id: item.id });
        if (index_selected < 0) {
            data.push({ item_name: item.name, custom_menu_id: row.id, item_id: item.id, price: item_price, points: item_points, itemsize_id: itemsize_ids, item_price: item.price, default: 0 });
        } else {
            _.unset(data, index_selected);
        }
        data = data.filter(function (element) {
            return element !== undefined;
        });
        //let selected = { custom_menu_id: row.id, item_id: item.id, price: item_price, itemsize_id: itemsize_ids };
        setselectedDatas([...data])
        // setTempSelectedDatas([...data])
        let defaultdata = defaultSelect;
        let dindex_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
        if (dindex_selected >= 0) {
            _.unset(defaultdata, dindex_selected);

            defaultdata = defaultdata.filter(function (element) {
                return element !== undefined;
            });
            setDefaultSelect(defaultdata);
        }
        if (typeof (selectallitem[tindex]) === "undefined") {
            selectallitem[tindex] = false
        } else {
            let count = row.custom_menu_items.length;
            let datas = _.filter(selectedDatas, { "custom_menu_id": row.id })
            if (count == datas.length)
                selectallitem[tindex] = true;
            else
                selectallitem[tindex] = false;
        }
        setselectallitem(selectallitem)
    };

    const checkAllSelected = (tindex, row) => {
        if (typeof (selectallitem[tindex]) === "undefined") {
            selectallitem[tindex] = false
            return false;
        } else {
            let count = row.custom_menu_items.length;
            let datas = _.filter(selectedDatas, { "custom_menu_id": row.id })
            if (count == datas.length) {
                selectallitem[tindex] = true;
                return true;
            }
            else {
                selectallitem[tindex] = false;
                return false;
            }
        }
        setselectallitem(selectallitem)
    };

    // useEffect(() => {
    //     if (custommenuDatabyID && custommenuDatabyID.length <= 0) {
    //         let postData = { ids: [] }
    //         console.log(postData, 'postData==5');

    //         dispatch(custommenuDatabyID({ postData }))
    //     }

    // }, [custommenuDatabyID])

    // const selectDefault = (row, tindex, rindex, item) => {
    //     if (is_weight == 1) {
    //         let existingCustomWeights;
    //         if (defaultSelect.length) {
    //             existingCustomWeights = (Number(customWeightTotal) + Number(item.weight));
    //         } else {
    //             existingCustomWeights = Number(item.weight)
    //         }
    //         let icecreamCustomWeight;
    //         if (editDatas.custom_weight && editDatas.custom_weight.length) {
    //             let customWeightData = _.find(editDatas.custom_weight, { itemsize_id: String(defaultItemsize) })
    //             icecreamCustomWeight = Number(customWeightData.weight_value)
    //         } else {
    //             icecreamCustomWeight = Number(weightValue)
    //         }
    //         let data = defaultSelect;
    //         let datadefault = 0;
    //         let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
    //         if (index_selected < 0) {
    //             if ((Number(existingCustomWeights) <= Number(icecreamCustomWeight))) {
    //                 setselectedDatas([...selectedDatas])

    //                 data.push({ custom_menu_id: row.id, item_id: item.id, weight: Number(item.weight) });
    //                 datadefault = 1;
    //             }
    //             else {
    //                 // window.alert('Maximum Weight limit exceeded for this Product!.')
    //                 weightError()
    //                 // setOpenModal(true)


    //             }
    //         } else {
    //             _.unset(data, index_selected);
    //         }
    //         // console.log(data, selectedDatas, 'index_selected==data');
    //         data = data.filter(function (element) {
    //             return element !== undefined;
    //         });
    //         //let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
    //         selectedDatas.map((datas) => {
    //             console.log(datas.item_id == item.id && datas.custom_menu_id == row.id, 'index_selected==2');
    //             if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
    //                 datas.default = datadefault;
    //                 return datas;
    //             }
    //         });
    //         let addweight = 0;
    //         if (data.length > 0) {
    //             addweight = data.reduce((a, b) => Number(a) + Number(b.weight), 0);
    //         }
    //         setcustomWeightTotal(addweight);
    //         defaultSelect = data;
    //         setselectedDatas([...selectedDatas])
    //         setDefaultSelect(data);

    //     } else {
    //         let getcustomdatas = _.filter(defaultSelect, { custom_menu_id: row.id });
    //         let minValue;
    //         let maxValue;

    //         if (categoryId === 4) {
    //             const filterMatchedObject = customItemSize.filter(item => item.sizeID === defaultItemsize.toString());
    //             if (filterMatchedObject) {
    //                 const matchedObject = filterMatchedObject.flatMap(item => item.customMenuCounts)?.find(({ customDatas }) => {
    //                     return Number(customDatas.id) === Number(row.id)
    //                 });
    //                 minValue = matchedObject?.customDatas?.min
    //                 maxValue = matchedObject?.customDatas?.max
    //             }
    //         }

    //         let data = defaultSelect;
    //         let countminmax = _.filter(customenuCountDatas, { id: row.id });
    //         let get_min = categoryId === 4 ? minValue : countminmax[0]['min'];
    //         let get_max = categoryId === 4 ? maxValue : countminmax[0]['max'];
    //         let datadefault = 0;
    //         let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
    //         console.log(data,countminmax,get_min,get_max,index_selected,'data,countminmax,get_min,get_max,index_selected');
    //         if (index_selected < 0) {
    //             let getcustomdatas = _.filter(data, { custom_menu_id: row.id });
    //             let getcustomdatasindex = _.findIndex(data, { custom_menu_id: row.id });
    //             if (getcustomdatas.length >= parseInt(get_max)) {
    //                 var lastItem = getcustomdatas.pop();
    //                 _.unset(data, getcustomdatasindex);

    //                 selectedDatas.map((datas) => {
    //                     if (datas.item_id == lastItem.item_id && datas.custom_menu_id == lastItem.custom_menu_id) {
    //                         datas.default = 0;
    //                         return datas;
    //                     }
    //                 });

    //                 setselectedDatas([...selectedDatas])
    //                 //data.splice(-1);
    //                 //return false;
    //             }
    //             data.push({ custom_menu_id: row.id, item_id: item.id });
    //             datadefault = 1;
    //         } else {
    //             _.unset(data, index_selected);
    //         }
    //         data = data.filter(function (element) {
    //             return element !== undefined;
    //         });
    //         //let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
    //         selectedDatas.map((datas) => {
    //             if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
    //                 datas.default = datadefault;
    //                 return datas;
    //             }
    //         });

    //         // tempSelectedDatas.map((datas) => {
    //         //     if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
    //         //         datas.default = datadefault;
    //         //         return datas;
    //         //     }
    //         // });

    //         defaultSelect = data;
    //         setselectedDatas([...selectedDatas])
    //         setDefaultSelect(data);

    //     }
    // };


    const selectDefault = (row, tindex, rindex, item) => {
        if (is_weight == 1) {
            let existingCustomWeights
            if (defaultSelect.length) {
                existingCustomWeights = (Number(customWeightTotal) + Number(item.weight));
            } else {
                existingCustomWeights = Number(item.weight)
            }
            let icecreamCustomWeight;
            if (editDatas.custom_weight && editDatas.custom_weight.length) {
                let customWeightData = _.find(editDatas.custom_weight, { itemsize_id: String(defaultItemsize) })
                icecreamCustomWeight = Number(customWeightData.weight_value)
            } else {
                icecreamCustomWeight = Number(weightValue)
            }
            let data = defaultSelect;
            let datadefault = 0;
            let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
            let totalWeight = Number(existingCustomMenuItemWeight) + Number(item.weight)
            if (index_selected < 0) {
                // if ((Number(existingCustomWeights) <= Number(icecreamCustomWeight))) {
                if ((Number(totalWeight) <= Number(icecreamCustomWeight))) {
                    setselectedDatas([...selectedDatas])
                    data.push({ custom_menu_id: row.id, item_id: item.id, weight: Number(item.weight) });
                    datadefault = 1;
                }
                else {
                    // window.alert('Maximum Weight limit exceeded for this Product!.')
                    weightError()
                    // setOpenModal(true)
                }
            } else {
                _.unset(data, index_selected);
            }
            // console.log(data, selectedDatas, 'index_selected==data');
            data = data.filter(function (element) {
                return element !== undefined;
            });
            //let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
            selectedDatas.map((datas) => {
                if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
                    datas.default = datadefault;
                    return datas;
                }
            });
            setselectedDatas([...selectedDatas])
            let addweight = 0;
            if (data.length > 0) {
                //since some objects was not having the property weight so nan issue occured
                const filteredData = data.filter(item => item.hasOwnProperty('weight'));
                addweight = filteredData.reduce((a, b) => Number(a) + Number(b.weight), 0);
            }
            setcustomWeightTotal(addweight);
            defaultSelect = data;
            setDefaultSelect(data);

        } else {
            let getcustomdatas = _.filter(defaultSelect, { custom_menu_id: row.id });
            let minValue;
            let maxValue;

            if (categoryId === 4) {
                // Your logic to get minValue and maxValue based on categoryId
            }

            let data = defaultSelect;
            let countminmax = _.filter(customenuCountDatas, { id: row.id });
            let get_min = categoryId === 4 ? minValue : countminmax[0]['min'];
            let get_max = categoryId === 4 ? maxValue : countminmax[0]['max'];
            let datadefault = 0;
            let index_selected = _.findIndex(defaultSelect, { item_id: item.id, custom_menu_id: row.id });
            if (index_selected < 0) {
                let getcustomdatas = _.filter(data, { custom_menu_id: row.id });
                let getcustomdatasindex = _.findIndex(data, { custom_menu_id: row.id });
                if (getcustomdatas.length >= parseInt(get_max)) {
                    // Display error toast if maximum limit is reached
                    toast.dismiss()
                    toast.error('Maximum limit reached for this custom menu!');
                    return; // Exit function without adding the new item
                }
                data.push({ custom_menu_id: row.id, item_id: item.id });
                datadefault = 1;
            } else {
                _.unset(data, index_selected);
            }
            data = data.filter(function (element) {
                return element !== undefined;
            });
            //let seledata = _.filter(selectedDatas, {item_id: item.id, custom_menu_id: row.id});
            selectedDatas.map((datas) => {
                if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
                    datas.default = datadefault;
                    return datas;
                }
            });

            // tempSelectedDatas.map((datas) => {
            //     if (datas.item_id == item.id && datas.custom_menu_id == row.id) {
            //         datas.default = datadefault;
            //         return datas;
            //     }
            // });

            defaultSelect = data;
            setselectedDatas([...selectedDatas])
            setDefaultSelect(data);
            // Your remaining logic for updating selectedDatas, defaultSelect, etc.
        }
    };


    // console.log(selectedDatas, defaultSelect, 'data,countminmax,get_min,get_max,index_selected1');

    const zoomIn = () => {
        setScale(scale * 2);
    }

    const zoomOut = () => {
        setScale(scale / 2);
    }

    const resetFormFields = () => {
        dispatch(clearSubCatgDatas());
        setSubcategoryOptions([])
        let datas = formFields.map((data, index) => {
            if (data.name === "category_id")
                data.value = [];
            else if (data.name === "subcategory_id")
                data.value = [];
            else if (data.name === "itemsize_ids")
                data.value = [];
            else if (data.name === "default_size")
                data.value = [];
            else if (data.name === "uom")
                data.value = 0;
            else if (data.name === "weight") {
                data.class = "";
                data.validation = "required";
                data.value = "";
            }
            else if (data.name === "custom_menus")
                data.value = 0;
            else if (data.name === "status")
                data.value = [{ id: "1", name: "Active" }];
            else
                data.value = "";
            return data
            //return datas.push(data);
        })
        //console.log(datas, "datas")
        setFormFields(datas)
    };

    useEffect(() => {
        resetFormFields();
        dispatch(categoryData({}));
        dispatch(itemsizeData({ status: 1 }));
        //dispatch(subcategoryData({}));
        dispatch(custommenuData({ status: 1 }));
        if (editID) {
            dispatch(productById({ editID }));
            dispatch(productCustomProducts({ editID }));
        }
        simpleValidator.current.showMessages()
    }, [])

    useEffect(() => {
        let tempData = customenuCountDatas
        for (let i in tempData) {
            let findEle = _.find(custommenuDatasbyID, { id: tempData[i].id })
            tempData[i].match_count = findEle && findEle.custom_menu_items && findEle.custom_menu_items.length
        }
        setcustomenuCountDatas([...tempData])

    }, [custommenuDatasbyID])

    useEffect(() => {
        if (categoryOptions && categoryOptions.length == 0) {
            if (categoryDatas && categoryDatas.length > 0) {
                let catdata = [];
                categoryDatas.map((data) => {
                    return catdata.push({ id: data.id, name: data.name })
                });
                setCategoryOptions(catdata)
            }
        }
        if (itemSizeOptions && itemSizeOptions.length == 0) {
            if (itemsizeDatas && itemsizeDatas.length > 0) {
                let itemsizeoptions = [];
                itemsizeDatas.map((data) => {
                    return itemsizeoptions.push({ id: data.id, name: data.description })
                });
                setItemSizeOptions(itemsizeoptions)
            }
        }
        if (subcategoryOptions && subcategoryOptions.length == 0) {
            if (subcategoryDatas && subcategoryDatas.length > 0) {
                let subcategoryOption = [];
                subcategoryDatas.map((data) => {
                    return subcategoryOption.push({ id: data.id, name: data.name, hybrid_category: data.hybrid_category })
                });
                setSubcategoryOptions(subcategoryOption)
                if (editDatas && typeof (editDatas.subcategory_id) !== "undefined") {
                    let from_index = _.findIndex(formFields, { name: "subcategory_id" });
                    let set_status = _.filter(subcategoryOption, { id: editDatas.subcategory_id });
                    if (set_status.length > 0) {
                        formFields[from_index].value = set_status;
                    }
                }
            }
        }
        //if (defaultSizeOptions && defaultSizeOptions.length == 0) {
        if (chosenItems && chosenItems.length >= 0) {
            let defaultSizeOptions = [];
            let customenuWeight = [];
            let chosendata = chosenItems.filter(function (element) {
                return element !== undefined;
            });
            chosendata.map((data) => {
                return defaultSizeOptions.push({ id: data.itemsize_id, name: data.name })
            });
            //Weight Array Form if it has values
            let weightArray = [];
            if (subId == 4) {
                let row = productDataById
                if (row.custom_weight && row.custom_weight.length > 0) {
                    row.custom_weight && row.custom_weight.map((item) => {
                        weightArray.push({
                            itemsize_id: item.itemsize_id,
                            itemsize_name: item.itemsize_name,
                            weight_value: item.weight_value
                        })
                    })
                }
            }
            chosendata.map((item) => {
                return customenuWeight.push({
                    itemsize_id: item.itemsize_id,
                    itemsize_name: item.name,
                    weight_value: weightArray && weightArray.length && weightArray.find(data => data.itemsize_id == item.itemsize_id) ? weightArray && weightArray.length && weightArray.find(data => data.itemsize_id == item.itemsize_id).weight_value : 0
                })
            })
            // }
            setcustomenuCountWeight([...customenuWeight])
            setDefaultSizeOptions([...defaultSizeOptions])
        }

        if (editDatas && editDatas.length == 0 && productDataById) {
            setEditDatas(productDataById);
        }
        //}
        if (customMenuOptions && customMenuOptions.length == 0) {
            if (custommenuDatas && custommenuDatas.length > 0) {
                let customMenuOptions = [];
                custommenuDatas.map((data) => {
                    return customMenuOptions.push({ id: data.id, name: data.name })
                });
                setCustomMenuOptions(customMenuOptions)
            }
        }

    }, [itemsizeDatas, categoryDatas, subcategoryDatas, chosenItems, custommenuDatas, editDatas, productDataById])

    useEffect(() => {
        console.log(customProductDatas, editDatas, 'daataaaa');

        let tempArray = []
        if (editDatas && typeof (editDatas.name) !== "undefined" && customProductDatas) {
            const row = editDatas;
            setUpdateID(row.id);
            setCategoryId(row.category_id)
            if (row.hybrid_product == 1) {
                setHybridProduct(true)
            } else {
                setHybridProduct(false)
            }
            let chosen_data = []
            let data = formFields.map((data, index) => {
                //console.log(_.filter(statusDropdown, { id: row.status }));
                if (data.name === "name")
                    data.value = row.name;
                if (data.name === "item_code")
                    data.value = row.item_code;
                if (data.name === "additional_information")
                    data.value = row.additional_information;
                if (data.name === "description")
                    data.value = row.description;
                if (data.name === "weight")
                    data.value = row.weight;
                if (row.is_weight == '1' && row.category_id == 4) {
                    let get_index = _.findIndex(formFields, { name: "weight" })
                    if (get_index >= 0) {
                        formFields[get_index].class = "hide";
                        formFields[get_index].validation = "";
                    }
                }
                if (data.name === "uom") {
                    if (row.hybrid_product && row.hybrid_product == 1) {
                        let get_index = _.findIndex(formFields, { name: "uom" })
                        if (get_index >= 0) {
                            formFields[get_index].class = "hide";
                            formFields[get_index].validation = "";
                        }

                        let get_indexs = _.findIndex(formFields, { name: "weight" })
                        if (get_indexs >= 0) {
                            formFields[get_indexs].class = "hide";
                            formFields[get_indexs].validation = "";
                        }

                    } else {
                        data.value = row.uom;
                        if (row.uom == 0) {
                            let get_index = _.findIndex(formFields, { name: "weight" })
                            if (get_index >= 0) {
                                formFields[get_index].class = "";
                                formFields[get_index].validation = "required";
                            }
                        }

                        if (row.uom == 1) {
                            let get_index = _.findIndex(formFields, { name: "weight" })
                            if (get_index >= 0) {
                                formFields[get_index].class = "hide";
                                formFields[get_index].validation = "";
                            }
                        }
                    }
                }

                if (data.name === "reward") {
                    data.value = row.reward;
                    if (row.reward == 1)
                        setRewardhide(true);
                    else
                        setRewardhide(false);
                }
                if (data.name === "status") {
                    let set_status = _.filter(statusDropdown, { id: row.status });
                    data.value = set_status;
                }
                if (data.name === "category_id") {
                    let postData = { category_id: row.category_id };
                    dispatch(subcategoryFilterData({ postData }))
                    let set_status = _.filter(categoryOptions, { id: row.category_id });
                    data.value = set_status;
                }
                if (data.name === "subcategory_id") {
                    let set_status = _.filter(subcategoryOptions, { id: row.subcategory_id });
                    data.value = set_status;
                    // if (set_status && set_status[0] == 189) {
                    //     setHybridProduct(true)
                    // } else {
                    //     setHybridProduct(false)
                    // }
                }
                if (data.name === "itemsize_ids") {
                    let set_status = [];
                    let ordered_itemSize = []
                    row.price.map((price, index) => {
                        let datam = _.filter(itemSizeOptions, { id: price.itemsize_id });
                        if (datam.length > 0) {
                            set_status.push(datam[0]);
                            chosen_data.push({ name: datam[0].name, itemsize_id: datam[0].id, price: price?.price, points: price?.points, position: price?.position });
                            ordered_itemSize.push({ id: datam[0].id, name: datam[0].name, position: price?.position })
                        }
                    });
                    // chosenItems = chosen_data;
                    // setChosenItems([...chosen_data])
                    let orderedArray = _.orderBy(chosen_data, ['position'], ['asc']);
                    let newOrderedArray = _.orderBy(ordered_itemSize, ['position'], ['asc']);
                    chosenItems = orderedArray;
                    setChosenItems([...orderedArray])
                    setdeleteItems([...orderedArray])
                    // data.value = set_status;
                    data.value = newOrderedArray;
                }
                if (data.name === "default_size") {
                    let defaultSizeValue = [];
                    let set_status = _.filter(chosen_data, { itemsize_id: row.default_size });
                    defaultSizeValue.push({ id: set_status[0]?.itemsize_id, name: set_status[0]?.name })
                    setDefaultItemsize(row.default_size)
                    // tempArray.push()
                    data.value = defaultSizeValue;
                }
                if (data.name === "image") {
                    data.value = assestURL + row.image;
                    setMerchImageLength(false)
                }
                if (row.category_id === 5 && data.name === "image") {
                    if (row?.merchimages?.length > 0) {
                        setMerchImageLength(true)
                        let imgs = row.merchimages && row.merchimages.length > 0 && row.merchimages[0] && row.merchimages[0].images && row.merchimages[0].images.split(",");
                        let img_urls = [];
                        let existing_image = []
                        imgs.forEach((file) => {
                            if (file != '') {
                                img_urls.push(assestURL + file);
                            }
                            existing_image.push(file)
                        });
                        setExistingImage(existing_image)
                        data.value = img_urls;
                    }
                    else {
                        let temp = []
                        temp.push(row.image)
                        setExistingImage(temp)
                        data.value = [assestURL + row.image];
                    }
                }
                if (data.name === "custom_menus") {
                    data.value = row.custom_menus;
                    setCustom_menu(row.custom_menus);
                }

                return data;
            });
            if (row.custom_weight && row.custom_weight.length > 0) {
                let weightArray = [];
                row.custom_weight && row.custom_weight.map((item) => {
                    weightArray.push({
                        itemsize_id: item.itemsize_id,
                        itemsize_name: item.itemsize_name,
                        weight_value: item.weight_value
                    })
                })
                setcustomenuCountWeight([...weightArray])
            }
            if (row.custom_size && row.custom_size.length > 0) {
                row.custom_size.map((item) => {
                    let custom_Menu_name = item.item_name?.split(',')
                    let custom_Menu_Id = item.custom_menu_id?.split(',')
                    let custom_Min = item.custom_min?.split(',')
                    let custom_Max = item.custom_max?.split(',')
                    let temp = []
                    custom_Menu_name && custom_Menu_name.length > 0 && custom_Menu_name.map((items, index) => {
                        let matchCount = _.find(customenuCountDatas, { id: custom_Menu_Id[index] })
                        temp.push({
                            customDatas: {
                                id: custom_Menu_Id[index],
                                name: items,
                                match_count: matchCount ? matchCount?.match_count : null,
                                max: custom_Max[index],
                                min: custom_Min[index]

                            }
                        })


                    })
                    tempArray.push({
                        sizeID: item.itemsize_id,
                        customMenuCounts: temp

                    })
                })
                setcustomItemSize([...tempArray])

                if (row.category_id == 4) {
                    setSubId(row.category_id)
                    setDefaultItemsize(row.default_size)
                }
            } else if (row.category_id == 4) {
                let arr = []
                let customMenus;
                let itemSizes = chosenItems.map((item) => { return item.itemsize_id })
                let custom_ids = _.split(row.custom_menu_ids, ",");
                let custom_min = _.split(row.custom_menu_min, ",");
                let custom_max = _.split(row.custom_menu_max, ",");
                let existingData = [];
                custom_ids.map((items, index) => {
                    if (items != '') {
                        let datam = _.filter(customMenuOptions, { id: parseInt(items) });
                        if (datam.length > 0) {
                            // selectedCustomMenu.push(datam[0]);
                            existingData.push({ name: datam[0].name, id: parseInt(items), min: custom_min[index], max: custom_max[index] });
                        }
                    }
                });

                let mergeCustom = itemSizes.map((item) => { return existingData.map((item1) => { return arr.push({ sizeID: item, customDatas: item1 }) }) })

                const result = Object.values(arr.reduce((acc, cur) => {
                    acc[cur.sizeID] = acc[cur.sizeID] || { sizeID: cur.sizeID, customMenuCounts: [] };
                    acc[cur.sizeID].customMenuCounts.push({ customDatas: cur.customDatas });
                    return acc;
                }, {}))

                setcustomItemSize([...result])
                // setDefaultItemsize(itemSizes[0])
            }
            console.log(row, "row===");

            if (row.custom_menus == 1) {
                // let filterData=_.find(row.custom_size,{})
                let custom_ids = [];
                let custom_min;
                let custom_max;

                if (row.category_id == 4 && row.is_weight == '0' && row.custom_size && row.custom_size.length > 0) {
                    row.custom_size.map((item) => {
                        custom_ids = item.custom_menu_id?.split(',')
                        custom_min = item.custom_min?.split(',')
                        custom_max = item.custom_max?.split(',')
                    })
                } else {
                    custom_ids = _.split(row.custom_menu_ids, ",");
                    custom_min = _.split(row.custom_menu_min, ",");
                    custom_max = _.split(row.custom_menu_max, ",");
                    // let custom_ids1 = _.split(row.custom_menu_ids, ",");
                    // let custom_min1 = _.split(row.custom_menu_min, ",");
                    // let custom_max1 = _.split(row.custom_menu_max, ",");
                }
                console.log(custom_ids, row.custom_menu_ids, row?.custom_size, customMenuOptions, "custom-ids");

                custom_ids.map((items, index) => {
                    if (items != '') {
                        let datam = _.filter(customMenuOptions, { id: parseInt(items) });
                        if (datam.length > 0) {
                            selectedCustomMenu.push(datam[0]);
                            customenuCountDatas.push({ name: datam[0].name, id: parseInt(items), min: custom_min[index], max: custom_max[index] });
                        }
                    }
                });

                setDeleteCustomMenu(selectedCustomMenu);
                let selectedDatas = selectedCustomMenu.filter((obj, index) => {
                    return index === selectedCustomMenu.findIndex(o => obj.id === o.id);
                });
                setSelectedCustomMenu(selectedDatas);
                setcustomenuCountDatas([...customenuCountDatas]);
                //customenuCountDatasdata.push({ name: items.name, id: items.id, min: 0, max: 1 });
                let menuIds = selectedCustomMenu.map(value => value.id);
                let postData = { ids: menuIds }

                dispatch(custommenuDatabyID({ postData }));
                let items_arr = [];
                let default_arr = [];
                customProductDatas && customProductDatas.length && customProductDatas.map((item) => {
                    let item_index = _.findIndex(items_arr, { item_id: item.custom_item_id, custom_menu_id: item.custom_menu_id });
                    if (item_index < 0) {
                        let item_price = [parseFloat(item.price)];
                        let item_points = [parseFloat(item.points)];
                        let itemsize_ids = [parseInt(item.itemsize_id)];
                        items_arr.push({ item_name: item.item_name, custom_menu_id: item.custom_menu_id, item_id: item.custom_item_id, price: item_price, points: item_points, itemsize_id: itemsize_ids, item_price: item.price, default: item.default_select })
                    } else {
                        items_arr[item_index].price.push(parseFloat(item.price));
                        items_arr[item_index].points.push(parseFloat(item.points));
                        items_arr[item_index].itemsize_id.push(parseInt(item.itemsize_id));
                    }
                    let default_index = _.findIndex(default_arr, { item_id: item.custom_item_id, custom_menu_id: item.custom_menu_id });
                    if (default_index < 0 && item.default_select == 1) {
                        default_arr.push({ custom_menu_id: item.custom_menu_id, item_id: item.custom_item_id })
                    }
                });
                setprevselectedItem([...items_arr]);
                setselectedDatas([...items_arr]);
                setTempSelectedDatas([...items_arr])
                setDefaultSelect(default_arr);
                setIs_Weight(editDatas && editDatas.is_weight)
                //let custom_items =row.custom_items.map()
            }
            setFormFields(data);
        }
    }, [editDatas, customProductDatas])

    // useEffect(() => {
    //     if (tempFormFields && tempFormFields.length > 0) {
    //         setFormFields(tempFormFields)
    //     }
    // }, [tempFormFields])


    useEffect(() => {
        if (deleteloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Deleted Successfully!");
            dispatch(productData({}));
        }
        if (addloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Added Successfully!");
            navigate('/products');
            //dispatch(productData({}));

        }
        if (updateloading === API_STATUS.FULFILLED) {
            if (categoryId === 5) {
                // const existingImages = formFields && formFields[12] && formFields[12].value
                if (((existingImage && existingImage.length > 0) || (files && files.length > 0))) {
                    const filteredImages = existingImage && existingImage.length > 0 ? existingImage.filter(image => image !== '') : [];
                    dispatch(UpdateMerchImages({ filteredImages, updateID, files }))
                    toast.dismiss();
                    toast.success("Updated Successfully!");
                    dispatch(clearProdUpdateLoading())
                    navigate('/products');
                }
                else {
                    toast.dismiss();
                    toast.success("Updated Successfully!");
                    dispatch(clearProdUpdateLoading())
                    navigate('/products');
                }
            } else {
                toast.dismiss();
                toast.success("Updated Successfully!");
                dispatch(clearProdUpdateLoading())
                navigate('/products');
            }
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message)
                toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                })
                toast.error(data);
            }

            dispatch(clearErrormsg())

        }
    }, [deleteloading, addloading, updateloading, errorMessage]);


    // Function to update prices in selectedDatas based on the order of tempItemSizeIdsFromChosenItems
    function updatePrices(selectedDatas, tempItemSizeIdsFromChosenItems) {
        // Iterate over each item in selectedDatas
        selectedDatas.forEach(item => {
            // Get the index of the itemsize_id in tempItemSizeIdsFromChosenItems
            const index = tempItemSizeIdsFromChosenItems.indexOf(item.itemsize_id[0]);
            // If the index is found and the corresponding price exists, update the price in selectedDatas
            if (index !== -1 && item.price[index] !== undefined) {
                // Swap the prices based on the index
                [item.price[0], item.price[index]] = [item.price[index], item.price[0]];
                // Swap the itemsize_id based on the index
                [item.itemsize_id[0], item.itemsize_id[index]] = [item.itemsize_id[index], item.itemsize_id[0]];
            } else {
                // Log any problematic cases for debugging
                console.error('Error: Unable to update price for item:', item);
                console.error('Index:', index);
                console.error('Price at index:', item.price[index]);
            }
        });
    }

    //Set Custom menu items values based on chosenItem
    useEffect(() => {
        if (((chosenItems && chosenItems.length > 0) && (selectedDatas && selectedDatas.length > 0))) {

            let tempItemSizeIdsFromChosenItems = chosenItems.map((item) => {
                return item.itemsize_id
            })
            // let tempSelectedDatas = [...selectedDatas]

            // Call the function to update prices
            if (tempItemSizeIdsFromChosenItems) {
                updatePrices(selectedDatas, tempItemSizeIdsFromChosenItems);
            }


        }

    }, [chosenItems, selectedDatas])

    //Calculate Exisiting Custom menu selected items weight
    useEffect(() => {
        if (custommenuDatasbyID != null && selectedDatas != null) {
            let defaultSelectedItemsFiltered = _.filter(selectedDatas, { default: 1 })
            console.log(defaultSelectedItemsFiltered, 'defaultSelectedItemsFiltered');
            let customMenuItems = []
            for (let i in custommenuDatasbyID) {
                customMenuItems.push(custommenuDatasbyID[i].custom_menu_items)
            }
            if (customMenuItems && customMenuItems.length > 0) {
                const combinedArray = customMenuItems.flat();
                console.log(combinedArray, 'combinedArray');
                if (combinedArray && combinedArray.length > 0) {
                    let matchedItems = combinedArray.filter(item1 => defaultSelectedItemsFiltered.find(item2 => item2.item_id === item1.id));
                    console.log(matchedItems, 'matchedItems');
                    const sumWeight = matchedItems.reduce((acc, item) => acc + Number(item.weight), 0);
                    setIsExistingCustomMenuItemWeight(sumWeight);
                }
            }
            console.log(customMenuItems, 'customMenuItems');
        }

    }, [selectedDatas, custommenuDatasbyID])

    console.log(is_weight, 'is_weight');

    const containerStyle = {
        display: 'flex',
        overflowX: 'auto',
        padding: '5px',
        '&::-webkit-scrollbar': {
            width: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0f0f0',
        },
        '&::-webkit-scrollbar-corner': {
            backgroundColor: '#fff',
        },
    };

    const columnStyle = {
        border: '1px solid darkgrey',
        padding: '3px',
        margin: '3px',
        minWidth: '200px',
        height: '125px',
        boxShadow: '5px 5px 10px 5px #888888',
        marginRight: '10px',
    };

    const itemSizeColumnStyle = {
        border: '1px solid darkgrey',
        padding: '3px',
        margin: '3px',
        minWidth: '190px',
        height: '50px',
        boxShadow: '5px 5px 10px 5px #888888',
        marginRight: '10px',
    };

    // const onDragEnd = (result) => {
    //     if (!result.destination) {
    //         return; // Dragged outside the list
    //     }

    //     const newColumnOrder = Array.from(columnOrder);

    //     // Reorder the items in newColumnOrder based on the drag and drop
    //     const [removed] = newColumnOrder.splice(result.source.index, 1);
    //     newColumnOrder.splice(result.destination.index, 0, removed);

    //     console.log(newColumnOrder, 'newColumnOrder');
    //     // Update the position property based on the new order
    //     newColumnOrder.forEach((column, index) => {
    //         console.log(column, 'columncolumn');
    //         if (column && column.position) {
    //             column.position = index + 1;
    //         }
    //     });

    //     console.log(newColumnOrder, 'newColumnOrder----');
    //     // Update the state with the new columnOrder
    //     setColumnOrder(_.orderBy(newColumnOrder, ['position'], ['asc']));
    // };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return; // Dragged outside the list
        }

        const newColumnOrder = Array.from(columnOrder);

        // Reorder the items in newColumnOrder based on the drag and drop
        const [removed] = newColumnOrder.splice(result.source.index, 1);
        newColumnOrder.splice(result.destination.index, 0, removed);


        // Update the position property based on the new order
        const updatedColumnOrder = newColumnOrder.map((column, index) => {
            if (column && column.position) {
                // Create a new object to avoid modifying the original object
                return { ...column, position: index + 1 };
            }
            return column;
        });

        // Update the state with the new columnOrder
        setColumnOrder(_.orderBy(updatedColumnOrder, ['position'], ['asc']));
    };

    const itemSizeOnDragEnd = (result) => {
        if (sortSizes === true && result.destination) {


            let tempChosenItems = chosenItems && chosenItems.length && chosenItems.map((item, index) => ({
                ...item,
                position: index
            }))


            if (tempChosenItems && tempChosenItems.length > 0) {

                const newColumnOrder = Array.from(tempChosenItems);

                // Reorder the items in newColumnOrder based on the drag and drop
                const [removed] = newColumnOrder.splice(result.source.index, 1);
                newColumnOrder.splice(result.destination.index, 0, removed);


                // Update the position property based on the new order
                const updatedColumnOrder = newColumnOrder.map((column, index) => {
                    if (column && column.position != null) { // Check if position is not null or undefined
                        // Create a new object to avoid modifying the original object
                        return { ...column, position: index }; // Update position to match the index
                    }
                    return column;
                });

                // Update the state with the new columnOrder
                setChosenItems(updatedColumnOrder);

                // Map over chosenItems and extract itemsize_id, name, and position fields
                const updatedItemsizeIds = updatedColumnOrder.map(({ itemsize_id, name, position }) => ({
                    id: itemsize_id,
                    name: name,
                    position: position
                }));

                // Find the field in the formField array with the name "itemsize_ids"
                const itemsizeField = formFields.find(field => field.name === "itemsize_ids");

                // If the field is found, update its value with the updatedItemsizeIds array
                if (itemsizeField) {
                    itemsizeField.value = updatedItemsizeIds;
                }
            }

        }
    };

    // useEffect(() => {
    //     if (merchImageArray && merchImageArray.length === 1) {
    //         const imageUrl = merchImageArray[0].defaultPath; // Assuming 'path' is the property containing the image URL
    //         const response = axios.get(imageUrl, { responseType: 'arraybuffer' });

    //         // Convert array buffer to base64 using btoa
    //         const binaryData = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));

    //         console.log('Binary data of default image:', binaryData);
    //         setDefaultImageBinary('data:image/png;base64,' + binaryData); // Update the state with the binary data of the default image
    //     }

    // }, [merchImageArray])

    const onChangeDefaultImage = async (e) => {
        const index = e.target.value; // Assuming e.target.value is the index of the item to update

        // Fetch the binary data of the default image from the server
        try {
            const imageUrl = merchImageArray[index].defaultPath; // Assuming 'path' is the property containing the image URL
            const response = await axios.get(imageUrl, { responseType: 'arraybuffer' });

            // Convert array buffer to base64 using btoa
            const binaryData = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));

            setDefaultImageBinary('data:image/png;base64,' + binaryData); // Update the state with the binary data of the default image
        } catch (error) {
            console.error('Error fetching image binary data:', error);
        }

        setfileerror(false)
        setValue(index); // Assuming you also want to set the value state to the updated index
    };

    return (
        <div>
            <div className="container-fluid">
                <PageHeader
                    HeaderText="Products"
                    Breadcrumb={[{ name: "Products" }]}
                />
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="header">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2>Edit Products</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            {loading === 'Fulfilled' ?
                                <form className="ng-untouched ng-dirty ng-invalid">
                                    <div className="row p-10">
                                        {
                                            formFields && formFields.map((fields, index) => {
                                                if (fields?.name == 'reward') {
                                                    return "";
                                                } else {
                                                    return (
                                                        <div className={(fields.class == "hide") ? "col-md-6 hide" : "col-md-6"}>
                                                            <div className="form-group">
                                                                <label>{(_.includes(fields?.label, "*")) ? <>{_.replace(fields?.label, "*", "")} <span className="error">*</span></> : fields?.label}</label>

                                                                {(fields?.input == "text") ?
                                                                    <input
                                                                        className={`form-control ` + fields?.class}
                                                                        value={fields?.value}
                                                                        name={fields?.type}
                                                                        required=""
                                                                        onChange={(e) => { FieldChange(e, index) }}
                                                                    />
                                                                    : (fields?.input == "textarea") ?
                                                                        <textarea
                                                                            className="form-control"
                                                                            value={fields?.value}
                                                                            cols="30"
                                                                            name="textarea"
                                                                            required=""
                                                                            rows="2"
                                                                            onChange={(e) => {
                                                                                FieldChange(e, index)
                                                                            }}
                                                                        ></textarea>
                                                                        : (fields?.input == "dropdown") ?
                                                                            <>
                                                                                &nbsp;&nbsp;{(fields?.options == "itemsize" && itemSizeOptions.length > 0) ?
                                                                                    <>
                                                                                        <Button className="btn btn-primary btn-sm" onClick={() => {
                                                                                            setSortSizes(true)
                                                                                        }}>Sort Sizes</Button>
                                                                                        {sortSizes === true ?
                                                                                            <>
                                                                                                <button type="button" class="btn btn-outline-danger btn-sm ml-2" onClick={() => {
                                                                                                    setSortSizes(false)
                                                                                                }}>Close</button>
                                                                                            </>
                                                                                            : ''}
                                                                                    </>
                                                                                    : ''}
                                                                                < Select
                                                                                    className="js-states"
                                                                                    placeholder=""
                                                                                    options={(fields?.options == "status") ? statusDropdown : (fields?.options == "subcategory" && subcategoryOptions.length > 0) ? subcategoryOptions : (fields?.options == "category" && categoryOptions.length > 0) ? categoryOptions : (fields?.options == "itemsize" && itemSizeOptions.length > 0) ? itemSizeOptions : (fields?.options == "defaultsize" && defaultSizeOptions.length > 0) ? defaultSizeOptions : []}
                                                                                    values={(fields.value) ? fields?.value : []}
                                                                                    disabled={false}
                                                                                    multi={(fields.multiple) ? fields.multiple : false}
                                                                                    dropdownHandle={true}
                                                                                    searchable={true}
                                                                                    searchBy={"name"}
                                                                                    labelField={"name"}
                                                                                    valueField={"id"}
                                                                                    onChange={(value) => { DropdownChange(value, index, fields?.options); }}
                                                                                />
                                                                                {(fields?.dynamic_data && fields?.dynamic_data == true) ?
                                                                                    <>
                                                                                        {sortSizes === true ?
                                                                                            <Card className="mt-2 mb-2" style={{ width: '475px' }}>
                                                                                                <DragDropContext onDragEnd={itemSizeOnDragEnd}>
                                                                                                    <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                                                                                                        {(provided) => (
                                                                                                            <div ref={provided.innerRef} {...provided.droppableProps} style={containerStyle}>
                                                                                                                {chosenItems.map((column, index) => (
                                                                                                                    <Draggable key={column.position} draggableId={String(column.position)} index={index}>
                                                                                                                        {(provided) => (
                                                                                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                                                                {/* <div style={{ border: '1px solid #ccc', padding: '8px', margin: '8px', minWidth: '200px', backgroundColor: '#f0f0f0' }}> */}
                                                                                                                                {/* <Card style={itemSizeColumnStyle}>
                                                                                                                            <div className="col-md-12">
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-md-9">
                                                                                                                                        <p style={{ fontWeight: '500', fontSize: '13px' }}>{column.name}</p>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                                                                        <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Card> */}
                                                                                                                                <Tag key={index} color="cyan" style={{ fontSize: '15px' }}>{column.name}</Tag>
                                                                                                                                {/* </div> */}
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Draggable>
                                                                                                                ))}
                                                                                                                {provided.placeholder}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Droppable>
                                                                                                </DragDropContext>
                                                                                            </Card> : ''}
                                                                                        <TableContainer component={Paper}>
                                                                                            <Table sx={{ minWidth: 400 }} stickyHeader>
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        <StyledTableCell className="table-header-column-name">
                                                                                                            Item Size
                                                                                                        </StyledTableCell>
                                                                                                        <StyledTableCell className={(rewardhide) ? "table-header-column-name hide" : "table-header-column-name"}>
                                                                                                            Item Price
                                                                                                        </StyledTableCell>
                                                                                                        <StyledTableCell className={(rewardhide) ? "table-header-column-name" : "table-header-column-name hide"}>
                                                                                                            Reward Points
                                                                                                        </StyledTableCell>
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {chosenItems && chosenItems.length > 0 && chosenItems.map((items, index) => {
                                                                                                        return (items && items.name) ?
                                                                                                            <StyledTableRow>
                                                                                                                <StyledTableCell align="center">
                                                                                                                    {items?.name}
                                                                                                                </StyledTableCell>
                                                                                                                <StyledTableCell align="center" className={(rewardhide) ? "hide" : ""}>
                                                                                                                    <input type="text" name="itemprice" value={items.price}
                                                                                                                        onChange={(e) => {
                                                                                                                            changePrice(e, index)
                                                                                                                        }} class="form-control" />
                                                                                                                </StyledTableCell>
                                                                                                                <StyledTableCell align="center" className={(rewardhide) ? "" : "hide"}>
                                                                                                                    <input type="text" name="points" value={items.points}
                                                                                                                        onChange={(e) => {
                                                                                                                            changeReward(e, index)
                                                                                                                        }} class="form-control" />
                                                                                                                </StyledTableCell>
                                                                                                            </StyledTableRow>
                                                                                                            : ''
                                                                                                    })}
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                        </TableContainer>
                                                                                        <div className={(priceerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The {(rewardhide) ? "points" : "price"} field is required.</div>
                                                                                    </>
                                                                                    : ''
                                                                                }
                                                                            </>
                                                                            : (fields?.input == "checkbox" && fields?.name != 'reward') ?
                                                                                <>&nbsp;
                                                                                    <Tooltip title={fields?.placeHolder}>
                                                                                        <span className="icon-question"></span>
                                                                                    </Tooltip><br />
                                                                                    <label class="fancy-checkbox">
                                                                                        <input type="checkbox" className={fields?.class}
                                                                                            value={fields?.value}
                                                                                            name={fields?.type}
                                                                                            required=""
                                                                                            checked={(1 == fields?.value) ? "checked" : ""}
                                                                                            onChange={(e) => { CheckOrRadioChange(((fields?.value == 1) ? 0 : 1), index) }} /><span></span>
                                                                                    </label>
                                                                                </>
                                                                                : (fields?.input == "radio") ?
                                                                                    <><br />
                                                                                        {fields?.options && fields.options.map((item) => {
                                                                                            return <label class="fancy-radio">
                                                                                                <input type="radio" className={fields?.class}
                                                                                                    value={item?.value}
                                                                                                    name={fields?.name}
                                                                                                    checked={(item?.value == fields?.value) ? "checked" : ""}
                                                                                                    onChange={(e) => { CheckOrRadioChange(item?.value, index) }} /><span><i></i>{item.option}</span>
                                                                                            </label>
                                                                                        })}

                                                                                    </>
                                                                                    : (fields?.input == "file") ?
                                                                                        <>&nbsp;
                                                                                            <Tooltip title={fields?.placeHolder}>
                                                                                                <span className="icon-question"></span>
                                                                                            </Tooltip><br />
                                                                                            <section className="row container">
                                                                                                <div className="col-md-8">
                                                                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                                                                        <input {...getInputProps()}
                                                                                                            name={fields?.name} />
                                                                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                                                                    </div></div>
                                                                                                {categoryId === 5 ?
                                                                                                    <div className="col-md-12">
                                                                                                        <div className="thumbcontainer">{(thumbsimage && thumbsimage.length > 0 ? thumbsimage :
                                                                                                            // (fields?.value != '') ?
                                                                                                            //  <div className="dropzone_thum_img">
                                                                                                            //             <div className="dropzone_thumb">
                                                                                                            //                 <img
                                                                                                            //                     src={fields?.value}
                                                                                                            //                     className="dropzone_img"
                                                                                                            //                 />
                                                                                                            //             </div>
                                                                                                            //         </div> : '')}</div>
                                                                                                            //     <div className="thumbcontainer">{(thumbs && thumbs.length > 0 ? thumbs : 
                                                                                                            '')}

                                                                                                            {(fields?.value != '') ?
                                                                                                                merchImageArray.map((imgs, index) => {
                                                                                                                    return (imgs != '') ? <div className="dropzone_thum_img">
                                                                                                                        <a href="javascript:void(0);" className="close_img" onClick={(e) => { deleteImg(index) }}><i className="error fa fa-times"></i></a>
                                                                                                                        <div className="dropzone_thumb">
                                                                                                                            <img
                                                                                                                                src={imgs.defaultPath}
                                                                                                                                className="dropzone_img"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <Radio.Group onChange={(e) => { onChangeDefaultImage(e) }} value={value}>
                                                                                                                            <Radio value={index}>Set as Default</Radio>
                                                                                                                        </Radio.Group>
                                                                                                                    </div>
                                                                                                                        : ''
                                                                                                                }) : ''}</div>
                                                                                                    </div> :
                                                                                                    <div className="col-md-4">


                                                                                                        <div className="thumbcontainer">{(fileUpload ? <div className="dropzone_thum_img">
                                                                                                            <div className="dropzone_thumb">
                                                                                                                <img
                                                                                                                    src={fileUpload}
                                                                                                                    className="dropzone_img"
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div> : (fields?.value != '') ? <div className="dropzone_thum_img">
                                                                                                            <div className="dropzone_thumb">
                                                                                                                <img
                                                                                                                    src={fields?.value}
                                                                                                                    className="dropzone_img"
                                                                                                                />
                                                                                                            </div>

                                                                                                        </div> : '')}</div>
                                                                                                    </div>}
                                                                                                {fileRejectionItems}
                                                                                                <div className={(fileerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>The image field and (Set as default) is required.</div>
                                                                                            </section>
                                                                                        </>
                                                                                        : ''

                                                                }
                                                                {simpleValidator.current.message(fields?.label, fields?.value, fields?.validation, { className: `invalid-feedback ${showvalidator ? 'show' : 'hide'}`, })}
                                                            </div>
                                                        </div>

                                                    );
                                                }

                                            })
                                        }

                                        {
                                            (custom_menu == 1) ?
                                                <>
                                                    <div className="row col-md-12">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Custom Menus <span className="error">*</span></label>
                                                                <Select
                                                                    className="js-states"
                                                                    placeholder=""
                                                                    options={customMenuOptions}
                                                                    values={selectedCustomMenu}
                                                                    disabled={false}
                                                                    multi={true}
                                                                    dropdownHandle={true}
                                                                    searchable={true}
                                                                    searchBy={"name"}
                                                                    labelField={"name"}
                                                                    valueField={"id"}
                                                                    onChange={(value) => { DropdownCustom(value) }}
                                                                />
                                                                <div className={(custommenuserror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>Please select custom menus.</div>

                                                            </div>
                                                        </div>
                                                        {
                                                            // (updateID == 424) ? <div className="col-md-6">
                                                            //     <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { setCustomMenus(e) }}>Set Custom Menu's Weight</button>
                                                            //     </div>
                                                            // </div> :
                                                            (hybridProduct == true) ?
                                                                <div className="col-md-6">
                                                                    <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { setCustomMenus(e) }}>Set Custom Menus Hybrid (Min & Max / Weight)</button>
                                                                    </div>
                                                                </div> :
                                                                is_weight === '0' ?
                                                                    <div className="col-md-6">
                                                                        <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { setCustomMenus(e) }}>Set Custom Menu's Count</button>
                                                                        </div>
                                                                    </div> :
                                                                    (is_weight === '1' && subId == 4) ?
                                                                        <div className="col-md-6">
                                                                            <div className="form-group"><label className="col-md-12">&nbsp;</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { setCustomMenus(e) }}>Set Custom Menu's Weight</button>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                        {custommenuDatasbyID && custommenuDatasbyID.length > 0 ?
                                                            <>
                                                                <div className="col-md-12">
                                                                    <div className="form-group"><label className="col-md-12">Custom Items</label><button id="submit_btn" className="btn btn-primary" onClick={(e) => { e.preventDefault(); setshowCustommenuItem(true) }}>Select Custom Items</button>
                                                                    </div>
                                                                </div>
                                                                <div className={(customerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>Please select custom items.</div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        {selectedDatas.length > 0 && selectedDatas.map((item) => (
                                                                            <span id="submit_btn" className="badge badge-info" onClick={(e) => { e.preventDefault(); }}>{item.item_name}</span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </> : null}

                                                    </div>

                                                    <Modal size="xl" show={showCustommenuCount} backdrop="static">
                                                        <Modal.Header>
                                                            <Modal.Title>{(hybridProduct == true) ? "Set Custom Menus Hybrid (Min & Max / Weight)" : (is_weight === '1' && subId == 4) ? "Set Custom Menus Weight" : "Set Custom Menus Count"} </Modal.Title>
                                                        </Modal.Header>
                                                        {/* <Modal.Body>
                                                        <div className={(minmaxerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>{formerrormsg}</div>
                                                        {
                                                            <TableContainer component={Paper}>
                                                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <StyledTableCell align="">
                                                                                Menu
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="">
                                                                                Min
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="">
                                                                                Max
                                                                            </StyledTableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                            customenuCountDatas && customenuCountDatas.map((row, index) => (
                                                                                <StyledTableRow>
                                                                                    <StyledTableCell align="">
                                                                                        {row?.name}
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            value={row?.min}
                                                                                            name="min"
                                                                                            type="number"
                                                                                            max={row?.match_count}
                                                                                            required=""
                                                                                            onChange={(e) => { changeMinMax(e, index, "min") }}
                                                                                        />
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            value={row?.max}
                                                                                            name="max"
                                                                                            type="number"
                                                                                            max={row?.match_count}
                                                                                            required=""
                                                                                            onChange={(e) => { changeMinMax(e, index, "max") }}
                                                                                        />
                                                                                    </StyledTableCell>
                                                                                </StyledTableRow>
                                                                            ))
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        }
                                                    </Modal.Body> */}
                                                        <Modal.Body>
                                                            <div className="row">
                                                                {/* onClick={toggleChecked} */}
                                                                {Number(subId) == 4 ?
                                                                    <div className="m-b30 col-md-7 col-sm-8">
                                                                        <h6>Choose Product Size<span className="error">*</span></h6>
                                                                        <div className="btn-group product-item-size" data-toggle="buttons" style={{ display: 'block' }}>
                                                                            {
                                                                                chosenItems && chosenItems.map((row, tindex) => (
                                                                                    <label className={(row.itemsize_id == '') ? 'btn active' : 'btn'} >
                                                                                        <input name="options" id="option7" type="radio" value={row.itemsize_id} checked={row.itemsize_id == defaultItemsize} onClick={toggleChecked} /> {row?.name}
                                                                                    </label>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div> : ''}
                                                            </div>
                                                            <div className={(minmaxerror) ? 'invalid-feedback show' : 'invalid-feedback hide'}>{formerrormsg}</div>
                                                            {
                                                                // 17 21 for toppings & combinations
                                                                (hybridProduct) ?
                                                                    <>
                                                                        <div className="col-md-8"><Alert message="For Hybrid Products, both Toppings and Combinations will share the same combined values. Ensure that you select both menus, not just one, when working with this Product." type="warning" showIcon /></div>
                                                                        <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <StyledTableCell align="">
                                                                                            Custom Menus
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                            Min
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                            Max
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                        </StyledTableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {defaultItemsize > 0 && customItemSize && customItemSize.length > 0 && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()) && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()) !== undefined && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts.length > 0 && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts.map((row, index) => {
                                                                                        return (
                                                                                            <StyledTableRow>
                                                                                                <StyledTableCell align="">
                                                                                                    {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ? <span>{row?.customDatas.name}<br />
                                                                                                        <span style={{ fontWeight: '600', fontSize: '12px' }}>(Combined Hybrid)</span></span>
                                                                                                        : (custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?
                                                                                                            <span>{row?.customDatas.name}<br />
                                                                                                                <span style={{ fontWeight: '600', fontSize: '12px' }}>(Hybrid)</span></span>
                                                                                                            : row?.customDatas.name
                                                                                                    }

                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    {/* {(custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?
                                                                                                        '-' : */}
                                                                                                    {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ?
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.min}
                                                                                                            name="min"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "min", "hybrid") }}
                                                                                                        />
                                                                                                        :
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.min}
                                                                                                            name="min"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "min") }}
                                                                                                        />
                                                                                                    }
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    {/* {(custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?
                                                                                                        '-' : */}
                                                                                                    {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ?
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.max}
                                                                                                            name="max"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "max", "hybrid") }}
                                                                                                        />
                                                                                                        :
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.max}
                                                                                                            name="max"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "max") }}
                                                                                                        />
                                                                                                    }
                                                                                                </StyledTableCell>
                                                                                                <StyledTableCell align="">
                                                                                                    {/* {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ?
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.max}
                                                                                                            name="max"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "max", "hybrid") }}
                                                                                                        />
                                                                                                        :
                                                                                                        (custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?

                                                                                                            <input
                                                                                                                className="form-control"
                                                                                                                value={row?.customDatas.max}
                                                                                                                name="max"
                                                                                                                type="number"
                                                                                                                max={row?.customDatas.match_count}
                                                                                                                required=""
                                                                                                                onChange={(e) => { changeMinMaxCustom(e, index, "max") }}
                                                                                                            /> : '-'} */}
                                                                                                    {(row?.customDatas.id == '17') || (row?.customDatas.id == '21') ?
                                                                                                        <span>Weight&nbsp;&nbsp;<span style={{ fontWeight: '600', fontSize: '12px' }}>(Combined Hybrid)</span></span>
                                                                                                        : (custommenuDatas && custommenuDatas.findIndex((arr1) => arr1.id == row?.customDatas.id && arr1.is_hybrid == '1') >= 0) ?
                                                                                                            <span>Weight &nbsp;&nbsp;<span style={{ fontWeight: '600', fontSize: '12px' }}>(Hybrid)</span></span> : '-'}
                                                                                                </StyledTableCell>
                                                                                            </StyledTableRow>
                                                                                        )
                                                                                    }
                                                                                    )}
                                                                                </TableBody>
                                                                            </Table>

                                                                        </TableContainer> </> :
                                                                    (is_weight === '1' && subId == 4) ?
                                                                        <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <StyledTableCell align="">
                                                                                            Size
                                                                                        </StyledTableCell>
                                                                                        <StyledTableCell align="">
                                                                                            Weight Value
                                                                                        </StyledTableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {defaultItemsize > 0 && customenuCountWeight && customenuCountWeight.length > 0 && customenuCountWeight.find((item) => item.itemsize_id == defaultItemsize.toString()) && customenuCountWeight.map((row, index) => {
                                                                                        if (row.itemsize_id == defaultItemsize) {
                                                                                            return (
                                                                                                <StyledTableRow>
                                                                                                    <StyledTableCell align="">
                                                                                                        {row?.itemsize_name}
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell align="">
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.weight_value}
                                                                                                            name="weight"
                                                                                                            type="number"
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxWeight(e, index, row?.itemsize_name) }}
                                                                                                        />
                                                                                                    </StyledTableCell>
                                                                                                </StyledTableRow>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                    )
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer> :
                                                                        subId == 4 ?
                                                                            <TableContainer component={Paper}>
                                                                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <StyledTableCell align="">
                                                                                                Custom Menus
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell align="">
                                                                                                Min
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell align="">
                                                                                                Max
                                                                                            </StyledTableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>

                                                                                    <TableBody>
                                                                                        {defaultItemsize > 0 && customItemSize && customItemSize.length > 0 && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()) && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()) !== undefined && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts.length > 0 && customItemSize.find((item) => item.sizeID == defaultItemsize.toString()).customMenuCounts.map((row, index) => {
                                                                                            return (
                                                                                                <StyledTableRow>
                                                                                                    <StyledTableCell align="">
                                                                                                        {row?.customDatas.name}
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell align="">
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.min}
                                                                                                            name="min"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "min") }}
                                                                                                        />
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell align="">
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.customDatas.max}
                                                                                                            name="max"
                                                                                                            type="number"
                                                                                                            max={row?.customDatas.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMaxCustom(e, index, "max") }}
                                                                                                        />
                                                                                                    </StyledTableCell>
                                                                                                </StyledTableRow>
                                                                                            )
                                                                                        }
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer> :
                                                                            <TableContainer component={Paper}>
                                                                                <Table sx={{ minWidth: 700 }} stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <StyledTableCell align="">
                                                                                                Menu
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell align="">
                                                                                                Min
                                                                                            </StyledTableCell>
                                                                                            <StyledTableCell align="">
                                                                                                Max
                                                                                            </StyledTableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {
                                                                                            customenuCountDatas && customenuCountDatas.map((row, index) => (
                                                                                                <StyledTableRow>
                                                                                                    <StyledTableCell align="">
                                                                                                        {row?.name}
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell align="">
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.min}
                                                                                                            name="min"
                                                                                                            type="number"
                                                                                                            max={row?.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMax(e, index, "min") }}
                                                                                                        />
                                                                                                    </StyledTableCell>
                                                                                                    <StyledTableCell align="">
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            value={row?.max}
                                                                                                            name="max"
                                                                                                            type="number"
                                                                                                            max={row?.match_count}
                                                                                                            required=""
                                                                                                            onChange={(e) => { changeMinMax(e, index, "max") }}
                                                                                                        />
                                                                                                    </StyledTableCell>
                                                                                                </StyledTableRow>
                                                                                            ))
                                                                                        }
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                            }
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={onCloseCustommenuCount}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" onClick={onSaveCustommenuCount}>
                                                                Save
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>

                                                    <Modal size="xl" show={showCustommenuItem} backdrop="static">
                                                        <Modal.Header>
                                                            <Modal.Title>Set Custom Menus Items</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <b>{is_weight === '1' ? `Product Weight ${categoryId === 4 ? '(Default Product Size)' : ''} : ${weightValue}` : ''}</b>
                                                            <ToastContainer
                                                                position="top-right" // Position the toast container above the modal
                                                                autoClose={5000} // Adjust as needed
                                                                hideProgressBar={false}
                                                                newestOnTop={false}
                                                                closeOnClick
                                                                rtl={false}
                                                                pauseOnFocusLoss
                                                                draggable
                                                                pauseOnHover
                                                            />
                                                            <Tabs defaultActiveKey={null}>
                                                                {
                                                                    custommenuDatasbyID && custommenuDatasbyID.map((row, tindex) => (
                                                                        <Tabs.TabPane tab={TabName(row)} key={tindex}>
                                                                            <div className="row col-md-12 p-5" style={{ height: '500px', overflowY: 'scroll' }}>
                                                                                <label class="fancy-checkbox">
                                                                                    <input type="checkbox" className=""
                                                                                        value=""
                                                                                        name=""
                                                                                        required=""
                                                                                        checked={checkAllSelected(tindex, row)}
                                                                                        onChange={(e) => selectAll(row, tindex)}
                                                                                    /><span>Select All Items</span>
                                                                                </label>
                                                                                {row?.custom_menu_items.length > 0 && row?.custom_menu_items.map((item, rindex) => (
                                                                                    <div className="col-md-3">
                                                                                        <div className="card card-border text-center">
                                                                                            <label class="fancy-checkbox float-right" style={{ position: "absolute", left: "85%", top: "2%" }}>
                                                                                                <input type="checkbox" className=""
                                                                                                    value=""
                                                                                                    name={rindex}
                                                                                                    checked={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? false : true}
                                                                                                    required=""
                                                                                                    onClick={(e) => selectItems(row, tindex, rindex, item)}
                                                                                                // (rowcheckbox[tindex][rindex] == true) ? true :
                                                                                                /><span></span>
                                                                                            </label>
                                                                                            <label for={rindex}>
                                                                                                <img src={assestURL + item?.image}
                                                                                                    onClick={(e) => selectItems(row, tindex, rindex, item)} style={{ width: "auto", height: "125px", marginTop: "10px" }} class="card-img-top" alt="..." />
                                                                                            </label>
                                                                                            <div className="card-body">
                                                                                                <h5 class="card-title">{item?.name}</h5>
                                                                                                <p className="card-text">{item?.price}</p>
                                                                                                <p className="card-text" style={{ fontSize: '12px' }}>Weight :{item?.weight}</p>
                                                                                                <div>
                                                                                                    <table>
                                                                                                        {chosenItems && chosenItems.map((items, ciindex) => (
                                                                                                            <tr>
                                                                                                                <td style={{ width: "20%" }}>
                                                                                                                    <label>{items?.name}</label>
                                                                                                                </td>
                                                                                                                <td style={{ width: "80%" }} className={(rewardhide) ? "hide" : ""}>
                                                                                                                    <input type="number"
                                                                                                                        className="form-control"
                                                                                                                        value={ItemPrice(item?.price, row, ciindex, item, items)}
                                                                                                                        //value={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) >= 0) ? selectedDatas[_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id })]?.price[ciindex] : item?.price}
                                                                                                                        name="priceItem"
                                                                                                                        onChange={(e) => ChangeItemPrice(e, ciindex, item, items)}
                                                                                                                        disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false} />
                                                                                                                    {/* //(rowcheckbox[tindex][rindex] == true) ? false : */}
                                                                                                                </td>
                                                                                                                <td style={{ width: "80%" }} className={(rewardhide) ? "" : "hide"}>
                                                                                                                    <input type="number"
                                                                                                                        className="form-control"
                                                                                                                        value={ItemPoints(0, row, ciindex, item, items)}
                                                                                                                        //value={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) >= 0) ? selectedDatas[_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id })]?.price[ciindex] : item?.price}
                                                                                                                        name="priceItem"
                                                                                                                        onChange={(e) => ChangeItemPoints(e, ciindex, item, items)}
                                                                                                                        disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false} />
                                                                                                                    {/* //(rowcheckbox[tindex][rindex] == true) ? false : */}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))}
                                                                                                    </table>
                                                                                                </div><br />
                                                                                                <label class="fancy-checkbox">
                                                                                                    <input type="checkbox" className=""
                                                                                                        value=""
                                                                                                        name={"default-" + rindex}
                                                                                                        required=""
                                                                                                        checked={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id, default: 1 }) >= 0) ? true : false}
                                                                                                        onChange={(e) => selectDefault(row, tindex, rindex, item)}
                                                                                                        disabled={(_.findIndex(selectedDatas, { item_id: item.id, custom_menu_id: row.id }) < 0) ? true : false}
                                                                                                    /><span>Set Default</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <img src={assestURL + item?.image} style={{ width: "auto", height: "125px" }} />
                                                                                    {chosenItems && chosenItems.map((items, index) => (
                                                                                        <>
                                                                                            <div> {items?.name}</div>
                                                                                            <div> {item?.price}</div>
                                                                                        </>
                                                                                    ))}
                                                                                    <div>{item?.name}</div>
                                                                                    <div>{item?.price}</div> */}

                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </Tabs.TabPane>
                                                                    ))

                                                                }
                                                            </Tabs>
                                                        </Modal.Body>

                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={onCloseCustommenuItem}>
                                                                Close
                                                            </Button>
                                                            <Button variant="primary" onClick={onSaveCustommenuItem}>
                                                                Save
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </>
                                                : ""
                                        }
                                        <Divider />
                                        <label htmlFor="">Kitchen Code <span style={{ color: 'red' }}>*</span></label>
                                        <Card className="mt-2 ml-4 mb-2" style={{ width: '95%' }}>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="columns" direction="horizontal" type="COLUMN">
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps} style={containerStyle}>
                                                            {columnOrder.map((column, index) => (
                                                                <Draggable key={column.id} draggableId={String(column.id)} index={index}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                            {/* <div style={{ border: '1px solid #ccc', padding: '8px', margin: '8px', minWidth: '200px', backgroundColor: '#f0f0f0' }}> */}
                                                                            <Card style={columnStyle}>
                                                                                <div className="col-md-12">
                                                                                    <div className="row">
                                                                                        <div className="col-md-9">
                                                                                            <p style={{ fontWeight: '900', fontSize: '15px' }}>{column.name}</p>
                                                                                        </div>
                                                                                        <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                            <Button style={{ backgroundColor: '#49c5b6', height: '22px', width: '15px', color: 'black', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="round">{index + 1}</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Card>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </Card>
                                        <Divider />
                                        <label htmlFor="">Sample Kitchen Code Output&nbsp;
                                            <Tooltip title={"Sample Output displays only Default custom menu items"}>
                                                <span className="icon-question"></span>
                                            </Tooltip><br /></label>
                                        <Card className="mt-2 ml-4 mb-2" style={{ width: '95%' }}>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <p style={{ fontWeight: '900', fontSize: '18px' }}>{sampleCodeOutput}</p>
                                                </div>
                                            </div>
                                        </Card>
                                        <button id="submit_btn" class="offset-md-5 col-md-2 btn btn-success" onClick={(e) => { e.preventDefault(); submitCategory(e); }}>Submit</button>
                                    </div>
                                </form>
                                :
                                <div style={{ marginTop: '5%', marginBottom: '5%', marginLeft: '45%' }}>
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 24,
                                                }}
                                                spin
                                            />
                                        }
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
            <Modal size="lg" show={showPreview} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {/* <button onClick={zoomIn}>Zoom in</button>
                        <button onClick={zoomOut}>Zoom out</button> */}
                        {imgSRC && (
                            <ReactCrop
                                crop={crop}
                                //onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onChange={c => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={false}
                                maxHeight={maxProductHeight}
                                maxWidth={maxProductWidth}
                                minWidth={maxProductWidth}
                                minHeight={maxProductHeight}
                                locked={true}
                                style={{ overflow: "scroll", height: "400px", width: imgSRCwidth }}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSRC}
                                    style={{ transform: `scale(${scale})`, width: imgSRCwidth, height: imgSRCheight, maxWidth: "unset" }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        )}

                        {!!completedCrop && (
                            <>
                                <div style={{ display: "none" }}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                </div>
                            </>)}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={cropImg}>
                        Crop
                    </Button>
                    <Button variant="primary" onClick={Imgmodalclose} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}

export default EditProducts;
