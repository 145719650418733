export const Leftmenu = () => {
    const menus = [
        { menu: "Dashboard", url: 'dashboard', container: "Dashboard Container", ischild: false, icon: "fa fa-home", child: [{ menu: "", url: '' }] },
        { menu: "Category", url: 'category', container: "Category Container", ischild: false, icon: "fa fa-sitemap", child: [{ menu: "", url: '' }] },
        { menu: "Sub Category", url: 'subcategory', container: "SubCategory Container", ischild: false, icon: "fa fa-database", child: [{ menu: "", url: '' }] },
        { menu: "Item Size", url: 'itemsize', container: "ItemSize Container", ischild: false, icon: "fa fa-sort-amount-asc", child: [{ menu: "", url: '' }] },
        { menu: "Products", url: 'products addproducts editproducts', container: "Products Container", ischild: true, icon: "fa fa-shopping-cart", child: [{ menu: "Add Products", url: 'addproducts', container: "Products Container" }, { menu: "Products List", url: 'products', container: "Products Container" }] },
        { menu: "Kitchen Code", url: 'kitchencode kitchensection', container: "KitchenCode Container", ischild: true, icon: "fa fa-file-code-o", child: [{ menu: "Kitchen Section", url: 'kitchensection', container: "KitchenCode Container" }, { menu: "Kitchen Format", url: 'kitchencode', container: "KitchenCode Container" }] },
        { menu: "Customers", url: 'customers', container: "Customers Container", ischild: false, icon: "fa fa-users", child: [{ menu: "", url: '' }] },
        { menu: "Orders", url: 'orders', container: "Orders Container", ischild: false, icon: "fa fa-shopping-basket", child: [{ menu: "", url: '' }] },
        { menu: "Custom Menu", url: 'custommenu', container: "CustomMenu Container", ischild: false, icon: "fa fa-cubes", child: [{ menu: "", url: '' }] },
        { menu: "Custom Menu Item", url: 'custommenuitem', container: "CustomItem Container", ischild: false, icon: "fa fa-tasks", child: [{ menu: "", url: '' }] },
        { menu: "Gift Card", url: 'giftcard', container: "GiftCard Container", ischild: false, icon: "fa fa-address-card-o", child: [{ menu: "", url: '' }] },
        //{ menu: "POS & Schedule", url: 'pos', ischild: false, icon: "icon-clock", child: [{ menu: "", url: '' }] },
        //{ menu: "Custom Menu", url: 'custommenu custommenuitem', ischild: true, icon: "icon-settings", child: [{ menu: "Custom Menu", url: 'custommenu' }, { menu: "Custom Menu Item", url: 'custommenuitem' }] },
        { menu: "Settings", url: 'pos sorting aboutus contactus editcontactus notification banner editbanner addbanner addaboutus editaboutus colorpicker', container: "Settings Container", ischild: true, icon: "fa fa-cogs", child: [{ menu: "POS & Schedule", url: 'pos', container: "Settings Container" }, { menu: "General", url: 'general', container: "Settings Container" }, { menu: "KIOSK", url: 'kiosk', container: "Settings Container" },{ menu: "Sorting", url: 'sorting', container: "Settings Container" }, { menu: "Banner", url: 'banner', container: "Settings Container" }, { menu: "Notification", url: 'notification', container: "Settings Container" }, { menu: "About Us", url: 'aboutus', container: "Settings Container" }, { menu: "Contact Us", url: 'contactus', container: "Settings Container" }, { menu: "Color Picker", url: 'colorpicker', container: "Settings Container" },{menu:"Credentials",url:'credentials',container: "Settings Container"}] },
        { menu: "Reports", url: 'reports', container: "Reports Container", ischild: false, icon: "fa fa-bar-chart", child: [{ menu: "", url: '' }] },

    ]
    return menus;
};