import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { productsList, productsAdd, productsUpdate, productsCustomMenus, productsDelete, productsListById, productsBulkDelete, productsBulkStatus, productsAddMultipleImage, productsAddMerchImages, productsUpdateMerchImages } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
import alertmessage from './../../utils/alert';
const namespace = "products";

const initialState = {
    loading: "initial",
    addloading: "initial",
    addMerchImageLoading: "initial",
    updateloading: "initial",
    deleteloading: "initial",
    errorMessage: null,
    productDatas: null,
    productDataById: null,
    productCount: 0,
    bulkDloading: "initial",
    bulkSloading: "initial",
    lastProductId: null,
    customProductDatas: null
};

export const productData = createAsyncThunk(
    `${namespace}/productData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "", reward = 0, status = "", category_id = 0 }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await productsList(query, page, limit, sortby, order, reward, status, category_id);
            console.log("getCount-->123 ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const AddProduct = createAsyncThunk(
    `${namespace}/AddProduct`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await productsAdd({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const AddMerchImages = createAsyncThunk(
    `${namespace}/AddMerchImages`,
    async ({ uploadfiles, lastProductId }, { rejectWithValue, dispatch }) => {
        try {
            console.log(uploadfiles, 'uploadfiles');
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const postData = new FormData();
            uploadfiles.forEach((file) => {
                console.log(file);
                postData.append("images", file);
            })
            // postData.append("payload", payload);
            postData.append("product_id", lastProductId)
            console.log(postData, 'postData');
            const data = await productsAddMerchImages(postData);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const UpdateMerchImages = createAsyncThunk(
    `${namespace}/UpdateMerchImages`,
    async ({ filteredImages, updateID, files }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, filteredImages, "interdata");
            // let payload = EncryptDecrypt.encryptdata(postDatas, secretKey)
            const postData = new FormData();
            // if (existingImages) {
            //     existingImages.forEach((file) => {
            //         console.log(file);
            //         postData.append("images", file);
            //     })
            // }
            if (files) {
                files.forEach((file) => {
                    console.log(file);
                    postData.append("images", file);
                })
            }
            else {
                postData.append("images", []);
            }
            // postData.append("payload", payload);
            postData.append("product_id", updateID)
            postData.append("existingImages", filteredImages)
            const data = await productsUpdateMerchImages(postData, updateID);
            console.log(postData, 'postDatapostData');

            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const UpdateProduct = createAsyncThunk(
    `${namespace}/UpdateProduct`,
    async ({ postData, updateID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(updateID, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await productsUpdate({ payload: payload }, updateID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteProduct = createAsyncThunk(
    `${namespace}/DeleteProduct`,
    async ({ deleteID }, { rejectWithValue, dispatch }) => {
        try {
            console.log(deleteID, "interdata");
            const data = await productsDelete(deleteID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const productById = createAsyncThunk(
    `${namespace}/productById`,
    async ({ editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await productsListById(editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const DeleteBulkProduct = createAsyncThunk(
    `${namespace}/DeleteBulkProduct`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await productsBulkDelete({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const ChangeBulkProductStatus = createAsyncThunk(
    `${namespace}/ChangeBulkProductStatus`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "interdata");
            let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await productsBulkStatus({ payload: payload });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const productCustomProducts = createAsyncThunk(
    `${namespace}/productCustomProducts`,
    async ({ editID }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            const data = await productsCustomMenus(editID);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const productsSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearProdLoadingDatas: (state) => {
            state.loading = "initial";
            state.addloading = "initial";
            state.updateloading = "initial";
            state.deleteloading = "initial";
            state.bulkDloading = "initial";
            state.bulkSloading = "initial";
        },
        clearProdAddLoading: (state) => {
            state.addloading = "initial";
        },
        clearProdUpdateLoading: (state) => {
            state.updateloading = "initial";
        },
        clearProdDeleteLoading: (state) => {
            state.deleteloading = "initial";
        },
        clearProdBulkDLoading: (state) => {
            state.bulkDloading = "initial";
        },
        clearProdBulkSLoading: (state) => {
            state.bulkSloading = "initial";
        },
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [productData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [productData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // state.productCount = payload?.data?.count;
            // state.productDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.data?.count, "payload")
            console.log(payloaddatas?.data, payloaddatas && payloaddatas, 'payloaddatas');
            state.productCount = payloaddatas?.count;
            state.productDatas = payloaddatas?.data;
            // state.lastProductId = payloaddatas?.last_productID;
        },
        [productData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            if (action?.payload?.data?.auth === "deleted") {
                alertmessage.sweetalert(action?.payload?.data?.message);
            }
            state.errorMessage = action?.payload?.data;
        },
        [AddProduct.pending](state) {
            state.addloading = API_STATUS.PENDING;
        },
        [AddProduct.fulfilled](state, { payload }) {
            console.log(payload, payload?.data?.lastProductID, 'payload');
            state.addloading = API_STATUS.FULFILLED;
            state.lastProductId = payload?.data?.lastProductID
        },
        [AddProduct.rejected](state, action) {
            state.addloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [AddMerchImages.pending](state) {
            state.addMerchImageLoading = API_STATUS.PENDING;
        },
        [AddMerchImages.fulfilled](state, { payload }) {
            state.addMerchImageLoading = API_STATUS.FULFILLED;
        },
        [AddMerchImages.rejected](state, action) {
            state.addMerchImageLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [UpdateProduct.pending](state) {
            state.updateloading = API_STATUS.PENDING;
        },
        [UpdateProduct.fulfilled](state, { payload }) {
            state.updateloading = API_STATUS.FULFILLED;
        },
        [UpdateProduct.rejected](state, action) {
            state.updateloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteProduct.pending](state) {
            state.deleteloading = API_STATUS.PENDING;
        },
        [DeleteProduct.fulfilled](state, { payload }) {
            state.deleteloading = API_STATUS.FULFILLED;
        },
        [DeleteProduct.rejected](state, action) {
            state.deleteloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [productById.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [productById.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload")
            state.productDataById = payloaddatas?.response;
        },
        [productById.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [productCustomProducts.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [productCustomProducts.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas, "payload")
            state.customProductDatas = payloaddatas?.response;
        },
        [productCustomProducts.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [DeleteBulkProduct.pending](state) {
            state.bulkDloading = API_STATUS.PENDING;
        },
        [DeleteBulkProduct.fulfilled](state, { payload }) {
            state.bulkDloading = API_STATUS.FULFILLED;
        },
        [DeleteBulkProduct.rejected](state, action) {
            state.bulkDloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [ChangeBulkProductStatus.pending](state) {
            state.bulkSloading = API_STATUS.PENDING;
        },
        [ChangeBulkProductStatus.fulfilled](state, { payload }) {
            state.bulkSloading = API_STATUS.FULFILLED;
        },
        [ChangeBulkProductStatus.rejected](state, action) {
            state.bulkSloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
    },
});

export const { clearData, clearProdAddLoading, clearProdUpdateLoading, clearProdLoadingDatas, clearProdDeleteLoading, clearProdBulkDLoading, clearProdBulkSLoading, clearErrormsg } = productsSlice.actions;

export const productsSelector = (state) => state.products;

export default productsSlice.reducer;
